import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
export default function index(props) {
    return (
        <Grid item {...props} >
            <Paper style={{padding: '1.5rem'}} elevation={3}>
                {props.children}
            </Paper>
        </Grid>
    )
}
