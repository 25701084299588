import { productTypeEnum } from "commonFnBcode/types/whitelabel.types";
import _ from "lodash";
import { getRequest } from "tools/ajax";

export const checkPurchasedAddon = async (addon: productTypeEnum) => {
  const res = await getRequest("wallet/getPurchasedAddons", {});
  if (res?.success) {
    if (_.find(res.result, { addon })) {
      return true;
    } else return false;
  }
  return false;
};
