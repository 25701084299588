import { Button, Modal } from "react-bootstrap";
import { ApiSettings, IUserApiIntegration } from "./ApiSettings";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { postRequest } from "tools/ajax";
import { toast } from "react-toastify";

export const SingleApiDetails: React.FC<{
  editApiSettings: () => void;
  apiDetails: IUserApiIntegration;
}> = (props) => {
  const [currentApiDetails, setCurrentApiDetails] =
    useState<IUserApiIntegration>(props.apiDetails || undefined);

  useEffect(() => {
    setCurrentApiDetails(props.apiDetails);
  }, [props.apiDetails]);
  return (
    <div className="">
      <div className="row  ">
        <div className="col-2 ">
          <div
            className={`form-check form-switch  m-0 p-0  ${
              currentApiDetails?.isActive ? "text-success" : "text-danger"
            }`}
          >
            {currentApiDetails?.isActive ? (
              <i className="fas fa-check"></i>
            ) : (
              <i className="fas fa-times"></i>
            )}{" "}
            isActive
          </div>
        </div>
        <div className="col-3">
          <div
            className={`form-check form-switch  m-0 p-0  ${
              currentApiDetails?.isApiSaleAllowed
                ? "text-success"
                : "text-danger"
            }`}
          >
            {currentApiDetails?.isApiSaleAllowed ? (
              <i className="fas fa-check"></i>
            ) : (
              <i className="fas fa-times"></i>
            )}{" "}
            isApiSaleAllowed
          </div>
        </div>
        <div className="col-3">
          <div
            className={`form-check form-switch  m-0 p-0  ${
              currentApiDetails?.isB2bSaleAllowed
                ? "text-success"
                : "text-danger"
            }`}
          >
            {currentApiDetails?.isB2bSaleAllowed ? (
              <i className="fas fa-check"></i>
            ) : (
              <i className="fas fa-times"></i>
            )}{" "}
            isB2bSaleAllowed
          </div>
        </div>
        <div className="col-3">
          <div
            className={`form-check form-switch  m-0 p-0  ${
              currentApiDetails?.isB2cSaleAllowed
                ? "text-success"
                : "text-danger"
            }`}
          >
            {currentApiDetails?.isB2cSaleAllowed ? (
              <i className="fas fa-check"></i>
            ) : (
              <i className="fas fa-times"></i>
            )}{" "}
            isB2cSaleAllowed
          </div>
        </div>
        <div className="col-1">
          <button className="btn text-primary" onClick={props.editApiSettings}>
            <i className="fas fa-edit"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
