import React from 'react'
import {Button} from 'semantic-ui-react'
import * as yup from 'yup'
import {Formik, Field,Form} from 'formik'

import axios from 'axios'

export default class NewCityForm extends React.Component {


    constructor(props){
        super()
        this.state = {countries: []}
    }

    componentDidMount(){
        this.getCountries()
    }

    getCountries = () => {
        axios({
            url: "/api/admin/master/getCountries",
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('auth-token')
            }
        }).then(r=>{
            console.log(r.data)
            this.setState({countries: r.data})
        })
    }
    schema = yup.object({
        cityName: yup.string().label('Country Name').required(),
        country: yup.string().required()
    })

    render =() => {
    return (
        <Formik
   
        validationSchema={this.schema}
        initialValues={{
            cityName: "",
            country: ""
        }}
        onSubmit={(data,actions)=>{
            const obj = this.state.countries.find(c => c._id === data.country)
            axios({
                url: '/api/admin/master/addCity',
                method: 'POST',
                data: {
                    cityName: data.cityName,
                    countryId: obj._id,
                    countryName: obj.countryName 
                },
                headers:{
                    'auth-token': localStorage.getItem('auth-token')
                }
            }).then(r=>{
                console.log(r.data)

                this.props.getCities()
                console.log(actions.resetForm())
                
            })
        }}
        >
            {(props)=>(
                <Form>
                
                    
                    <Field name="cityName"
                    placeholder="City Name"
                    className="myinput"
                    >
                    </Field>
                    <span className="myerror">{props.errors.cityName}</span>
                        
                        <Field 
                        name="country" 
                        as="select"
                        placeholder="country"
                        className="myinput"
                         >  
                         <option key="a">

                         </option>
                         {this.state.countries.map(v=>(
                            <option key={v._id} value={v._id}>
                                {v.countryName}
                            </option>
                         ))}
                        </Field>
                        <span className="myerror">{props.errors.country}<br/></span>
                  
                    <Button type='submit'>Submit</Button>
                
                </Form>
                
            )}
            
        </Formik>
    )
            }
}
