import { getWhitelabel } from "apiCalls/whitelabelDb";
import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IInvoice } from "types/accounts.types";

import { CashfreePaymentGateway } from "../plans/paymentGateways/cashfree/CashfreePaymentGateway";

export interface IPendingPaymentBreakup {
  id: number;
  mongoDb_siteId: string;
  look_count: number;
  look_book_amount: string;
  book_count: number;
  amount_per_search: number;
  amount_per_seg_pax: number;
  total_segments_pax: number;
  amount_month_min: number;
  fd_user_book_count: number;
  fd_supplier_book_count: number;
  fd_sup_charges: string;
  fd_user_charges: string;
  fd_u_charges_below_10k: number;
  fd_u_charges_below_20k: number;
  fd_u_charges_below_30k: number;
  fd_u_charges_below_40k: number;
  fd_u_charges_below_50k: number;
  fd_u_charges_below_100k: number;
  fd_u_charges_perc_above_100k: number;
  fd_sup_charges_perc: number;
  gst_percentage: number;
  total_amount: string;
  discount: string;
  isPaid: boolean;
  month_year: string;
  free_searches_per_booking: number;
  createdAt: Date;
  updatedAt: Date;
}

export const PendingPaymentBreakup: React.FC<{
  onHide: () => void;
  paymentBreakup: IInvoice;
}> = (props) => {
  const [whitelabelState, setWhitelabelState] = useState<any>();
  const [loadingState, setLoadingState] = useState(false);

  const getWhitelabelData = async () => {
    const whitelabelData = await getWhitelabel();
    setWhitelabelState(whitelabelData[0]);
  };

  useEffect(() => {
    getWhitelabelData();
  }, []);

  const [paymentGatewayOpen, setPaymentGatewayOpen] = useState(false);

  // let subtotal = item.subtotal;

  const payMonthlyBilling = () => {
    setPaymentGatewayOpen(true);
  };
  const convertToFloat = (amount) => {
    if (typeof amount == "string") {
      return parseFloat(amount);
    } else return amount;
  };
  return (
    <Modal
      show
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <FullScreenLoader show={loadingState} />
      <Modal.Header closeButton>
        <Modal.Title>Pending Payment Breakup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {/* <div className="row">
            <div className="col-md-12">
              <h3 className="mb-3">Look To Book Charges</h3>
            </div>
            <p>Total Searches: {props.paymentBreakup.look_count}</p>
            <p>Total Bookings: {props.paymentBreakup.book_count}</p>

            <strong>Extra Searches: {extraSearches}</strong>
            <p>
              Amount per extra search: ₹{props.paymentBreakup.amount_per_search}
            </p>

            <h4>
              Look to Book Amount: ₹
              {extraSearches * props.paymentBreakup.amount_per_search}
            </h4>
            <hr />
          </div> */}

          <div className="row">
            <div className="col-md-12">
              <h3 className="mb-3">Flight Charges</h3>
            </div>
            {props.paymentBreakup.invoiceBreakups
              .filter((inv) => inv.breakupGroup === "flight")
              .map((item, index) => {
                return (
                  <div className="container-fluid" key={index}>
                    <div key={index} className="row">
                      <div className="col-md-8">{item.label}</div>
                      <div className="col-md-4">
                        {props.paymentBreakup.currency} {item.amountValue}
                      </div>
                    </div>
                  </div>
                );
              })}
            <hr />
          </div>

          <div className="row">
            <div className="col-md-12">
              <h3 className="mb-3">Fixed Departure Charges</h3>
            </div>
            {props.paymentBreakup.invoiceBreakups
              .filter((inv) => inv.breakupGroup === "fixedDeparture")
              .map((item, index) => {
                return (
                  <div className="container-fluid" key={index}>
                    <div key={index} className="row">
                      <div className="col-md-8">{item.label}</div>
                      <div className="col-md-4">
                        {props.paymentBreakup.currency} {item.amountValue}
                      </div>
                    </div>
                  </div>
                );
              })}
            <hr />
          </div>

          <div className="row">
            <div className="col-md-12">
              <h3 className="mb-3">Total Charges:</h3>
            </div>
            <p>
              Subtotal: {props.paymentBreakup.currency}&nbsp;
              {props.paymentBreakup.subtotal}
            </p>
            <p>
              Tax: {props.paymentBreakup.currency}&nbsp;
              {props.paymentBreakup.tax}
            </p>
            <p>
              Discount: {props.paymentBreakup.currency}&nbsp;
              {props.paymentBreakup.discount}
            </p>

            <h4>
              Total: {props.paymentBreakup.currency}{" "}
              {convertToFloat(props.paymentBreakup.subtotal) +
                convertToFloat(props.paymentBreakup.tax) -
                convertToFloat(props.paymentBreakup.discount)}
            </h4>
            <hr />
            {props.paymentBreakup.isPaid ? (
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2>
                    <span className="badge bg-success badge-bg">Paid</span>
                  </h2>
                </div>
              </div>
            ) : (
              // <button className="btn btn-primary" onClick={payMonthlyBilling}>
              //   pending
              // </button>
              <></>
            )}
          </div>
          {paymentGatewayOpen && whitelabelState && (
            <CashfreePaymentGateway
              customer={{
                purchases: {
                  form: {
                    email: whitelabelState.adminEmail,
                    phone: whitelabelState?.whatsAppNumber,
                  },
                },
                _id: whitelabelState._id.toString(),
              }}
              paymentType="MonthlyFee"
              orderId={props.paymentBreakup.id}
              form={{}}
              setLoading={setLoadingState}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
