export const UserWalletCard: React.FC<{
  heading: string;
  subHeading?: string;
  description?: string;
  btnLable?: string;
  onClick?: any;
  className?: string;
  loading?: boolean;
  scrollToId?: string;
  isBalanceNegative?: boolean;
}> = (props) => {
  return (
    <div className=" col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="bg-white user-wallet-card  p-5 rounded d-flex flex-column justify-content-between">
        <div>
          <h2 className={`p-0 m-0 ${props.isBalanceNegative && "text-danger"}`}>
            {props.loading ? "Loading..." : props.heading}
          </h2>
          <h3 className={`p-0 my-2  fs-3`}>{props.subHeading}</h3>
          <p className="sm">{props.description || ""}</p>
        </div>
        <div>
          {props.onClick && (
            <button
              className={`btn btn-${props.className?.toLocaleLowerCase()} text-white py-1 mt-3`}
              onClick={props.onClick}
            >
              {props.btnLable}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
