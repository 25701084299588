import { SippiAccountsApi } from "apiCalls/SippiAccounts.api";
import { useAppDispatch } from "hooks/reduxHooks";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { SET_LOADER } from "redux/actions/common.actions";

export const AccountEmailChangePopup: React.FC<{
  email: string;
  mongoDb_siteId: string;
  hidePopup: () => void;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState(props.email);

  const updateAccountsEmail = async () => {
    try {
      dispatch(SET_LOADER(true));

      await SippiAccountsApi.updateAccountsEmail({
        email: email,
        mongoDb_siteId: props.mongoDb_siteId,
      });

      toast.success("Email updated successfully");
      props.hidePopup();
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    }
    dispatch(SET_LOADER(false));
  };

  return (
    <Modal
      show={true}
      onHide={() => {
        props.hidePopup();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-center w-100"
        >
          Change Email Popup
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-7">
            <label htmlFor="">Accounts Email</label>
            <input
              value={email}
              className="form-control"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
          <div className="col-md-7">
            <button
              className="btn btn-primary mt-1"
              onClick={() => {
                updateAccountsEmail();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
