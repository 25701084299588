import React from 'react'
import {Backdrop,CircularProgress,makeStyles} from '@material-ui/core'


const useStyles = makeStyles((theme)=>({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
}))
export default function Loading(props){
    const classes = useStyles()

    return(
        <Backdrop open={props.loading} 
            className={classes.backdrop}
            >
                <CircularProgress />
        </Backdrop>
    )
}