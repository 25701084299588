import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// import { makeStyles, Theme } from "@material-ui/core/styles";
import { AxiosResponse } from "axios";
import { flightAxios } from "./commonFlightAjax";
import { IAirline } from "redux/reducers/flightApi.reducer";
import { DiscountSettings } from "./DiscountSettings";
import { MarkupSettings } from "./MarkupSettings";
import { DefaultDiscountSettings } from "./DefaultDiscountSettings";
import { DefaultMarkupSettings } from "./DefaultMarkupSettings";
import { toast } from "react-toastify";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="p-3">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AirlineDiscountMarkup = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [flightsState, setFlightsState] = useState<Array<IAirline>>([]);

  const getAllAirlines = async () => {
    let response: AxiosResponse;
    try {
      response = await flightAxios({
        url: `getAllAirlines`,
        method: "POST",
      });
    } catch (error) {
      toast.error("Error connecting to server. ");
      return;
    }

    if (response.data) {
      setFlightsState(response.data.airlines);
    }
  };

  useEffect(() => {
    getAllAirlines();
    return () => {
      // cleanup
    };
  }, []);

  return (
    <>
      <AppBar position="static">
        <Tabs
          variant="scrollable"
          scrollButtons="on"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Default Discount Settings" {...a11yProps(2)} />
          <Tab label="Default Markup Settings" {...a11yProps(3)} />
          <Tab label="Flight Specific Discount" {...a11yProps(0)} />
          <Tab label="Flight Specific Markup" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DefaultDiscountSettings />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DefaultMarkupSettings />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DiscountSettings airlines={flightsState} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MarkupSettings airlines={flightsState} />
      </TabPanel>
    </>
  );
};
