import { useEffect, useState } from "react";
import TextInp from "commonUi/input/text/index";
import Button from "commonUi/button/btnPrimary";
import {
  GET_BANK_DETAILS,
  BANK_DETAILS,
} from "redux/actions/siteSettings.actions";
import { useDispatch } from "react-redux";

import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import { SelectPaymentGatewayTable } from "./SelectPaymentGatewayTable";
import { PaymentGatewayDetails } from "./PaymentGatewayDetails";
import { useAppSelector } from "hooks/reduxHooks";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "tools/ajax";

export default function BankDetails() {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [loadingState, setLoadingState] = useState(false);
  const [loading, setLoading] = useState(false);
  const { bankDetails, siteData } = useAppSelector(
    (state) => state.siteSettings
  );
  const [tanNumber, setTanNumber] = useState<string | undefined>(undefined);
  const [form, setForm] = useState({
    bankName: "",
    bankCode: "",
    accountNumber: "",
    accountName: "",
    remark: "",
    bankAddress: "",
  });

  useEffect(() => {
    dispatch(BANK_DETAILS({ siteId: siteData._id }));
    if (legalEntityId) getTanNumber();
    // getActivePaymentGateway();
    // getRazorpayDetails();
    // getIciciDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setForm(bankDetails);
  }, [bankDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDisabled(false);
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // dispatch(SET_LOADER(true));
    console.log(form);
    dispatch(GET_BANK_DETAILS(form));
    // dispatch(SET_LOADER(false));
    setDisabled(true);
  };

  const printIn = (name, label, value, form?: any, placeholder?: string) => (
    <TextInp
      name={name}
      label={label}
      type="text"
      value={value || ""}
      onChange={handleChange}
      className="mb-3"
      placeholder={placeholder}
      required
    />
  );
  const legalEntityId = useAppSelector(
    (state) => state.siteSettings.siteData.legalEntityId
  );

  const saveTanNumber = async () => {
    if (loading) return;
    setLoading(true);
    if (tanNumber && tanNumber?.trim()?.length !== 10) {
      toast.error("Tan number must contain 10 charaters.");
      setLoading(false);

      return;
    }
    const saveTanNumberRes = await postRequest("siteSettings/saveTanNumber", {
      legalEntityId,
      tanNumber,
    });
    setLoading(false);
    if (saveTanNumberRes?.success) {
      toast.success("Tan number saved");
    }
  };
  const getTanNumber = async () => {
    if (legalEntityId) {
      const tanNumberRes = await getRequest("siteSettings/getTanNumber", {
        id: legalEntityId,
      });
      if (tanNumberRes?.success) {
        setTanNumber(tanNumberRes?.result?.tanNumber);
      }
    }
  };
  return (
    <>
      <div className="bank-details-page-pss">
        <FullScreenLoader show={loadingState} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-9 col-sm-12 col-12 mb-4">
              <h5 className="heading text-start mb-2">Bank Details</h5>
              <p className="text-start mb-3">
                Bank Details will be shown to customers while making payments.
              </p>
              <div className="bank-details-form pt-4">
                <div className="row">
                  <div className="col-lg-6">
                    {printIn("bankName", "Bank Name", form.bankName)}
                  </div>
                  <div className="col-lg-6">
                    {printIn("bankCode", "IFSC/NEFT/RTGS", form.bankCode)}
                  </div>
                  <div className="col-lg-6">
                    {printIn("accountNumber", "A/C Number", form.accountNumber)}
                  </div>
                  <div className="col-lg-6">
                    {printIn("accountName", "A/C Name", form.accountName)}
                  </div>
                  <div className="col-lg-6">
                    {printIn("bankAddress", "Bank Address", form.bankAddress)}
                  </div>
                  <div className="col-lg-6">
                    <TextInp
                      name="remark"
                      label="Remarks"
                      type="text"
                      value={form.remark || ""}
                      rows={2}
                      onChange={handleChange}
                      className=""
                    />
                  </div>
                </div>

                <div className="text-center">
                  {!disabled && (
                    <Button label="Submit" onClick={handleSubmit} />
                  )}
                </div>
              </div>
            </div>
            {/* add tan number */}
            <div className="col-lg-9 col-md-9 col-sm-12 col-12 mb-4">
              <h5 className="heading text-start mb-2">Tan Number</h5>
              <p className="text-start mb-3">
                Please be advised that in order to facilitate the deduction of
                TDS (Tax Deducted at Source) from your transactions, it is
                imperative to provide your TAN (Tax Deduction and Collection
                Account Number).
              </p>
              <div className="shadow p-4 pb-3 border border-light">
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="" className="text-muted">
                      Tan Number
                    </label>
                    <input
                      value={tanNumber || ""}
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setTanNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="offset-4 col-lg-2 d-flex align-items-end justify-content-end">
                    <button className="btn btn-primary" onClick={saveTanNumber}>
                      {loading ? "loading..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 col-12 mb-4">
              <PaymentGatewayDetails />
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 col-12 mb-4">
              <SelectPaymentGatewayTable
                setLoading={(loading) => setLoadingState(loading)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
