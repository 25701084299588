import React from 'react'

import {Container,Dialog,makeStyles,AppBar,Toolbar,Typography,IconButton,Slide} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    spacer: {
        margin: theme.spacing(2)
    }
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
export default function PackageDailog(props){
    const classes = useStyles()

return(
    <>
        <Dialog open={props.open} onClose={props.close}
        TransitionComponent={Transition}
        fullScreen>

        <AppBar className={classes.appBar}>
        <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="close">
            <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            {props.name}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={props.close}>
            save
            </Button> */}
        </Toolbar>
        </AppBar>
            <div className={classes.spacer}></div>
            <Container>
                {props.dialogContent()}
            </Container>
        </Dialog>
    </>
)

}