import { SippiAccountsApi } from "apiCalls/SippiAccounts.api";
import { useAppDispatch } from "hooks/reduxHooks";
import _ from "lodash";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { SET_LOADER } from "redux/actions/common.actions";

export const AccountAddDiscountPopup: React.FC<{
  id: number;
  discount: number;
  total: number;
  isPaid: boolean;
  adminRemark: string;
  hidePopup: () => void;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [discount, setDiscount] = useState<number | undefined>(props.discount);
  const [isPaidState, setIsPaidState] = useState(props.isPaid);
  const [adminRemark, setAdminRemark] = useState(props.adminRemark);

  const updateinvoiceDetails = async () => {
    try {
      dispatch(SET_LOADER(true));

      await SippiAccountsApi.updateInvoiceData({
        id: props.id,
        discount: discount,
        isPaid: isPaidState,
        adminRemark: adminRemark,
      });

      toast.success("Invoice details updated successfully");
      props.hidePopup();
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    }
    dispatch(SET_LOADER(false));
  };

  return (
    <Modal
      show={true}
      onHide={() => {
        props.hidePopup();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-center w-100"
        >
          Change Invoice Popup
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-7">
            <label htmlFor="">Discount</label>
            <input
              value={discount}
              className="form-control"
              onChange={(event) => {
                let newDiscount: number | undefined = undefined;
                try {
                  newDiscount = parseInt(event.target.value);
                  if (_.isNaN(newDiscount)) {
                    throw new Error("Invalid discount");
                  }
                } catch (err) {
                  newDiscount = undefined;
                }
                setDiscount(newDiscount);
              }}
            />
          </div>
          <div className="col-md-7 my-2">
            <input
              checked={isPaidState}
              // className="form-control"
              type="checkbox"
              name="is_paid"
              className="me-2"
              // value={true}
              onChange={(event) => {
                setIsPaidState(event.target.checked);
              }}
            />
            <label htmlFor="">Is Paid</label>
          </div>
          <div className="col-md-7 mb-3">
            <label htmlFor="">Admin Remark</label>
            <textarea
              // className="form-control"
              value={adminRemark}
              className="form-control"
              // value={true}
              onChange={(event) => {
                setAdminRemark(event.target.value);
              }}
            ></textarea>
          </div>
          <div className="col-md-7">
            <button
              className="btn btn-primary mt-1"
              onClick={() => {
                updateinvoiceDetails();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
