import React from "react";
import { Route } from "react-router-dom";
import Header from "components/user/common/Header";
import Footer from "components/user/common/footer/Footer";
export default function UserPublicRoutes({ component: Component, ...rest }) {
  return (
    <>
      <Header />
      <Route {...rest} render={(props) => <Component {...props} />} />
      <Footer />
    </>
  );
}
