import React from "react";
import { TboCredentials } from "./TboCredentials";

export const ApiCredentials = () => {
  return (
    <div>
      <div className="container">API Credentials</div>

      <TboCredentials />
    </div>
  );
};
