import React from 'react'

export default function TestMailResponse({testEmailResponse}) {
    return (
        <div style={{
            background: '#dedede',
            padding: '1rem',
            color: '#333',
            borderRadius: '10px',
            margin: '10px auto',
            overflowX: 'scroll'
        }}>
            <pre>
               <b>Server Response:</b>
                {JSON.stringify(testEmailResponse,null,2)}
            </pre>
        </div>
    )
}
