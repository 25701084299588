import React from 'react'
import AdminRoutes from 'components/routes/AdminRoutes'
import ListPackages from './ListPackages'

export default function Index() {
    return (
        <>
            <AdminRoutes exact path={'/admin/packages'}
                        noMenu={true} component={ListPackages}/>          
        </>
    )
}
