import { flightApiTypes } from "redux/actionTypes/flightApi.types";

export interface IAirline {
  _id: string;
  text: string;
  value: string;
  isMultiStop?: boolean;
  isAdvanceSearch?: boolean;
  logo?: IAirlineLogo;
  isGDSSpecialReturn?: boolean;
  isSpecial?: boolean;
  isLCCSpecialReturn?: boolean;
}

export interface IAirlinesData {
  id: number;
  airlineName: string;
  airlineCode: string;
  logoUrl: string;
  isLCC: boolean | null;
  isDeprecated: boolean;
}
export interface IAirlineLogo {
  logoUrl: string;
  s3Obj_id: string;
}

export interface IFlightReducerState {
  tbo: {
    allBookings: Array<any>;
  };
  allAirlines: Array<IAirline>;
}

const initialState: IFlightReducerState = {
  tbo: {
    allBookings: [],
  },
  allAirlines: [],
};

export const flightApiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case flightApiTypes.TBO_SET_ALL_BOOKINGS:
      return {
        ...state,
        tbo: {
          ...state.tbo,
          allBookings: payload,
        },
      };

    case flightApiTypes.SET_ALL_AIRLINES:
      return {
        ...state,
        allAirlines: payload,
      };

    default:
      return state;
  }
};
