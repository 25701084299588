import React from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import PropTypes from 'prop-types'

function index(props) {
    return (
            <FormControlLabel control={<Radio />} {...props} />
    )
}

index.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

export default index