import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRequest, postRequest } from "tools/ajax";
import { DownloadExcelButton } from "./DownloadExcelButton";
import { toast } from "react-toastify";
import moment from "moment";

export const SippiAccountsReport = () => {
  const [loading, setLoading] = useState(false);
  const [dailyBillingLoading, setDailyBillingLoading] = useState(false);
  const [cacheLoading, setCacheLoading] = useState(false);
  const [billingReport, setBillingReport] = useState<{
    totalSitesWithDeductedBills: number;
    totalNoOfEligibleSites: number;
  }>();
  const getPendingBillsReport = async () => {
    if (loading) return;
    setLoading(true);
    const pendingBillsReport = await getRequest(
      "billing/getNegativebalanceReport",
      {}
    );
    setLoading(false);
    return pendingBillsReport.result || [];
  };

  const getBillingReport = async () => {
    const apiRes = await getRequest("billing/getBillingReport", {});
    if (apiRes.success) {
      setBillingReport(apiRes.result);
    }
  };

  const startBillingCron = async () => {
    if (dailyBillingLoading) {
      return;
    }
    setDailyBillingLoading(true);
    const apiRes = await getRequest("billing/startBillingCron", {});
    if (apiRes?.success) {
      getBillingReport();
      toast.success("Billing completed");
    } else {
      toast.error(apiRes?.errorMessage);
    }
    setDailyBillingLoading(false);
  };

  const startFixDepartureSectorsCaching = async () => {
    if (cacheLoading) {
      return;
    }
    setCacheLoading(true);
    const apiRes = await postRequest(
      "admin/cron/startFixDepartureSectorsCaching",
      {}
    );
    if (apiRes.success) {
      toast.success("Cashing triggered");
    } else {
      toast.error(apiRes?.errorMessage);
    }
    setCacheLoading(false);
  };

  useEffect(() => {
    getBillingReport();
  }, []);

  return (
    <div className="container">
      <p className="h4">Sippi Accounts Report</p>
      <div className="d-flex align-items-center justify-content-center">
        <Link to="/admin/accounts/setup_fee">
          <div className="shadow p-2 m-2 h4 text-primary">Setup Fee Report</div>
        </Link>
        <Link to="/admin/accounts/monthly_charges">
          <div className="shadow p-2 m-2 h4 text-primary">
            Monthly Charges Report
          </div>
        </Link>
        <Link to="/admin/accounts/site-wise-productivity">
          <div className="shadow p-2 m-2 h4 text-primary">
            Site Wise Productivity
          </div>
        </Link>
        <Link to="/admin/accounts/billing_slots">
          <div className=" shadow p-2 m-2 h4 text-primary border">
            Slot Wise Billing Status
          </div>
        </Link>
      </div>{" "}
      <div className="container  mt-4 w-100 ">
        <div className="row border rounded shadow shadow-sm p-3 mb-3">
          <div className="col-9 ">
            <h3 className="mb-1 text-secondary">
              Download Pending Invoices Report
            </h3>
            <p className="small">
              Clicking this button will allow you to download an Excel file
              containing a list of all pending invoices. This report includes
              the domains of the respective users whose invoices are pending
              along with the pending amounts (Monthly). <br />
              Please Note: This process might take a while to complete because
              an intensive algorithm is being executed in the backend to
              generate this report. Thank you for your patience.
            </p>
          </div>
          <div className="col-3 text-end d-flex align-items-end justify-content-end">
            <DownloadExcelButton
              fileName="pendingBills"
              getData={getPendingBillsReport}
            >
              {loading ? "loading.." : "Download Pending Bills Report"}
            </DownloadExcelButton>
          </div>
        </div>
        <div className="row border rounded shadow shadow-sm p-3 mb-3">
          <div className="col-9 ">
            <h3 className="mb-1 text-secondary">
              Trigger Daily Billing Cron{" "}
              {dailyBillingLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </h3>
            <p className="small">
              Please be informed that clicking this button will trigger a cron
              method. This method is designed to manually deduct the daily
              billing amount from your customer wallet. Please note that if the
              amount has already been deducted for the day, the method will skip
              the account to avoid any double deductions. <br />
              <i>Thank you for your understanding and cooperation.</i>
            </p>
          </div>
          <div className="col-3 text-end d-flex flex-column align-items-end justify-content-end">
            <b>Total Billable Sites: {billingReport?.totalNoOfEligibleSites}</b>{" "}
            <br />
            <b>
              Bill Deducted For Sites:{" "}
              {billingReport?.totalSitesWithDeductedBills}
            </b>{" "}
            <br />
            <button
              className="btn btn-outline-primary"
              onClick={startBillingCron}
              disabled={dailyBillingLoading}
            >
              <i className="fas fa-bolt me-1 text-warning"></i> Start Billing
              For {moment().format("DD-MMM-YYYY")}
            </button>
          </div>
        </div>
        <div className="row border rounded shadow shadow-sm p-3">
          <div className="col-9 ">
            <h3 className="mb-1 text-secondary">
              Trigger Fix Departure Sectors Caching{" "}
              {cacheLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </h3>
          </div>
          <div className="col-3 text-end d-flex flex-column align-items-end justify-content-end">
            <button
              disabled={cacheLoading}
              className="btn btn-outline-primary"
              onClick={startFixDepartureSectorsCaching}
            >
              <i className="fas fa-fighter-jet me-1 text-warning"></i> Cache
              fix-departure sectors
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
