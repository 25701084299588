import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import Button from "commonUi/button/btnPrimary";
import GridContainer from "commonUi/grid/container";
import GridItem from "commonUi/grid/item";
import TxtInp from "commonUi/input/text";
import {
  postWhitelabelWallet,
  getWhitelabelWallet,
} from "apiCalls/whitelabelDb";
import InputAdornment from "@material-ui/core/InputAdornment";
import TransactionHistory from "./transactionHistory";
import { Link } from "react-router-dom";
import { printAmount } from "tools/commonFuncions";
import { SearchCustomer } from "./SearchCustomer";
import { AdminRouteConstants } from "constants/routes";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  gridContainer: {
    marginTop: "1rem",
  },
  searchInp: {
    margin: "10px auto",
    width: "100%",
  },
  imgHeight: {
    maxHeight: "100px",
    maxWidth: "100%",
    width: "auto",
    height: "auto",
    margin: "1px auto",
    objectFit: "cover",
  },
});

export default function Wallet() {
  const classes = useStyles();
  const [amount, setAmount] = useState("");
  const [remark, setRemark] = useState("");
  const [whitelabel, setWhitelabel] = useState({});
  const [debitCredit, setDebitCredit] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [totalAmountFromWallet, setTotalAmountFromWallet] = useState("0");
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    console.log(typeof transactions, "\n", transactions);
  }, [transactions]);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const handleDebitCreditChange = (e) => {
    setDebitCredit(e.target.value);
    console.log(e.target.value);
  };

  const getTotalAmountForWl = async (id) => {
    const totalAmount = await getWhitelabelWallet({
      siteId: id,
    });
    setTotalAmountFromWallet(totalAmount.totalNumber);
    calculateBalance(totalAmount.transactions);
  };

  const calculateBalance = (transactions) => {
    let balance = 0;
    const transactionsWithBalance = transactions.map((transaction) => {
      switch (transaction.debit_credit) {
        case "credit":
          balance = balance + transaction.amount;
          return {
            ...transaction,
            balance,
          };
        case "debit":
          balance = balance - transaction.amount;
          return {
            ...transaction,
            balance,
          };
        default:
          toast.error("Debit/Credit not found!");
      }
      return null;
    });
    setTransactions(transactionsWithBalance);
  };

  const submitAmount = async (e) => {
    e.preventDefault();
    if (amount > 500000 || amount < 1)
      return toast.error("Amount cannot exceed 5 lakhs or can be less than 0");
    if (debitCredit === "")
      return toast.error("Please Select Debit/Credit Amount");
    setDisabled(true);
    const data = {
      siteId: whitelabel._id,
      amount: amount,
      remark: remark,
      debit_credit: debitCredit,
    };
    const result = await postWhitelabelWallet(data);
    if (!result) {
      toast.error("Transaction Failed!");
      setDisabled(false);
    }
    setAmount("");
    setRemark("");
    setDebitCredit("");
    getTotalAmountForWl(whitelabel._id);
    setDisabled(false);
  };

  const setWl = (whitelabel) => {
    setWhitelabel(whitelabel);
    setTotalAmountFromWallet("0");
    setTransactions([]);
    getTotalAmountForWl(whitelabel._id);
  };

  return (
    <>
      <Typography variant="h4" style={{ textAlign: "center" }}>
        Wallet
      </Typography>
      <Link to={`${AdminRouteConstants.ADMIN_WALLET}/allEntries_temp`}>
        <Typography style={{ textAlign: "center" }}>
          List All Transactions
        </Typography>
      </Link>
      <GridContainer
        className={classes.gridContainer}
        spacing={2}
        justify="center"
      >
        <GridItem xs={12} sm={6}>
          <SearchCustomer setWhitelabel={setWl} />
        </GridItem>
        <Grid item xs={12}></Grid>
        {whitelabel &&
          Object.keys(whitelabel).length !== 0 &&
          whitelabel.constructor === Object && (
            <GridItem xs={12} sm={6}>
              <Grid container justify="center">
                <Grid item xs={12} md={6} className="text-center">
                  <img
                    src={whitelabel.logoUrl}
                    alt={whitelabel.companyName}
                    className={classes.imgHeight}
                  />
                </Grid>
                <Grid item xs={12} md={6} className="text-center">
                  <Typography variant="h6" color="initial">
                    {whitelabel.companyName}
                  </Typography>
                  <Typography color="initial">
                    Domain: {whitelabel.domain}
                  </Typography>
                  <Typography color="initial">
                    Site: {whitelabel.siteName}
                  </Typography>
                  <Typography variant="h3" color="initial">
                    ₹ {printAmount(totalAmountFromWallet)}
                  </Typography>
                </Grid>

                <form onSubmit={submitAmount} autoComplete="off">
                  <TxtInp
                    type="number"
                    name="amount"
                    placeholder="Enter Amount"
                    value={amount}
                    className={classes.searchInp}
                    label="Amount"
                    onWheel={(event) => event.target.blur()}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    onChange={handleAmountChange}
                  />
                  <RadioGroup
                    aria-label="Action"
                    name="gender1"
                    value={debitCredit}
                    onChange={handleDebitCreditChange}
                  >
                    <FormControlLabel
                      value="debit"
                      control={<Radio />}
                      label="Debit Amount"
                    />
                    <FormControlLabel
                      value="credit"
                      control={<Radio />}
                      label="Credit Amount"
                    />
                  </RadioGroup>
                  <TxtInp
                    name="remark"
                    value={remark}
                    className={classes.searchInp}
                    label="Remarks"
                    required
                    multiline
                    rows={5}
                    onChange={handleRemarkChange}
                  />
                  <Button label="Submit" type="submit" disabled={disabled} />
                </form>
              </Grid>
            </GridItem>
          )}
        <Grid item xs={12}></Grid>
        {transactions.length ? (
          <GridItem>
            <Typography variant="h6" color="initial" align="center">
              Transaction History
              <br />
              <small>({transactions.length} Records)</small>
            </Typography>
            <TransactionHistory transactionArray={transactions} />
          </GridItem>
        ) : (
          ""
        )}
      </GridContainer>
    </>
  );
}
