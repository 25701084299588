import React, { MouseEventHandler, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";

const Transition: any = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  // children: React.ReactNode;
  title: string;
  isOpen: boolean;
  handleClose: MouseEventHandler<HTMLAnchorElement>;
  // handleClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void | MouseEventHandler<HTMLAnchorElement>;
  loading?: boolean;
}

const Index: React.FC<IProps> = (props) => {
  const [dialogProps, setDialogProps] = useState({});

  useEffect(() => {
    // deleteing some properties from props in order to avoid errors
    // these properties are not expected to be passed to dom
    const dialogProps: any = { ...props };
    delete dialogProps.isOpen;
    delete dialogProps.handleClose;
    setDialogProps(dialogProps);
  }, [props]);

  return (
    <div>
      <Dialog
        open={Boolean(props.isOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby={props.title}
        {...dialogProps}
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
          {props.loading && <LinearProgress />}
          {props.children}
          {props.loading && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" href="">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Index;
