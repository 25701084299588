import { Button, Modal } from "react-bootstrap";
import { IAirport } from "./AirportsSettings";

export const AddAirportModal: React.FC<{
  onHide: () => void;
  show: boolean;
  airportDetails: IAirport;
  handleAirportDetails: (e: any) => any;
  upsertAirport: () => void;
  loading: boolean;
}> = (props) => {
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="fas fa-rocket text-warning"></i>{" "}
          {props.airportDetails.id ? "Update Airport Data" : "Add New Airport"}{" "}
          <i className="fas fa-rocket text-warning"></i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row" id="update-airport">
          <div className="col-4 offset-2 mb-3">
            <label htmlFor="">Airport Name</label>
            <input
              onChange={props.handleAirportDetails}
              type="text"
              className="form-control"
              name="airportName"
              value={props.airportDetails?.airportName || ""}
            />
          </div>
          <div className="col-4 offset-0 mb-3">
            <label htmlFor="">Airport Code</label>
            <input
              onChange={props.handleAirportDetails}
              type="text"
              className="form-control"
              name="airportCode"
              value={props.airportDetails?.airportCode || ""}
            />
          </div>
          <div className="col-4 offset-2 mb-3">
            <label htmlFor="">Country Name</label>
            <input
              onChange={props.handleAirportDetails}
              type="text"
              className="form-control"
              name="countryName"
              value={props.airportDetails?.countryName || ""}
            />
          </div>
          <div className="col-4 offset-0 mb-3">
            <label htmlFor="">Country Code</label>
            <input
              onChange={props.handleAirportDetails}
              type="text"
              className="form-control"
              name="countryCode"
              value={props.airportDetails?.countryCode || ""}
            />
          </div>
          <div className="col-4 offset-2 mb-3">
            <label htmlFor="">City Name</label>
            <input
              onChange={props.handleAirportDetails}
              type="text"
              className="form-control"
              name="cityName"
              value={props.airportDetails?.cityName || ""}
            />
          </div>
          <div className="col-4 offset-0 mb-3">
            <label htmlFor="">City Code</label>
            <input
              onChange={props.handleAirportDetails}
              type="text"
              className="form-control"
              name="cityCode"
              value={props.airportDetails?.cityCode || ""}
            />
          </div>
          <div className="col-4 offset-2 mb-3">
            <label htmlFor="">Time Zone</label>
            <input
              onChange={props.handleAirportDetails}
              type="text"
              className="form-control"
              name="timezone"
              value={props.airportDetails?.timezone || ""}
            />
          </div>{" "}
          <div className="col-4 offset-0 mb-3">
            <label htmlFor="">Latitude</label>
            <input
              onChange={props.handleAirportDetails}
              type="text"
              className="form-control"
              name="latitude"
              value={props.airportDetails?.latitude || ""}
            />
          </div>{" "}
          <div className="col-4 offset-2 mb-3">
            <label htmlFor="">Longitude</label>
            <input
              onChange={props.handleAirportDetails}
              type="text"
              className="form-control"
              name="longitude"
              value={props.airportDetails?.longitude || ""}
            />
          </div>{" "}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <button className="btn btn-success" onClick={props.upsertAirport}>
          {props.loading
            ? "loading..."
            : props.airportDetails.id
            ? "Update"
            : "+Add"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
