import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextInp from "commonUi/input/text";
import { Typography } from "@material-ui/core";
import BtnPrimary from "commonUi/button/btnPrimary";
import { saveSiteSmtpDetails } from "apiCalls/siteSettingsDb";
import RadioGrp from "commonUi/radio/radioGrp";
import RadioItem from "commonUi/radio/radioItem";
import { useDispatch } from "react-redux";
import { SET_ALERT_MESSAGE } from "redux/actions/common.actions";
import { GET_SMTP_DETAILS } from "redux/actions/siteSettings.actions";
import { Form } from "react-bootstrap";

const TxtInp = (props: {
  label: string;
  name: string;
  error: boolean;
  inputRef: (instance: HTMLInputElement | null) => void;
  type?: "number" | "password";
  helperText?: string;
  autoComplete?: "off" | "on";
}) => {
  return (
    <div style={{ padding: "0.5rem" }}>
      <Form.Group>
        <b className="text-muted">{props.label}</b>
        <Form.Control
          ref={props.inputRef}
          type={props.type || "text"}
          style={{ width: "100%" }}
          isInvalid={props.error}
          autoComplete={props.autoComplete || "on"}
          name={props.name}
        />
        <p>{props?.helperText}</p>
      </Form.Group>
    </div>
  );
};

export default function SmtpForm() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [form, updateForm] = useState({
    secure: "secure",
  });

  const handleChange = ({ target }) => {
    console.log(target.name, target.value);
    updateForm((old) => ({ ...old, [target.name]: target.value }));
    console.log("changed");
  };

  const submitSmtp = async (data) => {
    data.secure = form.secure === "secure" ? true : false;
    const r = await saveSiteSmtpDetails(data);
    if (r.err) return dispatch(SET_ALERT_MESSAGE(r.err));
    dispatch(GET_SMTP_DETAILS());
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitSmtp)}>
        <Typography variant="h6">SMTP Config</Typography>

        <TxtInp
          label="Host"
          name="host"
          error={errors.host ? true : false}
          inputRef={register({ required: true })}
        />

        <TxtInp
          label="Port Number"
          name="port"
          type="number"
          error={errors.port ? true : false}
          inputRef={register({ required: true })}
        />

        <TxtInp
          label="Sender Name"
          name="senderName"
          error={errors.user ? true : false}
          inputRef={register({ required: true })}
          helperText="Sender Name will be dispalyed on each email"
        />

        <TxtInp
          label="User"
          name="user"
          error={errors.user ? true : false}
          inputRef={register({ required: true })}
        />

        <TxtInp
          label="Password"
          name="pass"
          type="password"
          autoComplete="off"
          error={errors.pass ? true : false}
          inputRef={register({ required: true })}
        />

        <RadioGrp
          label="Secure"
          name="secure"
          onChange={handleChange}
          value={form.secure}
        >
          <RadioItem label="Yes" value="secure" />
          <RadioItem label="No" value="notSecure" />
        </RadioGrp>

        <BtnPrimary label="Save Configuration" type="submit" />
      </form>
    </>
  );
}
