import React, { useEffect } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DiscountMarkupTextField } from "./components/DiscountMarkupTextField";
import { useState } from "react";
import { IAgentGroup, IDefaultMarkup, IDefaultMarkupForm } from "./types";
import { Autocomplete } from "@material-ui/lab";
import { Radio } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { flightAxios } from "./commonFlightAjax";
import { FullScreenLoader } from "./components/FullScreenLoader";
import { getWhitelabelGroups } from "apiCalls/whitelabelDb";
import { ERROR_MESSAGES, isInvalidField } from "./tools/utils";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ErrorHelperList } from "./components/ErrorHelperText";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        textAlign: "center",
        // width: 200,
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

interface IDefaultMarkupFormState {
  agentGroupId: IAgentGroup | null;
  isAbsoluteMarkup: boolean;
  markupValue: number;
  isLCC: boolean;
  isDomestic: boolean;
  userGroupId: string | null;
}

export const DefaultMarkupSettings: React.FC = (props) => {
  const classes = useStyles();

  const initialFormState: IDefaultMarkupForm = {
    isDomestic: { value: true, error: [], validation: ["required"] },
    isLCC: {
      value: false,
      error: [],
      validation: ["required"],
    },
    markupValue: {
      value: 0,
      error: [],
      validation: ["required"],
    },
    agentGroupId: { value: null, error: [], validation: ["required"] },
    userGroupId: { value: "", error: [], validation: [] },
    isAbsoluteMarkup: { value: false, error: [], validation: ["required"] },
  };

  const [formState, setFormState] =
    useState<IDefaultMarkupForm>(initialFormState);

  const [loadingState, setLoadingState] = useState(false);
  const [defaultMarkupState, setDefaultMarkupState] = useState<
    Array<IDefaultMarkup>
  >([]);
  // const [selectedAirlineState, setSelectedAirlineState] = useState<IAirline | {
  //     text: any
  // } | undefined>({ text: '' })
  const [agentGroupsState, setAgentGroupsState] = useState<Array<IAgentGroup>>(
    []
  );
  const [selectedAgentGroup, setSelectedAgentGroup] = useState<
    | IAgentGroup
    | {
        groupName: any;
      }
    | undefined
  >({ groupName: "" });

  useEffect(() => {
    getDefaultAirlineMarkups();
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    if (agentGroupsState.length) {
      setSelectedAgentGroup(
        agentGroupsState.find(
          (agent) => agent.id === formState.agentGroupId.value?.id
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.agentGroupId]);

  // useEffect(() => {
  //     setSelectedAirlineState(props.airlines.find(air => air._id == formState.airlineId.value))
  // }, [formState.airlineId])

  // const handleAirlineChange = (e, value) => {
  //     if (value) {
  //         setFormState({
  //             ...formState,
  //             airlineId: {
  //                 ...formState.airlineId,
  //                 value: value._id,
  //                 error: []
  //             }
  //         })
  //     }
  // }

  const handleAgentGroupChange = (e, value) => {
    if (value) {
      setFormState({
        ...formState,
        agentGroupId: {
          ...formState.agentGroupId,
          value: value,
          error: [],
        },
      });
    }
  };

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: {
        ...formState[e.target.name],
        value: e.target.value,
        error: [],
      },
    });
  };

  // const handleDefaultChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

  //     setFormState({
  //         ...formState,
  //         isDefault: {
  //             ...formState.isDefault,
  //             value: checked,
  //             error: []
  //         },

  //     })
  // }

  const getDefaultAirlineMarkups = async () => {
    let defaultMarkupResponse: AxiosResponse;
    let agentGroupsResponse;
    setLoadingState(true);
    try {
      defaultMarkupResponse = await flightAxios({
        url: `defaultAirlineMarkup`,
        method: "GET",
      });

      agentGroupsResponse = await getWhitelabelGroups();

      if (agentGroupsResponse.success) {
        setAgentGroupsState(agentGroupsResponse.result);
      }
    } catch (error) {
      toast.error("Error connecting to server. ");
      return;
    }

    if (defaultMarkupResponse.data) {
      setDefaultMarkupState(defaultMarkupResponse.data.result);
    }
    setLoadingState(false);
  };

  const handleDomesticChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let isDomestic: boolean = value === "true" || value === "1";
    setFormState({
      ...formState,
      isDomestic: {
        ...formState.isDomestic,
        value: isDomestic,
        error: [],
      },
    });
  };

  const handleAbsoluteMarkupChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    let isAbsoluteMarkup: boolean =
      event.target.value === "true" || event.target.value === "1";

    setFormState({
      ...formState,
      // markupValue: {
      //   ...formState.markupValue,
      //   validation: toggleValidation(
      //     formState.markupValue.validation,
      //     "percentage"
      //   ),
      // },
      isAbsoluteMarkup: {
        ...formState.isAbsoluteMarkup,
        value: isAbsoluteMarkup,
        error: [],
      },
    });
  };

  const handleDefaultMarkupEditBtn = (markupRowIndx) => {
    let formData = { ...formState };

    Object.keys(formData).forEach((key) => {
      formData[key] = {
        ...formData[key],
        value: defaultMarkupState[markupRowIndx][key],
      };
    });

    setFormState(formData);
  };

  const restructureFormDataForServer = (formData: IDefaultMarkupFormState) => {
    //copying value of formData into a new variable because typescript wont allow to reassign agentGroupId as a string/number
    let newFormData: any = Object.assign({}, formData);

    if (formData.agentGroupId) {
      newFormData.agentGroupId = formData.agentGroupId.id;
      return newFormData;
    } else {
      toast.error("Invalid Input");
      return null;
    }
  };

  const handleLCCChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let isLCC: boolean = value === "true" || value === "1";
    setFormState({
      ...formState,
      isLCC: {
        ...formState.isDomestic,
        value: isLCC,
        error: [],
      },
    });
  };

  const handleDefaultMarkupSave = async () => {
    setLoadingState(true);

    // initial Form Data (dummy values)
    let formData: IDefaultMarkupFormState = {
      agentGroupId: null,
      isAbsoluteMarkup: false,
      markupValue: 0,
      isLCC: false,
      isDomestic: false,
      userGroupId: null,
    };

    let newFormDataState = { ...formState };

    let errorExists = false;

    Object.keys(newFormDataState).forEach((fieldKey) => {
      formData[fieldKey] = newFormDataState[fieldKey].value;

      if (isInvalidField(newFormDataState[fieldKey])) {
        newFormDataState[fieldKey].error = ["This Field is invalid"];
        errorExists = true;
      }
    });

    setFormState(newFormDataState);
    console.log("errorExists", errorExists);

    if (!errorExists) {
      try {
        const newFormData = restructureFormDataForServer(formData);
        //extracting id from agentId Object (received after sql join)
        let saveResponse = await flightAxios({
          url: "/defaultAirlineMarkup",
          data: newFormData,
        });

        await getDefaultAirlineMarkups();

        let backendErrors = saveResponse.data.errors;
        if (backendErrors) {
          let newFormDataState = { ...formState };
          Object.keys(newFormDataState).forEach((fieldKey) => {
            if (backendErrors[fieldKey]) {
              newFormDataState[fieldKey].error = backendErrors[fieldKey];
            }
          });
          setFormState(newFormDataState);
        } else {
          setFormState(initialFormState);
        }
      } catch (error) {
        toast.error(ERROR_MESSAGES.SERVER_TRY_LATER);
      }
    } else {
    }
    setLoadingState(false);
  };

  return (
    <>
      <FullScreenLoader show={loadingState} />
      <div>
        <form
          className={`${classes.root} text-center`}
          noValidate
          autoComplete="off"
        >
          <div>
            <div className="my-4">
              <Typography variant="h5">Default Markup Settings</Typography>
              <Typography>
                If Flight specific markup is not present, then default markup
                will be applicable.
              </Typography>
            </div>

            {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={formState.isDefault.value} onChange={handleDefaultChange} name="isDefault" />}
                                    label="Is Default ?"
                                />
                            </Grid> */}

            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="isDomestic"
                name="isDomestic"
                value={formState.isDomestic.value}
                onChange={handleDomesticChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Domestic"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="International"
                />
              </RadioGroup>
              <ErrorHelperList errors={formState.isDomestic.error} />
            </FormControl>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="isLCC"
                  name="isLCC"
                  value={formState.isLCC.value}
                  onChange={handleLCCChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="LCC"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Non LCC"
                  />
                </RadioGroup>
                <ErrorHelperList errors={formState.isDomestic.error} />
              </FormControl>
            </Grid>

            <Grid container>
              <Autocomplete
                className="w-100"
                options={agentGroupsState}
                getOptionLabel={(option) => option?.groupName}
                value={selectedAgentGroup}
                onChange={handleAgentGroupChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Select Site Group"
                    variant="outlined"
                    className="mx-auto w-100 my-2"
                    error={formState.agentGroupId.error.length > 0}
                  />
                )}
              />
            </Grid>
            <ErrorHelperList errors={formState.agentGroupId.error} />
            <Grid container>
              <Grid item xs={4}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className="mt-2 w-100"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={formState.isAbsoluteMarkup.value}
                    onChange={handleAbsoluteMarkupChange}
                  >
                    <MenuItem value="true">Fixed</MenuItem>
                    <MenuItem value="false">Percentage</MenuItem>
                  </Select>
                  <ErrorHelperList errors={formState.isAbsoluteMarkup.error} />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <DiscountMarkupTextField
                  name="markupValue"
                  label="Markup Value"
                  value={formState.markupValue}
                  onChange={handleInputChange}
                  textFieldProps={{
                    type: "number",
                    className: "w-100",
                  }}
                />
              </Grid>
            </Grid>

            {/* {
                                !formState.isDefault.value && (
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={props.airlines}
                                            getOptionLabel={(option) => option.text}
                                            value={selectedAirlineState}
                                            onChange={handleAirlineChange}
                                            style={{ width: 300 }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Airline"
                                                    variant="outlined"
                                                    error={formState.airlineId.error.length > 0}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )
                            } */}

            <Button
              className="mt-2 mb-5 mx-auto"
              color="primary"
              variant="contained"
              onClick={handleDefaultMarkupSave}
            >
              Save Default Markup
            </Button>

            {defaultMarkupState?.map((air, index) => {
              return (
                <div className="shadow d-flex justify-content-around align-items-center p-3 m-4">
                  <div>
                    <div>
                      <p className="mb-0">
                        <small>Markup</small>
                      </p>
                    </div>
                    <div>
                      <p>
                        {air.markupValue}
                        {!air.isAbsoluteMarkup && "%"}
                      </p>
                    </div>
                  </div>
                  <div className="text-center">
                    {air.isLCC ? "LCC" : "Non LCC"}
                  </div>
                  <div>
                    <p className="bg-dark text-white rounded px-1">
                      {air.agentGroupId.groupName}
                    </p>

                    {air.isDomestic ? "Domestic" : "International"}
                  </div>
                  <Button
                    onClick={() => handleDefaultMarkupEditBtn(index)}
                    variant="contained"
                    color="primary"
                    size="small"
                    className="mt-2"
                  >
                    Edit
                  </Button>
                </div>
              );
            })}
          </div>
        </form>
      </div>
    </>
  );
};
