import React from "react";
import { makeStyles, List, ListItem, ListItemText } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { AdminRouteConstants } from "constants/routes";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Offcanvas } from "react-bootstrap";

export default function TopDrawer(props) {
  const history = useHistory();

  const menuItem = (name, path, func) => {
    const ListContent = () => {
      return (
        <ListItem button>
          <ListItemText>{name}</ListItemText>
        </ListItem>
      );
    };

    return func ? (
      <div onClick={func}>
        <ListContent />
      </div>
    ) : (
      <Link to={path} onClick={() => props.close()}>
        <ListContent />
      </Link>
    );
  };

  return (
    <Offcanvas show={props.open} onHide={props.close} placement="end">
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <List>
          {menuItem("White Label", "/admin/whiteLabel")}
          {menuItem("Sippi Staff", "/admin/sippiStaff")}
          {menuItem("Master", "/admin/master")}
          {menuItem("Packages", "/admin/packages")}
          {menuItem("Supplier Management", "/admin/suppliers")}
          {menuItem("Sippi Customers", "/admin/sippiCustomers")}
          {menuItem("Flight Module Settings", "/admin/flight_module_settings")}
          {menuItem("Airports Settings", "/admin/airports_settings")}
          {menuItem("APIs", "/admin/api/dashboard")}
          {menuItem("Visa", "/admin/visa")}
          {menuItem("Whitelabel Wallet", AdminRouteConstants.ADMIN_WALLET)}
          {menuItem("Settings", "/admin/settings")}
          {menuItem("Accounts", "/admin/accounts")}
          {/* <ListItem button onClick={handleClick}>
          <ListItemText primary="Inbox" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Starred" />
            </ListItem>
          </List>
        </Collapse> */}
          {menuItem("Advance Settings", "/admin/advance_settings")}
          {menuItem(
            "Payment Gateway Transactions",
            AdminRouteConstants.ADMIN_PG_TXN
          )}
          {menuItem(
            "Compare TBO Excel",
            AdminRouteConstants.ADMIN_COMPARE_TBO_EXCEL
          )}{" "}
          {menuItem(
            "Sippi Cutomer Transactions",
            "/admin/sippi_customer_transactions"
          )}
          {menuItem("Logout", "", () => {
            localStorage.removeItem("auth-token");
            history.push("/admin");
          })}
        </List>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
