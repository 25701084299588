import { MasterApi } from "apiCalls/masterDb";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AdminMasterMenu } from "../AdminMasterMenu";
import { MysqlCityLogs } from "./MysqlCityLogs";

export const MysqlCountryCity = () => {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [isEditCityModalOpen, setIsEditCityModalOpen] = useState(false);
  const [newCity, setNewCity] = useState<
    | {
        city: string;
        lat: number;
        lon: number;
        country?: string;
        country_iso2?: string;
      }
    | any
  >({
    city: "",
    lat: 0,
    lon: 0,
    country: "",
    country_iso2: "",
  });
  useEffect(() => {
    getMysqlCountries();
  }, []);

  const getMysqlCountries = async () => {
    const response = await MasterApi.getCountries();
    if (!response.success) return toast.error("Error getting countries");
    console.log(response);
    setCountries(response.result);
  };

  const getCitiesByCountry = async (country: any) => {
    setSelectedCountry(country);
    const countryJson = JSON.parse(country);
    const response = await MasterApi.getCitiesByCountryCode(
      countryJson?.countryCode
    );
    if (!response.success) return toast.error("Error getting cities");
    console.log(response);
    setCities(response.result);
    setNewCity({
      ...newCity,
      country_iso2: countryJson.countryCode,
      country: countryJson.countryName,
    });
  };

  const closeEditCityModal = () => {
    setIsEditCityModalOpen(false);
  };

  const handleNewCityInputChange = ({ target }) => {
    console.log(target.value, target.name);
    setNewCity({
      ...newCity,
      [target.name]: target.value,
    });
  };

  const saveCity = async () => {
    if (!newCity.city || !newCity.lat || !newCity.lon)
      return toast.error("Please fill all fields");
    const response = await MasterApi.saveCity(newCity);
    console.log(response);
    getCitiesByCountry(selectedCountry);
    closeEditCityModal();
  };

  const deleteCity = async (city) => {
    const response = await MasterApi.deleteCity(city);
    console.log(response);
    getCitiesByCountry(selectedCountry);
  };

  return (
    <>
      <AdminMasterMenu />
      <div className=" m-4">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Mysql Countries</Accordion.Header>
            <Accordion.Body
              style={{
                overflowX: "scroll",
                maxHeight: "500px",

                overflowY: "scroll",
              }}
            >
              <h1 className="h4 mt-4">Mysql Country City Master</h1>
              {countries.length} Countries Found!
              <table className="table shadow table-hover table-striped table-sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Continent</th>
                    <th>Capital</th>
                    <th>Currency</th>
                  </tr>
                </thead>
                <tbody>
                  {countries.map((country: any) => (
                    <tr className="" key={country.id}>
                      <td> {country.countryName} </td>
                      <td>{country.countryCode}</td>
                      <td>{country.continentName}</td>
                      <td>{country.capital}</td>
                      <td>{country.currencyCode}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Mysql Cities</Accordion.Header>
            <Accordion.Body
              style={{
                overflowX: "scroll",
                maxHeight: "500px",

                overflowY: "scroll",
              }}
            >
              <Form.Select
                onChange={(e) => {
                  getCitiesByCountry(e.target.value);
                }}
              >
                <option>Open this select menu</option>
                {countries.map((country: any) => (
                  <option value={JSON.stringify(country)}>
                    {country.countryName}
                  </option>
                ))}
              </Form.Select>
              {cities.length} Cities Found!
              {newCity.country && (
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-info mx-auto"
                    onClick={() => setIsEditCityModalOpen(true)}
                  >
                    Create New City
                  </button>
                </div>
              )}
              <table className="table table-sm table-striped table-hover shadow my-4">
                <thead>
                  <tr>
                    <th>City</th>
                    <th>LAT</th>
                    <th>LON</th>
                    <th>Country</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cities.map((city: any) => (
                    <tr key={city.id}>
                      <td>{city.city}</td>
                      <td>{city.lat}</td>
                      <td>{city.lon}</td>
                      <td>{city.country}</td>
                      <td className="d-flex justify-content-around">
                        <button className="btn btn-sm btn-success ">
                          Edit
                        </button>
                        <button
                          className="btn btn-sm btn-danger "
                          onClick={() => deleteCity(city)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Mysql Cities Logs</Accordion.Header>
            <Accordion.Body>
              <MysqlCityLogs />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Modal show={isEditCityModalOpen} onHide={closeEditCityModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add/edit City</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="City"
                onChange={handleNewCityInputChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                type="number"
                name="lat"
                placeholder="Latitude"
                onChange={handleNewCityInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                type="number"
                name="lon"
                placeholder="Longitude"
                onChange={handleNewCityInputChange}
              />
            </Form.Group>
            <pre>{JSON.stringify(newCity, null, 2)}</pre>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEditCityModal}>
              Close
            </Button>
            <Button variant="primary" onClick={saveCity}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
