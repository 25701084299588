import React from 'react'
import Alert from 'commonUi/alert/index'
import {useSelector,useDispatch} from 'react-redux'
import {HIDE_ALERT_MESSAGE} from 'redux/actions/common.actions'

export default function AlertMessage() {
    const alert = useSelector(state=>state.common.alert)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(HIDE_ALERT_MESSAGE())
    }
    return (
        <Alert open={alert.showErrorMessage} message={alert.errorMessage} handleClose={handleClose} />
    )
}
