import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import React, { useEffect, useState } from "react";
import { generateRandomString } from "tools/commonFuncions";
import { HDFCDetails } from "./PGDetail/HDFCDetails";
import TextInp from "commonUi/input/text/index";
import Button from "commonUi/button/btnPrimary";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Form from "react-bootstrap/Form";

import {
  addICICIDetailsApiCall,
  addRazorpayDetailsApiCall,
  getActivePaymentGatewayApiCall,
  getICICIDetailsApiCall,
  getRazorpayDetailsApiCall,
} from "apiCalls/siteSettingsDb";
import { WEBHOOK_URL } from "tools/constants";
import PaymentGatewayTDR from "./PaymentGatewayTDR";
import { useSelector } from "react-redux";
import { CashfreeDetails } from "./PGDetail/CashfreeDetail";
import { toast } from "react-toastify";
import { Easebuzz } from "./PGDetail/Easebuzz";
import { PhonePeDetails } from "./PGDetail/PhonePeDetail";

// const useStyles = makeStyles((theme) => ({
//   gridAlign: {
//     marginTop: theme.spacing(3),
//     paddingLeft: `${theme.spacing(3)}px !important`,
//     paddingRight: `${theme.spacing(3)}px !important`,
//   },
//   textfield: {
//     margin: theme.spacing(1),
//   },
// }));

export const PaymentGatewayDetails = () => {
  const { siteData } = useSelector((state: any) => state.siteSettings);
  // const classes = useStyles();
  const [razorpayForm, setRazorpayForm] = useState({
    razorpay_webhook_secret: generateRandomString(12),
    razorpay_key_id: "",
    razorpay_key_secret: "",
    siteId: siteData._id,
  });
  const [iciciForm, setIciciForm] = useState({
    storeName: "",
    sharedSecret: "",
    siteId: siteData._id,
  });

  const [loadingState, setLoadingState] = useState(false);
  const [pgbtnDisabled, setPgbtnDisabled] = useState(true);
  const [defaultPaymentGateway, setDefaultPaymentGateway] = useState<
    "Razorpay" | "ICICI" | "HDFC_CCAVENUE" | "Cashfree" | "Easebuzz" | "Phonepe"
  >();

  useEffect(() => {
    getActivePaymentGateway();
    getRazorpayDetails();
    getIciciDetails();
  }, []);

  const printIn = (name, label, value, form, placeholder?) => (
    <TextInp
      name={name}
      label={label}
      type="text"
      value={value}
      onChange={
        form === "razorpay"
          ? handleRazorpayChange
          : form === "icici"
          ? handleIciciChange
          : undefined
      }
      className="string"
      placeholder={placeholder}
      required
    />
  );

  const handleRazorpayChange = (e) => {
    const { name, value } = e.target;
    setPgbtnDisabled(false);
    setRazorpayForm({
      ...razorpayForm,
      [name]: value,
    });
  };

  const handleIciciChange = (e) => {
    const { name, value } = e.target;
    setPgbtnDisabled(false);
    setIciciForm({
      ...iciciForm,
      [name]: value,
    });
  };

  const handleRazorpaySubmit = async (event) => {
    // event.preventDefault()
    // console.log(form);
    setPgbtnDisabled(true);
    const razorpayUpdate = await addRazorpayDetailsApiCall(razorpayForm);
    if (razorpayUpdate) {
      setPgbtnDisabled(false);
      toast.success("Razorpay Details successfully saved.");
    }
  };

  const handleICICISubmit = async (event) => {
    setPgbtnDisabled(true);
    const iciciUpdate = await addICICIDetailsApiCall(iciciForm);
    if (iciciUpdate) {
      setPgbtnDisabled(false);
      toast.success("ICICI Details successfully saved.");
    }
  };

  const getActivePaymentGateway = async () => {
    await getActivePaymentGatewayApiCall();

    // if (activeGatewayResponse.success && activeGatewayResponse.result?.pgName) {
    //   setDefaultPaymentGateway(activeGatewayResponse.result.pgName);
    // }
  };

  const getRazorpayDetails = async () => {
    const razorpayResponse = await getRazorpayDetailsApiCall();

    if (razorpayResponse.success && razorpayResponse.result) {
      setRazorpayForm({ ...razorpayResponse.result.pgDetail });
    }
  };
  const getIciciDetails = async () => {
    const iciciResponse = await getICICIDetailsApiCall();

    if (iciciResponse.success && iciciResponse.result) {
      setIciciForm({ ...iciciResponse.result.pgDetail });
    }
  };

  return (
    <>
      <FullScreenLoader show={loadingState} />
      <h5 className="heading text-start mb-3">Payment Gateway Details</h5>

      <FormControl component="fieldset" className="w-100">
        <Form.Label>Active Payment Gateway</Form.Label>
        <small className="text-danger">
          Only the selected payment gateway will be collecting payments on your
          site.
        </small>
        <RadioGroup
          name="paymentGateway"
          value={defaultPaymentGateway}
          onChange={(e) => {
            if (
              // e.target.value === "ICICI" ||
              // e.target.value === "HDFC_CCAVENUE" ||
              e.target.value === "Razorpay" ||
              e.target.value === "Cashfree" ||
              e.target.value === "Easebuzz" ||
              e.target.value === "Phonepe"
            ) {
              setDefaultPaymentGateway(e.target.value);
            }
          }}
        >
          <FormControlLabel
            value="Razorpay"
            control={<Radio />}
            label="Razorpay"
          />
          {/* <FormControlLabel value="ICICI" control={<Radio />} label="ICICI" />
          <FormControlLabel
            value="HDFC_CCAVENUE"
            control={<Radio />}
            label="HDFC (CCAvenue)"
          /> */}
          <FormControlLabel
            value="Cashfree"
            control={<Radio />}
            label="Cashfree"
          />
          <FormControlLabel
            value="Easebuzz"
            control={<Radio />}
            label="Easebuzz"
          />
          <FormControlLabel
            value="Phonepe"
            control={<Radio />}
            label="Phonepe"
          />
        </RadioGroup>
      </FormControl>

      {defaultPaymentGateway === "Razorpay" && (
        <>
          <div className="gateway-details-form">
            <p className="text-center mb-3">
              These are the Razorpay Credentials.
              <br /> By adding these credentials, payment gateway will become
              live on your site automatically.
              <br />
              For getting Razorpay account contact our team.
            </p>
            <div className="row">
              <div className="col-lg-6">
                {printIn(
                  "razorpay_key_id",
                  "Razorpay Key Id",
                  razorpayForm.razorpay_key_id,
                  "razorpay"
                )}
              </div>
              <div className="col-lg-6">
                {printIn(
                  "razorpay_key_secret",
                  "Razorpay Key Secret",
                  razorpayForm.razorpay_key_secret,
                  "razorpay"
                )}
              </div>
              <div className="col-lg-6">
                {printIn(
                  "razorpay_webhook_secret",
                  "Razorpay Webhook Secret",
                  razorpayForm.razorpay_webhook_secret,
                  "razorpay"
                )}
              </div>
            </div>

            <p className="text-start mb-3">
              While setting up webhook enter secret as above and url as
              "https://
              {siteData.domain + WEBHOOK_URL}" .
            </p>
            <div className="text-center">
              {!pgbtnDisabled && (
                <Button
                  label="Submit"
                  onClick={handleRazorpaySubmit}
                  // className={classes.textfield}
                />
              )}
            </div>
          </div>
        </>
      )}

      {defaultPaymentGateway === "ICICI" && (
        <>
          <div className="gateway-details-form">
            <p className="text-center mb-3">
              These are the ICICI Credentials.
              <br /> By adding these credentials, payment gateway will become
              live on your site automatically.
            </p>
            <div className="row">
              <div className="col-lg-6">
                {printIn(
                  "storeName",
                  "Store name",
                  iciciForm.storeName,
                  "icici"
                )}
              </div>
              <div className="col-lg-6">
                {printIn(
                  "sharedSecret",
                  "Shared Secret",
                  iciciForm.sharedSecret,
                  "icici"
                )}
              </div>
            </div>
            <div className="text-center">
              {!pgbtnDisabled && (
                <Button label="Submit" onClick={handleICICISubmit} />
              )}
            </div>
          </div>
        </>
      )}

      {defaultPaymentGateway === "HDFC_CCAVENUE" && (
        <>
          <div className="gateway-details-form">
            <HDFCDetails />
          </div>
        </>
      )}

      {defaultPaymentGateway === "Cashfree" && (
        <>
          <div className="gateway-details-form">
            <CashfreeDetails />
          </div>
        </>
      )}

      {defaultPaymentGateway === "Easebuzz" && (
        <>
          <div className="gateway-details-form">
            <Easebuzz />
          </div>
        </>
      )}

      {defaultPaymentGateway === "Phonepe" && (
        <>
          <div className="gateway-details-form">
            <PhonePeDetails />
          </div>
        </>
      )}

      {defaultPaymentGateway && (
        <PaymentGatewayTDR
          paymentGateway={defaultPaymentGateway}
          setLoading={(loading) => setLoadingState(loading)}
        />
      )}
    </>
  );
};
