import { capitalCase } from "change-case";
import { useEffect, useState } from "react";
import { postRequest } from "tools/ajax";
import { EditApiIntegrationPopup } from "./EditApiIntegrationPopup";
import { IAllWlIntegration } from "commonFnBcode/Interfaces/userInterfaces";

export const EditWLApiIntegrations = () => {
  const [allWhiteLabelApiIntegrations, setAllWhiteLabelApiIntegrations] =
    useState<IAllWlIntegration[]>([]);
  const [apiSchema, setApiSchema] = useState<string[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [currentApiIntegrationValues, setCurrentApiIntegrationValues] =
    useState<IAllWlIntegration | undefined>();

  const queryParameters = new URLSearchParams(window.location.search);
  const siteId = queryParameters.get("id");

  useEffect(() => {
    getAllWhiteLabelApiIntegrations(siteId);
  }, []);

  const getAllWhiteLabelApiIntegrations = async (siteId: string | null) => {
    const response = await postRequest(
      "admin/nfs/getWhitelabelApiIntegration",
      { siteId }
    );
    if (response?.success) {
      setAllWhiteLabelApiIntegrations(response?.result);
    }
  };
  const getCredentials = (credintials) => {
    return Object.keys(credintials).map((cred: string) => (
      <div className="py-2 " key={cred}>
        <b>{cred}:</b> {credintials[cred]}
      </div>
    ));
  };
  const getApiSchema = async (id: number) => {
    const apiSchemaData = await postRequest("admin/nfs/getApiSchema", {
      apiIntegrationId: id,
    });
    if (apiSchemaData) setApiSchema(apiSchemaData.result);
  };

  return (
    <div className="container ">
      <EditApiIntegrationPopup
        reload={getAllWhiteLabelApiIntegrations}
        show={modalShow}
        onHide={() => setModalShow(false)}
        apischema={apiSchema}
        data={currentApiIntegrationValues}
      />
      <h3 className="my-4">Edit Api Integrations:</h3>
      <div className=" w-100">
        <table className="table table-responsive">
          <thead className="thead-light">
            <tr className="bg-dark">
              <th className="bg-light text-end" scope="col">
                Action
              </th>
              <th className="bg-light" scope="col">
                #
              </th>
              <th className="bg-light" scope="col">
                API
              </th>
              <th className="bg-light" scope="col">
                isTesting
              </th>
              <th className="bg-light" scope="col">
                isActive
              </th>
              <th className="bg-light" scope="col">
                Credentials
              </th>
              <th className="bg-light" scope="col">
                wl-Flight Api Credential Name
              </th>
            </tr>
          </thead>
          <tbody>
            {allWhiteLabelApiIntegrations?.length ? (
              allWhiteLabelApiIntegrations?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td className="text-end">
                      <button
                        onClick={() => {
                          setCurrentApiIntegrationValues(data);
                          getApiSchema(data.flightApiIntegration.id);
                          setModalShow(true);
                        }}
                        className="btn btn-sm btn-primary text-white w-100 "
                        // onClick={() => setModalShow(true)}
                      >
                        Edit
                        {/* <i className="fas fa-edit"></i> */}
                      </button>
                    </td>
                    <th scope="row">{index + 1}</th>
                    <td>{capitalCase(data?.flightApiIntegration.apiName)}</td>
                    <td
                      className={`text-${
                        data?.isTesting ? "success" : "danger"
                      } bold`}
                    >
                      {data?.isTesting.toString().toLocaleUpperCase()}
                    </td>
                    <td
                      className={`text-${
                        data?.isActive ? "success" : "danger"
                      } bold`}
                    >
                      {data?.isActive.toString().toLocaleUpperCase()}
                    </td>
                    <td>
                      <div>
                        {Object.keys(data.apiCredentials).map(
                          (cred: string) => (
                            <div className="py-2 " key={cred}>
                              <b>{cred}:</b>
                              <p
                                style={{
                                  wordBreak: "break-all",
                                }}
                              >
                                {data.apiCredentials[cred]}
                              </p>
                            </div>
                          )
                        )}{" "}
                      </div>
                    </td>
                    <td>{data?.wlFlightApiCredentialName}</td>
                  </tr>
                );
              })
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
        {!allWhiteLabelApiIntegrations?.length && (
          <div className="w-100 text-center text-danger"> Not Found</div>
        )}
      </div>
    </div>
  );
};
