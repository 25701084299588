import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";

const index: React.FC<{
  label: string;
  name: string;
  error?: any;
  onChange: (props: any) => void;
  value: string;
}> = (props) => {
  return (
    <div>
      <FormControl component="fieldset" error={props.error}>
        <FormLabel component="legend">{props.label} </FormLabel>
        <RadioGroup aria-label={props.name} {...props}>
          {props.children}
        </RadioGroup>
        <FormHelperText>
          {props.error && "Please select an option"}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default index;
