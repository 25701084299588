import {
  addTboCredentialsApiCall,
  getTboCredentialsApiCall,
} from "apiCalls/siteSettingsDb";
import { Button } from "@material-ui/core";
import TextInp from "commonUi/input/text/TextInp";
import { useEffect } from "react";
import { useForm, Resolver, Controller } from "react-hook-form";
import { toast } from "react-toastify";

interface IFormValues {
  TokenId: string;
  ClientId: string;
  UserName: string;
  Password: string;
  MemberId: string;
  AgencyId: string;
}

const resolver: Resolver<IFormValues> = async (values) => {
  return {
    values: values.TokenId ? values : {},
    errors: {},
  };
};

export const TboCredentials = () => {
  const { handleSubmit, control, getValues, setValue } = useForm<IFormValues>({
    resolver,
  });

  useEffect(() => {
    gettboApiCredentialsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gettboApiCredentialsDetails = async () => {
    const tboApiCredentialsRes = await getTboCredentialsApiCall();
    const tboApiCredentials = tboApiCredentialsRes.result;

    if (tboApiCredentials?.TokenId) {
      setValue("TokenId", tboApiCredentials["TokenId"]);
    }

    if (tboApiCredentials?.AgencyId) {
      setValue("AgencyId", tboApiCredentials["AgencyId"]);
    }

    if (tboApiCredentials?.ClientId) {
      setValue("ClientId", tboApiCredentials["ClientId"]);
    }

    // Object.keys(hdfcDetails).forEach((hdfcKey) => {
    //   switch (hdfcKey) {
    //     case "accessCode":
    //       setValue("accessCode", hdfcDetails[hdfcKey]);
    //       break;

    //     case "workingKey":
    //       setValue("workingKey", hdfcDetails[hdfcKey]);
    //       break;

    //     case "merchantId":
    //       setValue("merchantId", hdfcDetails[hdfcKey]);
    //       break;
    //     default:
    //       break;
    //   }
    // });
  };

  const onSubmit = handleSubmit(async (data) => {
    // toast.error(data);
    const credentialsForm = getValues();
    const credentialsUpdate = await addTboCredentialsApiCall(credentialsForm);
    if (credentialsUpdate) {
      // setPgbtnDisabled(false);
      toast.error("Tbo Credentials successfully saved.");
    }
  });

  return (
    <>
      <p>These are the TBO Credentials</p>
      <form onSubmit={onSubmit}>
        <div className="form-group mb-3">
          <Controller
            name="accessCode"
            control={control}
            required
            defaultValue=""
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"
                label="Access Code"
              />
            )}
          />
        </div>

        <div className="form-group mb-3">
          <Controller
            name="workingKey"
            control={control}
            defaultValue=""
            required
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"

                label="Working Key"
              />
            )}
          />
        </div>

        <div className="form-group mb-3">
          <Controller
            name="merchantId"
            required
            control={control}
            defaultValue=""
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"

                label="Merchant Id"
              />
            )}
          />
        </div>
        <Button color="primary" variant="contained" type="submit">
          Submit
        </Button>
        {/* <input type="submit" /> */}
      </form>
    </>
  );
};
