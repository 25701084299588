import {
  Isupplier,
  IpackageSupplierCategory,
} from "components/admin/supplierManagement/supplierManagement.types";
import { getRequest, postRequest } from "tools/ajax";

export class SupplierManagementApiCalls {
  static savePackageSupplierCategory = async (
    packageSupplierCategory: IpackageSupplierCategory
  ) => {
    const saveResponse = await postRequest(
      "admin/supplierManagement/packageSupplierCategory",
      packageSupplierCategory
    );
    return saveResponse;
  };

  static getPackageSupplierCategories = async () => {
    return await getRequest(
      "admin/supplierManagement/packageSupplierCategory",
      {}
    );
  };

  static saveSupplier = async (Supplier: Isupplier) => {
    return await postRequest("admin/supplierManagement/suppliers", Supplier);
  };

  static getSuppliers = async () => {
    return await getRequest("admin/supplierManagement/suppliers", {});
  };
}
