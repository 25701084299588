import { productTypeEnum } from "commonFnBcode/types/whitelabel.types";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { postRequest } from "tools/ajax";
import { currencyType, serviceEnum } from "types/wallet.types";

export const TransactionUsagePopup = (props) => {
  const defaultFilterState = {
    fromDate: moment().startOf("month").toDate(),
    toDate: moment().endOf("day").toDate(),
  };
  const [dateFilter, setDateFilter] = useState<{
    fromDate: Date;
    toDate: Date;
  }>(defaultFilterState);
  const [customerUsage, setCustomerUsage] = useState<
    {
      serviceEnum: productTypeEnum | serviceEnum;
      totalAmountDeducted: number;
      serviceName: string;
      currency: currencyType;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const getSippiCustomerUsage = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const apiRes = await postRequest(
        "wallet/getSippiCustomerUsage",
        dateFilter
      );
      if (apiRes?.success) setCustomerUsage(apiRes.result);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.show) getSippiCustomerUsage();
  }, [props.show, dateFilter]);
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Usage From -{" "}
          <span className="small sm">
            {moment(dateFilter.fromDate).format("LL")} to{" "}
            {moment(dateFilter.toDate).format("LL")}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row align-items-center">
          <div className="col-3">
            <label className="small">From : </label>
            <ReactDatePicker
              dateFormat={"dd/MM/yyyy"}
              className=" date-filter form-control"
              maxDate={dateFilter.toDate}
              onChange={(date) => {
                setDateFilter({
                  ...dateFilter,
                  fromDate: moment(date).toDate(),
                });
              }}
              selected={dateFilter.fromDate}
            />
          </div>
          <div className="col-3">
            <label className="small">To : </label>
            <ReactDatePicker
              dateFormat={"dd/MM/yyyy"}
              className=" date-filter form-control"
              maxDate={new Date()}
              onChange={(date) => {
                setDateFilter({
                  ...dateFilter,
                  toDate: moment(date).toDate(),
                });
              }}
              selected={dateFilter.toDate}
            />
          </div>
        </div>
        <div className="row">
          {customerUsage &&
            customerUsage.map((item) => {
              return (
                <div className="col-4 p-2  my-3" key={item.serviceEnum}>
                  <div className="d-flex flex-column justify-content-between rounded border py-2 px-3 shadow-sm h-100   ">
                    <span className="mb-2">
                      <p className="small mb-0">Total Amount Deducted For</p>
                      <b>{item.serviceName}</b>
                    </span>
                    <span className="my-2">
                      {loading ? (
                        <p className="placeholder-glow">
                          <span className="placeholder col-12"></span>
                        </p>
                      ) : (
                        <b className="text-danger">
                          {item.currency === currencyType.INR ? "₹" : "$"}
                          {item.totalAmountDeducted.toFixed(2).toString()}
                        </b>
                      )}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
