import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function index(props) {
    return (
       <>
            <Typography {...props} />
       </>
    )
}
