import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List, ListItem, Switch, makeStyles, Button, Typography, CircularProgress } from '@material-ui/core'
import { SET_VISACATEGORIES, SET_NATIONALITY, SET_SELECTED_VISACATEGORY, SET_SELECTED_VISA_CHECKLIST } from 'redux/actions/visaActions'
import { getSelectedVisa, isVisibleChangeHandler } from 'apiCalls/visaDb'
import { SET_VISACHECKLISTS } from '../../../redux/actions/visaActions'
import { getExistingVisaChecklist } from '../../../apiCalls/visaDb'

const useStyles = makeStyles((theme) => ({
    style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '2px auto',
    },

}))

export default function VisaCategoryList() {
    const [loading,setLoading] = useState(false)
    const countryIdRedux = useSelector((state) => state.visaChecklist.selectedCountry._id)
    const selectedVisaCategoriesRedux = useSelector((state) => state.visaChecklist.visaCategories)
    //const selectedVisaChecklistRedux = useSelector((state) => state.visaChecklist.visaChecklists)
    const dispatch = useDispatch()
    const classes = useStyles()

    useEffect (() => {
        selectedVisaCategories(countryIdRedux)
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[countryIdRedux])

    

    const selectedVisaCategories = async () => {
            setLoading(true)
            const r = await getSelectedVisa(countryIdRedux)
            dispatch(SET_VISACATEGORIES(r))
            setLoading(false)
    }

    const isVisibleHandleChange = async (visa,i) => {
        setLoading(true)
        await isVisibleChangeHandler( visa._id,!visa.isVisible)
        selectedVisaCategories()
        setLoading(false)
    }

    const getSelectedVisaChecklist = async (visa,i) => {
        setLoading(true)
        let r = await getExistingVisaChecklist(visa._id)
         dispatch(SET_VISACHECKLISTS(r))
         dispatch(SET_NATIONALITY([]))
         setLoading(false)
    }

    const onClickChangeHandler = (visa,i) => {
        getSelectedVisaChecklist(visa,i);
        dispatch(SET_SELECTED_VISACATEGORY(visa,i));
        dispatch(SET_SELECTED_VISA_CHECKLIST([]));
    }

    return (
        <>
           { countryIdRedux ? 
                <List>
                    <ListItem divider={true}>
                        <b>{ selectedVisaCategoriesRedux.length ? 
                        "VISA CATEGORIES" : "No Visa Category Added yet"}</b>
                    </ListItem>
                    {loading === true ? <CircularProgress/> : ''} 
                     { selectedVisaCategoriesRedux?.map((visa,i)=>( 
                        <React.Fragment key={i}> 
                        <ListItem key={i} divider={true} className={classes.style}>
                            <Typography>
                                {visa.visaCategory}
                            </Typography>
                            <div>
                            <Switch 
                                checked={visa.isVisible}
                                onChange={() => isVisibleHandleChange(visa,i)}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox'}}
                            />
                            <Button variant='contained'
                                color='primary' 
                                onClick={() => onClickChangeHandler(visa,i)}
                            >
                                Select
                            </Button>
                            </div>
                        </ListItem>
                        </React.Fragment>     
                    ))}
                </List>
             : ''} 
        </>
    )
}