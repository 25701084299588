export const userAdvanceRConstants = {
  getWlMetaData: {
    prefix: "userAdvance", // here prefix is the part of url which denotes the route file
    endPoint: "/getWlMetaData", // this is the final end point which will lead to the controller
  },
  saveWlMetaData: {
    prefix: "userAdvance",
    endPoint: "/saveWlMetaData",
  },
};
