import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { postRequest } from "tools/ajax";

interface IFormValues {
  easebuzzKey: string;
  easebuzzSalt: string;
}

export const Easebuzz = () => {
  const [pgDetails, setPgDetails] = useState<IFormValues>({
    easebuzzKey: "",
    easebuzzSalt: "",
  });

  useEffect(() => {
    getEasebuzzDetails();
  }, []);

  const getEasebuzzDetails = async () => {
    const response = await postRequest(
      "siteSettings/getEasebuzzDetailsToStaff",
      {}
    );
    console.log({ easeRes: response });
    if (!response.success) {
      return toast.error(
        response?.errorMessage ||
          "Could not fetch easebuzz details/credentials from the database!"
      );
    }
    const pg = response?.result?.pgDetail;
    setPgDetails((oldState) => {
      const old = { ...oldState };
      if (pg?.easebuzzKey) {
        old.easebuzzKey = pg.easebuzzKey;
      }
      if (pg?.easebuzzSalt) {
        old.easebuzzSalt = pg.easebuzzSalt;
      }
      return old;
    });
  };

  const savePgDetails = async () => {
    if (!pgDetails.easebuzzKey || !pgDetails.easebuzzSalt) {
      return toast.error("Please enter easebuzz credentials!");
    }
    const saveResponse = await postRequest(
      "siteSettings/addEasebuzzDetails",
      pgDetails
    );
    if (saveResponse) {
      // setPgbtnDisabled(false);
      toast.success("Easebuzz Details successfully saved.");
    }
    console.log({ saveResponse });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setPgDetails((old) => ({ ...old, [name]: value }));
  };

  return (
    <div>
      <h1 className="h4">Easebuzz Credentials</h1>
      {/* <pre>{JSON.stringify(pgDetails, null, 2)}</pre> */}
      <div className="row">
        <div className="col-sm-6 py-2">
          <Form.Group>
            <label>Easebuzz Key</label>
            <Form.Control
              name="easebuzzKey"
              onChange={handleChange}
              placeholder="Easebuzz Key"
              value={pgDetails.easebuzzKey}
            />
          </Form.Group>
        </div>
        <div className="col-sm-6 py-2">
          <Form.Group className="">
            <label>Easebuzz Salt</label>
            <Form.Control
              name="easebuzzSalt"
              onChange={handleChange}
              placeholder="Easebuzz Salt"
              value={pgDetails.easebuzzSalt}
            />
          </Form.Group>
        </div>
      </div>
      <div className="text-center">
        <button className="btn btn-primary" onClick={savePgDetails}>
          Save Easebuzz Details
        </button>
      </div>
    </div>
  );
};
