import React, { useState } from "react";
import Autocomplete from "commonUi/autoComplete/index";
import { searchWhitelabelByDomain } from "apiCalls/whitelabelDb";
import { toast } from "react-toastify";

export const SearchCustomer: React.FC<{
  setWhitelabel: Function;
}> = (props) => {
  const [whitelabels, setWhitelabels] = useState([]);

  const handleChange = (e, val) => {
    props.setWhitelabel(val);
  };

  const handleInputChange = async (e, val) => {
    const searchRes = await searchWhitelabelByDomain({ domain: val });
    if (searchRes.err) return toast.error(searchRes.err);
    if (!searchRes.whitelabels) return toast.error("no wl found");
    setWhitelabels(searchRes.whitelabels);
    console.log({ searchRes });
  };

  return (
    <div className="d-flex justify-content-center">
      <Autocomplete
        options={whitelabels}
        getOptionLabel={(option) => option.domain.toString()}
        onInputChange={handleInputChange}
        onChange={handleChange}
        label={"Search Domain old"}
      />
    </div>
  );
};
