import React, { useEffect, useState } from "react";
import { getCountries } from "apiCalls/masterDb";
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  makeStyles,
  Button,
  CardContent,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SET_NATIONALITY, SET_VISACHECKLISTS } from "redux/actions/visaActions";
import {
  submitNewChecklist,
  getExistingVisaChecklist,
  updateChecklistLog,
} from "../../../apiCalls/visaDb";
import moment from "moment";
import CKEditor from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FullPageDialog from "tools/fullpageloader";
//import propTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-center",
    width: "100%",
    padding: "10px",
  },

  autocompleteStyle: {
    width: "50%",
    alignItems: "center",
    padding: "10px",
  },

  buttonStyle: {
    marginLeft: "10px auto",
    marginTop: "20px",
  },
}));

export function HtmlNationality() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [nationalities, setNationalities] = useState([]);
  const [dropdownSearchValue, setDropdownSearchValue] = useState("");
  const [ckeditorText, setCkeditorText] = useState("");
  const dispatch = useDispatch();
  const selectedNationalityRedux = useSelector(
    (state) => state.visaChecklist.selectedNationality._id
  );
  const selectedIndexFromVisaCategoryRedux = useSelector(
    (state) => state.visaChecklist.selectedVisaCategory
  );
  const selectedVisaChecklistForEditRedux = useSelector(
    (state) => state.visaChecklist.selectedVisaChecklist
  );
  const selectedVisaChecklistsRedux = useSelector(
    (state) => state.visaChecklist.visaChecklists
  );

  useEffect(() => {
    getNationality();
  }, []);

  const dropdownSearch = ({ target }) => {
    setDropdownSearchValue(target.value);
  };

  const getNationality = async () => {
    const nationalityArr = await getCountries();
    return setNationalities(nationalityArr);
  };

  const setNationalityChangeHandler = (e, v) => {
    if (v === null) return dispatch(SET_NATIONALITY([]));
    else return dispatch(SET_NATIONALITY(v));
  };

  const submit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const date = moment().format();
    if (!selectedNationalityRedux)
      return toast.error("Nationality Not Selected");
    setLoading(false);
    if (!ckeditorText)
      return (
        toast.error("Text Editor cannot be empty or old one"), setLoading(false)
      );

    if (!selectedVisaChecklistForEditRedux._id) {
      const newArr = selectedVisaChecklistsRedux.map(
        (nation) => nation.nationality_countryId._id
      );
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i] === selectedNationalityRedux) {
          return (
            toast.error("This country already added in List", newArr[i]),
            setLoading(false)
          );
        }
      }
      await submitNewChecklist(
        selectedIndexFromVisaCategoryRedux._id,
        selectedIndexFromVisaCategoryRedux.countryId,
        selectedNationalityRedux,
        date,
        ckeditorText
      );
    } else if (selectedVisaChecklistForEditRedux) {
      const res = await updateChecklistLog(
        selectedVisaChecklistForEditRedux._id,
        date,
        selectedVisaChecklistForEditRedux.ckeditor,
        ckeditorText
      );
      if (res?.success) {
        toast.success("checklist updated");
      }
    }

    let updateRedux = await getExistingVisaChecklist(
      selectedIndexFromVisaCategoryRedux._id
    );
    dispatch(SET_VISACHECKLISTS(updateRedux));
    dispatch(SET_NATIONALITY([]));
    setLoading(false);
  };

  return (
    <>
      <div className={classes.form}>
        <form onSubmit={(e) => submit(e)}>
          <Autocomplete
            required={true}
            noOptionsText="No Nationality Found"
            options={nationalities}
            defaultValue={
              selectedVisaChecklistForEditRedux.nationality_countryId
            }
            onChange={setNationalityChangeHandler}
            getOptionLabel={(option) => option.countryName}
            className={classes.autocompleteStyle}
            renderInput={(params) => (
              <TextField
                {...params}
                value={dropdownSearchValue}
                onChange={dropdownSearch}
                label="Select Nationality"
                variant="outlined"
              />
            )}
          />
          <br />
          <Card elevation={2}>
            <CardContent className={classes.cardContent}>
              <CKEditor
                editor={ClassicEditor}
                data={selectedVisaChecklistForEditRedux.ckeditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setCkeditorText(data);
                }}
              />
            </CardContent>
          </Card>
          {loading === true ? (
            <CircularProgress />
          ) : (
            <Button
              className={classes.buttonStyle}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          )}
        </form>
      </div>
    </>
  );
}

// HtmlNationalityDialog.propTypes = {
//     name : propTypes.string.isRequired,
//     dialogContent : propTypes.element.isRequired
// }

export function HtmlNationalityDialog(props) {
  return (
    <>
      <FullPageDialog
        {...props}
        name="Nationality and CK editor"
        dialogContent={HtmlNationality}
      />
    </>
  );
}
