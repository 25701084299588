import React, { MouseEventHandler, useState } from "react";
import Dialog from "commonUi/dialog/Index";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { applySsl } from "apiCalls/serverShellScripts";

interface IProps {
  isOpen: boolean;
  handleClose: MouseEventHandler<HTMLAnchorElement>;
  whitelabel: any;
}
export const AddSSL = (props: IProps) => {
  const wl = props.whitelabel;
  const [isChecked, setIsChecked] = useState(false);
  const [sslOutput, setSslOutput] = useState<any>({});

  const handleApplySsl = async () => {
    const response = await applySsl(wl.domain);
    console.log("apply ssl response", response);
    setSslOutput(response);
  };

  const convertOutput = (strToDecode) => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(
      `<!doctype html><body>${strToDecode}`,
      "text/html"
    ).body.textContent;
    return decodedString;
  };

  return (
    <Dialog
      title={`Add SSl for ${wl.domain}`}
      isOpen={props.isOpen}
      handleClose={props.handleClose}
    >
      <div className="alert alert-warning">
        Please check A Name and C Name records before applying SSL.
      </div>

      <p className="fw-bold fs-5">
        I have checked both{" "}
        <a
          href={`http://${wl.domain}`}
          rel="noreferrer"
          target="_blank"
          className="text-danger"
        >
          {wl.domain}
        </a>
        &nbsp;and
        <a
          className="text-danger"
          href={`http://www.${wl.domain}`}
          rel="noreferrer"
          target="_blank"
        >
          www.{wl.domain}
        </a>{" "}
        are working and their dns are updated at dnschecker.org
      </p>

      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            name="checkedB"
            color="primary"
          />
        }
        label="I have checked everything carefully"
      />

      <Button
        color="primary"
        variant="contained"
        disabled={!isChecked}
        onClick={handleApplySsl}
      >
        Apply SSL
      </Button>

      {Object.keys(sslOutput).length && sslOutput.stderr === "" ? (
        <div className="alert alert-success">
          STD OUTPUT: <br />
          <pre>{convertOutput(sslOutput?.output || " ")}</pre>
        </div>
      ) : (
        <div className="alert alert-danger">
          STD ERROR (stderr): <br />
          <pre>{convertOutput(sslOutput?.stderr || " ")}</pre>
        </div>
      )}
    </Dialog>
  );
};
