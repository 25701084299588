import {
  addPhonepeDetailsApiCall,
  getPhonepeDetailsApiCall,
} from "apiCalls/siteSettingsDb";
import { Button } from "@material-ui/core";
import TextInp from "commonUi/input/text/TextInp";
import { useEffect } from "react";
import { useForm, Resolver, Controller } from "react-hook-form";
import { toast } from "react-toastify";

interface IFormValues {
  merchantId: string;
  key: string;
  keyIndex: string;
}

const resolver: Resolver<IFormValues> = async (values) => {
  return {
    values: values.key ? values : {},
    errors: {},
  };
};

export const PhonePeDetails = () => {
  const { handleSubmit, control, getValues, setValue } = useForm<IFormValues>({
    resolver,
  });

  useEffect(() => {
    getCashfreeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCashfreeDetails = async () => {
    const phonepeDetailsRes = await getPhonepeDetailsApiCall();
    const phonepeDetails = phonepeDetailsRes.result?.pgDetail;

    if (phonepeDetails?.merchantId) {
      setValue("merchantId", phonepeDetails["merchantId"]);
    }
    if (phonepeDetails?.key) {
      setValue("key", phonepeDetails["key"]);
    }
    if (phonepeDetails?.keyIndex) {
      setValue("keyIndex", phonepeDetails["keyIndex"]);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    // toast.error(data);
    const hdfcForm = getValues();
    const phonepeUpdate = await addPhonepeDetailsApiCall(hdfcForm);
    if (phonepeUpdate) {
      // setPgbtnDisabled(false);
      toast.success("Phonepe Details successfully saved.");
    }
  });

  return (
    <>
      <p>These are the Phonepe Details</p>
      <form onSubmit={onSubmit}>
        <div className="form-group mb-3">
          <Controller
            name="merchantId"
            control={control}
            required
            defaultValue=""
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"
                label="Merchant ID"
              />
            )}
          />
        </div>

        <div className="form-group mb-3">
          <Controller
            name="key"
            control={control}
            defaultValue=""
            required
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"

                label="Secret Key"
              />
            )}
          />
        </div>
        <div className="form-group mb-3">
          <Controller
            name="keyIndex"
            control={control}
            defaultValue=""
            required
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"

                label="Key Index"
              />
            )}
          />
        </div>
        <Button color="primary" variant="contained" type="submit">
          Submit
        </Button>
        {/* <input type="submit" /> */}
      </form>
    </>
  );
};
