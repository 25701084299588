import { getRequest, postRequest, deleteRequest } from "tools/ajax";

export const getWhitelabel = async () => {
  const whitelabel = await getRequest("user/checkWhitelabelForSippiCust");
  return whitelabel;
};

export const getWhitelabelByDomain = async (domain) => {
  return await postRequest("admin/whitelabel/getWhitelabelByDomain", domain);
};

export const searchWhitelabelByDomain = async ({ domain }) => {
  return await postRequest("admin/whitelabel/searchWhitelabelByDomain", {
    domain,
  });
};

export const mapWhiteLabelToGroup = async ({ groupId, siteId }) => {
  return await postRequest("admin/whitelabel/ty_wl_group_map", {
    groupId,
    siteId,
  });
};

export const listMappedSitesOnGroup = async ({ groupId }) => {
  return await getRequest("admin/whitelabel/ty_wl_group_map", { groupId });
};

export const deleteMappedSiteOnGroup = async ({ mapId }) => {
  return await deleteRequest("admin/whitelabel/ty_wl_group_map", { mapId });
};

export const getWhitelabelToTboEnv = async (data) => {
  return await postRequest(
    "admin/whitelabel/getWhitelabelFlightApiConfig",
    data
  );
};

export const getAllLiveTboWhitelabels = async () => {
  return await postRequest("admin/whitelabel/getAllLiveTboWhitelabels", {});
};

export const postWhitelabelToTboEnv = async (data) => {
  return await postRequest(
    "admin/whitelabel/postWhitelabelFlightApiConfig",
    data
  );
};

export const postWhitelabelWallet = async (data) => {
  return await postRequest("admin/whitelabel/postWhitelabelWallet", data);
};

/**
 * @usedAt partnerSippi/sippi-api/srcTs/Routes/admin/Whitelabel.js
 */
export const getAllWalletEntries = async (data) => {
  return await postRequest("admin/whitelabel/getAllWalletEntries", data);
};

export const getWhitelabelWallet = async (data) => {
  return await postRequest("admin/whitelabel/getWhitelabelWallet", data);
};

export const getAllPaymentEntries = async (data) => {
  return await postRequest("admin/whitelabel/getAllPGEntries", data);
};

// export const getUniqueSuppliers = async () => {
//   return await getRequest("admin/whitelabel/getUniqueSuppliers");
// };

export const postDefaultSupplier = async (data) => {
  return await postRequest("admin/whitelabel/makeDefaultSupplier", data);
};

export const getSuppliersTobeApproved = async () => {
  return await getRequest(
    "admin/whitelabel/supplierApproval_getAllApplications"
  );
};

export const postApproveSupplier = async (data) => {
  return await postRequest(
    "admin/whitelabel/supplierApproval_approveSupplier",
    data
  );
};

export const postSupplierApprovalRemark = async (data) => {
  return await postRequest("admin/whitelabel/supplierApproval_addRemark", data);
};

export const postKycLockStatusChange = async (data) => {
  return await postRequest(
    "admin/whitelabel/supplierApproval_lockStatus",
    data
  );
};

export const getWlUserWithWhitelabelDetails = async ({ userId }) => {
  return await postRequest("admin/whitelabel/getWlUserWithWhitelabelDetails", {
    userId,
  });
};

export const getWhitelabelGroups = async () => {
  return await getRequest("admin/whitelabel/ty_whitelabelGroup", {});
};

export const saveWhitelabelGroup = async (group) => {
  return await postRequest("admin/whitelabel/ty_whitelabelGroup", group);
};

export const setDefaultAgentGroup = async (groupId) => {
  return await postRequest("admin/whitelabel/ty_whitelabelGroup", groupId);
};
