import React, { useState } from "react";
import { Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { paths } from "../PathConstants";

const useStyles = makeStyles({
  main: {
    margin: "0 auto",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
  },
});

export const ApiHeader = () => {
  const classes = useStyles();
  const [tboOpen, setTboOpen] = useState(false);

  const handleTboOpen = (e) => setTboOpen(e.currentTarget);

  const handleTboClose = () => setTboOpen(false);

  return (
    <div className={classes.main}>
      <div className="border">
        <Link to={paths.apiDashboard}>
          <Button>Dashboard</Button>
        </Link>
        <Link to={paths.s3}>
          <Button>S3</Button>
        </Link>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleTboOpen}
        >
          TBO
        </Button>
        <Link to={paths.apiIntegrations}>
          <Button>API INTEGRATIONS</Button>
        </Link>
      </div>

      <Menu
        id="simple-menu"
        anchorEl={tboOpen}
        keepMounted
        open={Boolean(tboOpen)}
        onClose={handleTboClose}
      >
        <Link to={paths.tboLiveSites}>
          <MenuItem onClick={handleTboClose}>Live Sites</MenuItem>
        </Link>
        <Link to={paths.tboTotalSale}>
          <MenuItem onClick={handleTboClose}>Total Flight Sale Report</MenuItem>
        </Link>
      </Menu>
    </div>
  );
};
