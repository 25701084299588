import React from 'react'

export default function Error404(){
    return(
        <>
        <h1 style={{textAlign: 'center'}}>
            Opps... Page not found!
        </h1>
        </>
    )
}