import {accountsTypes} from '../actionTypes/accounts.types'

export const getWhitelabelsWithSippiCustomerData = () => {
    return {
        type: accountsTypes.GET_WHITELABELS_WITH_SIPPI_CUSTOMER_DATA
    }
}

export const setWhitelabelsWithSippiCustomerData = (payload) => {
    return {
        type: accountsTypes.SET_WHITELABELS_WITH_SIPPI_CUSTOMER_DATA,
        payload
    }
}

export const updateActualPaymentStatusForWhitelabel = (payload) => ({
    type: accountsTypes.UPDATE_ACTUAL_PAYMENT_STATUS,
    payload
})