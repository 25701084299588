import React, { useState, useEffect } from "react";
import Dialog from "commonUi/dialog/Index";
import TestMailForm from "./TestMailForm";
import TestMailResponse from "./TestMailResponse";
import { useSelector, useDispatch } from "react-redux";
import { SEND_TEST_EMAIL_RESPONSE } from "redux/actions/siteSettings.actions";
export default function TestMailDialog({ isOpen, handleClose, smtp }) {
  const [loading, setLoading] = useState(false);
  const testEmailResponse = useSelector(
    (state: any) => state.siteSettings.testEmailResponse
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) dispatch(SEND_TEST_EMAIL_RESPONSE(false));
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (testEmailResponse) setLoading(false);
  }, [testEmailResponse]);

  return (
    <Dialog
      title="Send Test Mail"
      isOpen={isOpen}
      handleClose={handleClose}
      loading={Boolean(loading)}
    >
      {testEmailResponse && (
        <TestMailResponse testEmailResponse={testEmailResponse} />
      )}
      <TestMailForm smtp={smtp} setLoading={setLoading} />
    </Dialog>
  );
}
