import React from "react";
import Container from "commonUi/Container";
import Typography from "commonUi/Typography";
import GridContainer from "commonUi/grid/container";
import GridItem from "commonUi/grid/item";
import { Link } from "react-router-dom";

export default function Index() {
  const printApi = (name, path) => {
    return (
      <GridItem>
        <Link to={path}>
          <Typography variant="h4">{name}</Typography>
        </Link>
      </GridItem>
    );
  };

  return (
    <div>
      <Container>
        <Typography variant="h5">API Dashboard</Typography>
        <GridContainer spacing={2}>
          {printApi("S3", "/admin/api/s3")}
          {printApi("TBO", "/admin/api/TBO")}
        </GridContainer>
      </Container>
    </div>
  );
}
