import React, { ChangeEventHandler, useEffect, useState } from "react";
import Dialog from "commonUi/dialog/Index";
import { toast } from "react-toastify";
import { isset } from "tools/commonFuncions";
import { SupplierManagementApiCalls } from "apiCalls/supplierManagementDb";
import { IpackageSupplierCategory } from "./supplierManagement.types";
import { FormControlLabel, Switch } from "@material-ui/core";
interface InpProps {
  name: string;
  value: any;
  placeHolder: string;
  handleChange: ChangeEventHandler;
}
const Inp = (props: InpProps) => {
  return (
    <>
      <div className="mb-3">
        <label htmlFor={props.name} className="form-label">
          {props.placeHolder}
        </label>
        <input
          type="text"
          name={props.name}
          className="form-control"
          id={props.name}
          value={props.value}
          placeholder={props.placeHolder}
          onChange={props.handleChange}
        />
      </div>
    </>
  );
};
export const PackageSupplierCategories = () => {
  const [newPackageSupplierCategory, setNewPackageSupplierCategory] =
    useState<IpackageSupplierCategory>({
      categoryName: "",
      isVisible: true,
    });
  const [newSupplierCategoryModelOpen, toogleNewSupplierCategoryModel] =
    useState(false);

  const [allPackageSupplierCategories, setAllPackageSupplierCategories] =
    useState<Array<IpackageSupplierCategory | undefined>>([]);
  useEffect(() => {
    getAllPackageSupplierCategories();
    return () => {
      // cleanup
    };
  }, []);

  const getAllPackageSupplierCategories = async () => {
    const response =
      await SupplierManagementApiCalls.getPackageSupplierCategories();
    console.log({ response });
    if (response?.success) {
      setAllPackageSupplierCategories(response?.result);
    } else {
      return toast.error(
        response?.data?.errorMessage ||
          "Could not fetch Supplier Categories from DB"
      );
    }
  };

  const handleInpChange = (e) => {
    setNewPackageSupplierCategory({
      ...newPackageSupplierCategory,
      [e.target.name]: e.target.value,
    });
  };

  const createPackageSupplierCategoty = async () => {
    if (!isset(newPackageSupplierCategory.categoryName)) {
      return toast.error("Please add supplier category name!");
    }
    const response =
      await SupplierManagementApiCalls.savePackageSupplierCategory(
        newPackageSupplierCategory
      );
    if (response?.success) {
      toast.success("Supplier Category Saved!");
      getAllPackageSupplierCategories();
      toogleNewSupplierCategoryModel(false);
    } else {
      toast.error("Could not save Supplier Category!");
    }
  };

  const changeVisibilityOfPackageSupplierCategory = async (
    event,
    category,
    index
  ) => {
    // console.log(event.target.checked, category);
    category.isVisible = event.target.checked;
    const response =
      await SupplierManagementApiCalls.savePackageSupplierCategory(category);
    // console.log({ response });
    if (response.success) {
      getAllPackageSupplierCategories();
    } else {
      toast.error(response?.errorMessage);
    }
  };

  return (
    <>
      <Dialog
        title="Create New Supplier Category"
        isOpen={newSupplierCategoryModelOpen}
        handleClose={() => toogleNewSupplierCategoryModel(false)}
      >
        <div className=" p-2 ">
          <Inp
            name="categoryName"
            placeHolder="Supplier Category Name"
            value={newPackageSupplierCategory.categoryName}
            handleChange={(e) => handleInpChange(e)}
          />
          <div className="text-center">
            <button
              className="btn btn-success "
              onClick={createPackageSupplierCategoty}
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>

      <div className="d-flex flex-row-reverse bg-light border p-2">
        <button
          className="btn btn-dark btn-sm"
          onClick={() => toogleNewSupplierCategoryModel(true)}
        >
          + Package Supplier Category
        </button>
      </div>

      <div className="my-2">
        <ul className="list-group">
          {allPackageSupplierCategories?.length ? (
            allPackageSupplierCategories?.map((category, index) => (
              <li
                className="list-group-item d-flex justify-content-between"
                key={index}
              >
                {category?.categoryName}
                <span>
                  <FormControlLabel
                    className="mx-2"
                    control={
                      <Switch
                        size="small"
                        checked={Boolean(category?.isVisible)}
                        onChange={(event) =>
                          changeVisibilityOfPackageSupplierCategory(
                            event,
                            category,
                            index
                          )
                        }
                      />
                    }
                    label={
                      <span className="fs-6 fw-light">
                        <small>Visibility Status</small>
                      </span>
                    }
                  />
                </span>
              </li>
            ))
          ) : (
            <div className="alert alert-warning">
              No Package Supplier Categories found!
            </div>
          )}
        </ul>
      </div>
    </>
  );
};
