import axios from "axios";
import React, { useEffect } from "react";

import { toast } from "react-toastify";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const CashfreePaymentGateway: React.FC<{
  form: any;
  paymentType?: "SetupFee" | "MonthlyFee";
  customer: any;
  setLoading: Function;
  orderId?: number;
}> = (props) => {
  // const createCashfreeOrder = () => {
  //   toast.error("creating cashfree order");
  // };

  const createCashfreeOrder = (
    paymentType: "SetupFee" | "MonthlyFee" = props.paymentType || "SetupFee"
  ) => {
    // console.log('razorpay triggered')
    props.setLoading(true);
    console.log(props.customer, "this is customer");
    axios({
      url: "/api/payment/cashfreeCreateOrder",
      method: "POST",
      headers: {
        "customer-token": localStorage.getItem("customer-token"),
      },
      data: {
        customer: props.customer,
        paymentType: paymentType,
        orderId: props.orderId, // Used for payment of monthly billing
      },
    }).then(async (r) => {
      props.setLoading(false);
      if (!r?.data?.success || !r?.data?.result) {
        return toast.error(
          r?.data?.error || "Error in creating payment gateway order..."
        );
      }
      console.log("cashfree response", r.data);
      let cashfreeRes = r.data?.result;
      if (!cashfreeRes?.payment_session_id) {
        return toast.error(
          "Error in creating payment gateway order... Failed to create payment session id!"
        );
      }

      if (r.data.success && cashfreeRes) {
        // const paymentLink = cashfreeRes.payment_link;
        // window.location.href = paymentLink;
        if (!r?.data?.pgRedirectUrl) {
          return toast.error(
            "Error in creating payment gateway order... Failed to create payment session id!"
          );
        }
        await loadScript(r.data.pgRedirectUrl);
        const windowRef: any = window;
        const cashfree = new windowRef.Cashfree(cashfreeRes.payment_session_id);
        cashfree.redirect();
      }
    });
  };

  const createOrder = async () => {
    try {
      createCashfreeOrder();
    } catch (error) {
      toast.error("Could not create order using Cashfree payment gateway!");
    }
  };

  useEffect(() => {
    console.log({ cashfreeProps: props });
    if (!props.customer || !props.form) {
      toast.error("customer or form not found");
    } else {
      createOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
