import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { postRequest } from "tools/ajax";
import { toast } from "react-toastify";
import { IAllWlIntegration } from "commonFnBcode/Interfaces/userInterfaces";

export const EditApiIntegrationPopup = (props: {
  reload: any;
  show: any;
  onHide: any;
  apischema: any;
  data: IAllWlIntegration | undefined;
}) => {
  const [updatedApiIntegaretionData, setUpdatedApiIntegaretionData] = useState(
    {}
  );
  const [apiCredentials, setApiCredentials] = useState<any>({});
  const [isValueEdited, setIsValueEdited] = useState<boolean>(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const siteId = queryParameters.get("id");

  const handleUpdatedApiIntegaretionData = (e) => {
    setIsValueEdited(true);
    let { name, value } = e.target;
    if (name === "isActive") {
      value = e.target.checked;
    }
    if (name === "isTesting") {
      value = e.target.value.toLocaleLowerCase() === "true";
    }
    setUpdatedApiIntegaretionData((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    props.data &&
      setUpdatedApiIntegaretionData((old: any) => ({
        isTesting: props?.data?.isTesting,
        isActive: props?.data?.isActive,
        apiCredentials: props?.data?.apiCredentials,
        id: props?.data?.id,
      }));
    setApiCredentials((old) => props?.data?.apiCredentials);
  }, [props.data]);

  useEffect(() => {
    setUpdatedApiIntegaretionData((old: any) => ({
      ...old,
      apiCredentials,
    }));
  }, [apiCredentials]);

  const updateSupplierCredentialsData = async () => {
    if (!isValueEdited) return;
    const response = await postRequest(
      "admin/nfs/updateSupplierCredentialsData",
      updatedApiIntegaretionData
    );
    if (response?.success) {
      toast.success("Api Integration Data Updated");
      props.reload(siteId);
      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit <b>{props.data && props.data?.flightApiIntegration?.apiName}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-2">
            <label htmlFor="select-api" className="form-label">
              <b>Testing</b>
            </label>
            <div className="form-check">
              <input
                defaultChecked={props?.data?.isTesting}
                className="form-check-input"
                type="radio"
                name="isTesting"
                id="testing-yes"
                value={"true"}
                onChange={handleUpdatedApiIntegaretionData}
              />
              <label className="form-check-label" htmlFor="testing-yes">
                Yes
              </label>
            </div>
            <div className="form-check">
              <input
                onChange={handleUpdatedApiIntegaretionData}
                defaultChecked={!props?.data?.isTesting}
                value="false"
                className="form-check-input"
                type="radio"
                name="isTesting"
                id="testing-no"
              />
              <label className="form-check-label" htmlFor="testing-no">
                No
              </label>
            </div>
          </div>
          <div className="col-2">
            <div className="form-check form-switch">
              <input
                defaultChecked={props?.data?.isActive}
                name="isActive"
                className="form-check-input"
                type="checkbox"
                id="isActive"
                onChange={handleUpdatedApiIntegaretionData}
              />
              <label className="form-check-label" htmlFor="isActive">
                <b>isActive</b>
              </label>
            </div>
          </div>
          <div className="col-4">
            <label className="form-check-label mb-2" htmlFor="testing-no">
              <b>Credentials</b>
            </label>
            {!props?.apischema?.length && (
              <p className="text-danger">Not Avalible</p>
            )}
            {props?.apischema?.length &&
              props.apischema.map((apiField, index) => {
                return (
                  <div className="input-group mb-3 w-100" key={index}>
                    <div className="w-100">
                      <label className="small" htmlFor={apiField}>
                        {apiField}
                      </label>

                      <input
                        id={apiField}
                        name={apiField}
                        type="text"
                        className="w-100 form-control"
                        placeholder={apiField}
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                          setIsValueEdited(true);
                          let { name, value } = e.target;
                          setApiCredentials((old) => ({
                            ...old,
                            [name]: value,
                          }));
                        }}
                        defaultValue={props?.data?.apiCredentials[apiField]}
                      />
                    </div>
                  </div>
                );
              })}
          </div>{" "}
          <div className="col-4">
            <label htmlFor="">
              {" "}
              <b>wl-Flight Api Credential Name</b>
            </label>
            <input
              onChange={handleUpdatedApiIntegaretionData}
              name="wlFlightApiCredentialName"
              type="text"
              className="form-control"
              defaultValue={props.data?.wlFlightApiCredentialName || ""}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            props.onHide();
          }}
          className="btn btn-secondary"
        >
          Close
        </button>
        <button
          style={{ cursor: `${isValueEdited ? "" : "not-allowed"}` }}
          onClick={() => {
            updateSupplierCredentialsData();
          }}
          className="btn btn-success"
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};
