import React, { useEffect, useState } from "react";
import moment from "moment";

import { ISippiCustomerWithPurchase } from "types/sippiCustomer.types";
import { SippiCustomerContactDetailsModal } from "./SippiCustomerContactDetailsModal";

export const SippiCustRow: React.FC<{
  customer: ISippiCustomerWithPurchase;
  jumpLogin: (IsippiCustomer) => void;
  setSelectedCustomer: (IsippiCustomer) => void;
  getSippiCustomers: () => void;
}> = (props) => {
  const CustomerDetails = props.customer;
  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false);
  return (
    <>
      <tr className={""}>
        <td>
          <button
            onClick={() => props.jumpLogin(CustomerDetails)}
            className="btn btn-primary btn-sm m-1"
          >
            Jump Login
          </button>
          <br />
          <button
            className="btn btn-info btn-sm m-1"
            onClick={() => props.setSelectedCustomer(CustomerDetails)}
          >
            Billing Details
          </button>
        </td>
        <td className={""}>
          <img
            src={CustomerDetails?.googlePersonObj?.imageUrl}
            alt={CustomerDetails?.googlePersonObj?.givenName}
            className={" img-fluid rounded-circle m-1"}
            style={{ maxWidth: "30px" }}
          />
          {`${CustomerDetails.googlePersonObj?.givenName} ${CustomerDetails.googlePersonObj?.familyName}`}
          <br />
          {CustomerDetails.googlePersonObj?.email}
          <br />
          <span className="small">
            Ph. {CustomerDetails?.purchases?.form?.phoneNumber}
            <i
              className="fas fa-edit ms-2  text-primary"
              onClick={() => setShowEditDetailsModal(true)}
              role="button"
            ></i>
          </span>
          <br />
          {moment(CustomerDetails?.createdAt).format("lll")}
        </td>
        <td>
          {CustomerDetails?.purchases
            ? CustomerDetails?.purchases?.planName
            : "Not Purchased"}
        </td>
        <td>
          {CustomerDetails?.purchases
            ? CustomerDetails?.purchases?.gatewayUsed
            : "Not Purchased"}

          <br />
          {CustomerDetails?.purchases && CustomerDetails?.purchases?.isPaid && (
            <>
              <span className="bg-success text-light px-1">
                <small>
                  <b>PAID</b>
                </small>
              </span>
            </>
          )}
        </td>
        <td>
          {CustomerDetails?.purchases && CustomerDetails?.purchases?.form
            ? CustomerDetails?.purchases?.form.promoCode
            : "---"}
        </td>
      </tr>
      {showEditDetailsModal && (
        <SippiCustomerContactDetailsModal
          userType="admin"
          getSippiCustomers={props.getSippiCustomers}
          show={showEditDetailsModal}
          handleClose={() => setShowEditDetailsModal(false)}
          customerDetails={CustomerDetails}
        />
      )}
    </>
  );
};
