import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IsippiCustomer } from "types/sippiCustomer.types";

const initialState: {
  customer?: IsippiCustomer;
} = {};

export const sippiCustomerSlice = createSlice({
  name: "sippiCustomer",
  initialState: initialState,
  reducers: {
    setSippiCustomer: (state, action: PayloadAction<IsippiCustomer>) => {
      state.customer = action.payload;
    },
    clearSippiCustomer: (state) => {
      state.customer = undefined;
    },
  },
});

export const sippiCustomerReducer = sippiCustomerSlice.reducer;
