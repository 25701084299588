import {
  addHDFCCCavenueDetailsApiCall,
  getHDFCCCAvenueDetailsApiCall,
} from "apiCalls/siteSettingsDb";
// import { Button } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import TextInp from "commonUi/input/text/TextInp";
import { useEffect } from "react";
import { useForm, Resolver, Controller } from "react-hook-form";
import { toast } from "react-toastify";

interface IFormValues {
  accessCode: string;
  workingKey: string;
  merchantId: string;
}

const resolver: Resolver<IFormValues> = async (values) => {
  return {
    values: values.accessCode ? values : {},
    errors: {},
  };
};

export const HDFCDetails = () => {
  const { handleSubmit, control, getValues, setValue } = useForm<IFormValues>({
    resolver,
  });

  useEffect(() => {
    getHdfcDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHdfcDetails = async () => {
    const hdfcDetailsRes = await getHDFCCCAvenueDetailsApiCall();
    const hdfcDetails = hdfcDetailsRes.result?.pgDetail;

    if (hdfcDetails?.accessCode) {
      setValue("accessCode", hdfcDetails["accessCode"]);
    }

    if (hdfcDetails?.workingKey) {
      setValue("workingKey", hdfcDetails["workingKey"]);
    }

    if (hdfcDetails?.merchantId) {
      setValue("merchantId", hdfcDetails["merchantId"]);
    }

    // Object.keys(hdfcDetails).forEach((hdfcKey) => {
    //   switch (hdfcKey) {
    //     case "accessCode":
    //       setValue("accessCode", hdfcDetails[hdfcKey]);
    //       break;

    //     case "workingKey":
    //       setValue("workingKey", hdfcDetails[hdfcKey]);
    //       break;

    //     case "merchantId":
    //       setValue("merchantId", hdfcDetails[hdfcKey]);
    //       break;
    //     default:
    //       break;
    //   }
    // });
  };

  const onSubmit = handleSubmit(async (data) => {
    // toast.error(data);
    const hdfcForm = getValues();
    const hdfcUpdate = await addHDFCCCavenueDetailsApiCall(hdfcForm);
    if (hdfcUpdate) {
      // setPgbtnDisabled(false);
      toast.success("HDFC Details successfully saved.");
    }
  });

  return (
    <>
      <p>These are the HDFC Details</p>
      <form onSubmit={onSubmit} className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 form-group mb-3">
          <Controller
            name="accessCode"
            control={control}
            required
            defaultValue=""
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"
                label="Access Code"
              />
            )}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-12 form-group mb-3">
          <Controller
            name="workingKey"
            control={control}
            defaultValue=""
            required
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"
                label="Working Key"
              />
            )}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-12 form-group mb-3">
          <Controller
            name="merchantId"
            required
            control={control}
            defaultValue=""
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"

                label="Merchant Id"
              />
            )}
          />
        </div>
        <div className="text-center">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
        {/* <input type="submit" /> */}
      </form>
    </>
  );
};
