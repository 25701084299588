import React, { Component } from 'react'
import {List} from 'semantic-ui-react'
import ListCountry from './ListCountry'
import NewCountryForm from './NewCountryForm'
import { getCountries } from 'apiCalls/masterDb'

export default class CountriesCard extends Component {

    constructor(props){
        super()
        this.state = {
            countries: []
        }
    }

    componentDidMount =() =>{
        this.getCountry()
    }

    getCountry = async () => {
        const countries = await getCountries()
        this.setState({
           countries
        })
    }

    render() {
        return (
            <React.Fragment>
            <div className="card">
                <h1>Countries</h1>  
                <NewCountryForm getCountries={this.getCountry} />
                <List divided verticalAlign='middle'>
                    {this.state.countries.map((v,i)=>(
                        <ListCountry country={v} key={i} />
                    ))}
                </List>
            </div> 
            </React.Fragment>
        )
    }
}
