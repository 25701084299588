import { Modal } from "react-bootstrap";
import { UploadImage as ReactEasyCrop } from "@sippisoftwaresolutions/upload-image-pkg";
export const UploadAirlineLogoPopup: React.FC<{
  show: boolean;
  setImageData: (data: Blob) => void;
  onHide: () => void;
}> = (props) => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload Airline Logo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Select Airline Logo</h4>
        <ReactEasyCrop aspectRatio={1} setImageData={props.setImageData} />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-success" onClick={props.onHide}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};
