import React, { useEffect, useState } from "react";

import Btn from "commonUi/button/btnPrimary";
import { useDispatch } from "react-redux";
import { updateActualPaymentStatusForWhitelabel } from "redux/actions/accounts.actions";
import { Form } from "react-bootstrap";

export default function EditActualAmount(props) {
  const dispatch = useDispatch();

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setAmount(props.amount);
  }, [props.amount]);

  const handleChange = ({ target }) => setAmount(target.value);

  const save = () => {
    dispatch(
      updateActualPaymentStatusForWhitelabel({
        siteId: props.siteId,
        actualPaymentAmount: amount,
        paymentStatus: props.actualPaymentStatus,
      })
    );
  };

  return (
    <div>
      <Form.Group>
        <Form.Label className="mb-0 text-muted">Actual Amount Paid</Form.Label>
        <Form.Control type="number" onChange={handleChange} value={amount} />
      </Form.Group>
      <Btn onClick={save} label="save" />
    </div>
  );
}
