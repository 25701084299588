import axios from "axios";
import { toast } from "react-toastify";

enum MethodsEnum {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

interface IAxios {
  url: string;
  method: MethodsEnum;
  data: object;
  headers?: object;
}

const _getHeaders = (propsHeaders) => ({
  "auth-token": localStorage.getItem("auth-token"),
  "customer-token": localStorage.getItem("customer-token"),
  ...propsHeaders,
});

const _axiosRequest = async (props: IAxios) => {
  try {
    /**
     * if GET request then we pass params object
     * else we pass data object
     */
    const dataOrParams = props.method === "GET" ? "params" : "data";
    const axiosOptions = {
      url: `/api/${props.url}`,
      method: props.method,
      [dataOrParams]: props.data ? props.data : {},
      headers: _getHeaders(props?.headers),
    };
    const axiosResponse = await axios(axiosOptions);
    return axiosResponse;
  } catch (err: any) {
    if (err.response) {
      let data = err.response.data;
      if (data.success === false && data.errorMessage) {
        toast.error(data.errorMessage);
        return data;
      }
    } else {
      console.log({
        msg: "HTTP Request failed",
        err,
      });
    }
  }
};
export const postRequest = async (url, data) => {
  try {
    const result = await _axiosRequest({
      url,
      data,
      method: MethodsEnum.POST,
    });
    if (result) {
      return result.data;
    } else {
      return {
        success: false,
        errorMessage:
          "Something went wrong. HTTP (POST) request failed. Please contact support.",
      };
    }
  } catch (err) {
    console.log("POST REQUEST FAILED \n\n", err);
  }
};

export const getRequest = async (url, data, headers = {}) => {
  try {
    const result = await _axiosRequest({
      url,
      data,
      method: MethodsEnum.GET,
      headers,
    });
    if (result) {
      return result.data;
    } else {
      return {
        success: false,
        errorMessage:
          "Something went wrong. HTTP (GET) request failed. Please contact support.",
      };
    }
  } catch (err) {
    console.log("GET REQUEST FAILED \n\n", err);
  }
};

export const deleteRequest = async (url, data) => {
  try {
    const result = await _axiosRequest({
      url,
      data,
      method: MethodsEnum.DELETE,
    });
    if (result) {
      return result.data;
    } else {
      return {
        success: false,
        errorMessage:
          "Something went wrong. HTTP (DELETE) request failed. Please contact support.",
      };
    }
  } catch (err) {
    console.log("DELETE REQUEST FAILED \n\n", err);
  }
};
