import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
//custom components
import MainMenu from "./DrawerMenu";

import logo from "assets/images/newHome/sippilogo.png";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { sippiCustomerSlice } from "redux/slices/sippiCustomer.slice";
import { IsippiCustomer } from "types/sippiCustomer.types";
import { postRequest } from "tools/ajax";
import { toast } from "react-toastify";

export default function Header() {
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const history = useHistory();
  const sippiCustomer = useAppSelector(
    (state) => state.sippiCustomer?.customer
  );
  useEffect(() => {
    verifyCustomerToken();
  }, []);
  const logout = () => {
    localStorage.removeItem("customer-token");
    localStorage.removeItem("googlePersonObj");

    dispatch(sippiCustomerSlice.actions.clearSippiCustomer());
    history.push("/");
  };

  const userPopover = (
    <Popover id="userPopover1" style={{ zIndex: 5000 }}>
      <Popover.Body>
        <b>Email:</b> {sippiCustomer?.googlePersonObj.email}
        <br />
        <b>Name:</b>{" "}
        {`${sippiCustomer?.googlePersonObj?.givenName} ${sippiCustomer?.googlePersonObj?.familyName} `}
      </Popover.Body>
    </Popover>
  );
  const verifyCustomerToken = async () => {
    const response = await postRequest("user/verifyCutomerToken", {});
    if (response?.err) {
      logout();
      // toast.error("tocken expired , please login again");
    }
  };
  return (
    <>
      {/* <div className={classes.root}>
        <AppBar position="fixed" color="inherit" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => setMenuOpen(true)}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.sippiLogo}>
              <Link to="/" className={classes.routerLink}>
                <img src={logo} alt="sippi logo" style={{ width: "2rem" }} />
              </Link>
            </div>
            <div className={classes.menuRightButton}>
              {user.name ? (
                <>
                  <OverlayTrigger
                    trigger={["hover", "focus", "click"]}
                    placement="bottom"
                    overlay={userPopover}
                  >
                    <div className="d-flex align-items-center justify-content-center fw-bold ">
                      <img
                        alt={user.name}
                        src={user.imageUrl}
                        className="rounded-circle m-2"
                        style={{ height: "45px", width: "45px" }}
                      />
                      <p>{` Hi, ${user.name}`}</p>
                    </div>
                  </OverlayTrigger>

                  <IconButton onClick={logout}>
                    <i className="fas fa-power-off"></i>
                  </IconButton>
                </>
              ) : (
                <Link to="/login" className={classes.routerLink}>
                  <Button>Login</Button>
                </Link>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ height: "80px" }}></div>
        <MainMenu open={menuOpen} close={() => setMenuOpen(false)} />
      </div> */}

      <div className="pss-header">
        <Navbar
          bg="white"
          expand="lg"
          className="shadow"
          id="header"
          fixed="top"
        >
          <Container>
            <Navbar.Brand>
              <div className="">
                <Link to="/" className="sippi-logo">
                  <img src={logo} alt="sippi logo" />
                </Link>
              </div>
            </Navbar.Brand>
            {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} /> */}

            <Nav
              className="ms-auto align-items-center"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              {sippiCustomer?.googlePersonObj?.email ? (
                <>
                  <div className="d-flex align-items-center">
                    <OverlayTrigger
                      trigger={["hover", "focus", "click"]}
                      placement="bottom"
                      overlay={userPopover}
                    >
                      <div className="d-flex align-items-center justify-content-center fw-bold login-person ">
                        <img
                          alt={sippiCustomer.googlePersonObj.givenName}
                          src={sippiCustomer.googlePersonObj.imageUrl}
                          className="rounded-circle"
                          style={{ height: "30px", width: "30px" }}
                        />
                        <p>{` Hi, ${sippiCustomer.googlePersonObj.familyName}`}</p>
                      </div>
                    </OverlayTrigger>

                    <div onClick={logout} className="logout-button">
                      <i className="fas fa-power-off"></i>
                    </div>
                  </div>
                </>
              ) : (
                <Link to="/login" className="login-button">
                  <button className="btn btn-primary">Login</button>
                </Link>
              )}
            </Nav>
            <div onClick={() => setMenuOpen(true)} className="menu-button">
              <i className="fas fa-bars"></i>{" "}
              <span className="menu-btn-text">Menu</span>
            </div>
            <MainMenu open={menuOpen} close={() => setMenuOpen(false)} />
          </Container>
        </Navbar>
        <div className="navbar-space"></div>
      </div>
    </>
  );
}
