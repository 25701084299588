import {takeEvery,
    call,put
} from 'redux-saga/effects'
import {packageTypes} from 'redux/actionTypes/package.types'
import {getAllPackages as getAllPackagesDb} from 'apiCalls/packageDb'

function* getAllPackages(){
    console.log('get all packages triggered')
    const packages = yield call(getAllPackagesDb)
        yield put({
            type: packageTypes.SET_ALL_PACKAGES,
            payload: packages
        })

}

export function* watchPackages(){
    yield takeEvery(packageTypes.GET_ALL_PACKAGES,getAllPackages)
}