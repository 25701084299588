import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Switch, Route } from "react-router-dom";
import UserPublicRoutes from "./UserPublicRoutes";
import AdminRoutes from "./AdminRoutes";
import PrivacyPolicy from "components/user/staticPages/privacyPolicy";
import Home from "components/user/home/home";
import Login from "components/user/login/Index";
import LoggedInRoute from "./LoggedInRoutes";
// import Dashboard from "components/user/dashboard/Dashboard";
import Admin from "components/admin/login/loginIndex";

import TravelEsLitePrePayment from "components/user/plans/travelEsLite/PrePaymentForm";
import TermsAndConditions from "components/user/staticPages/termsAndConditions";
import Error404 from "components/staticPages/404";
import useScroll from "hooks/useScroll";
import AdminDashboard from "../admin/dashboard/dashboardIndex";
import Settings from "components/admin/settings/Settings";
import WhiteLabel from "components/admin/whiteLabel/index";

import SippiCustomers from "components/admin/sippiCustomers/SippiCustomer";
import AdminPackages from "components/admin/packages/index";
import AdminMaster from "components/admin/master/indexMaster";
import API from "components/admin/APIs/Routes";
import ManageStaff from "components/user/mangeStaff/ManageStaff";
import Visa from "components/admin/visa";
import EmailSettings from "components/user/emailSettings/Index";
import AlertMessage from "tools/AlertMessage";
import ReduxLoader from "tools/Loader";
import Wallet from "components/admin/wallet";
import { AllEntries } from "components/admin/wallet/AllEntries/index";
import { FlightModuleSettings } from "components/admin/flightModuleSettings";
import { AdminRouteConstants } from "constants/routes";
import { AdvanceSettings } from "components/admin/Advance/AdvanceSettings";
import { ErrorLogs } from "components/admin/Advance/ErrorLogs";
import { UserAdvanceSettings } from "components/user/advanceSettings/AdvanceSettings";
import { PaymentGatewayTransactions } from "components/admin/PaymentGateways/PaymentGatewayTransactions";
import { FDKIngMapping } from "components/admin/Advance/FDKIngMapping";
import { TBOLogs } from "components/admin/Advance/TBOLogs";
import { LookToBookRatio } from "components/user/lookToBookRatio/lookToBookRatio";
import { SupplierManagement } from "components/admin/supplierManagement/Index";
import { ApiCredentials } from "components/user/ApiCredentials/ApiCredentials";
import { PageBuilder } from "components/user/PageBuilder/PageBuilder";
import { CompareTboExcel } from "components/admin/compareTboExcel/CompareTboExcel";
import { PlatformAndUsageFee } from "components/user/staticPages/PlatformAndUsageFee";
import { DuplicateEmailPhone } from "components/admin/Advance/DuplicateEmailPhone";
import { DmcModuleSettings } from "components/user/DmcModuleSettings/DmcModuleSettings";
import { SippiAccountsReport } from "components/admin/accounts/SippiAccountsReport";
import { MonthlyChargesReport } from "components/admin/accounts/monthly/Index";
import { SetupFeeReport } from "components/admin/accounts/sale";
import { SippiBilling } from "components/user/Billing/Billing";
import MongoCountryCityMaster from "components/admin/master/mongoCountryCityMaster";
import { MysqlCountryCity } from "components/admin/master/mysqlCountryCity/MysqlCountryCity";
import { FlightSupplierEmailLogs } from "components/admin/Advance/FlightSupplierEmailLogs";
// import { DynamicWebPages } from "components/user/advanceSettings/dynamicWebPages/DynamicWebPages";
import { FallbackDWP } from "components/admin/Advance/fallbackDWP/FallbackDWP";
import BankDetails from "components/user/bankDetails/Index";
// import SiteWiseProductivity from "components/admin/accounts/productivity/SiteWiseProductivity";

import { EditWLApiIntegrations } from "components/admin/whiteLabel/viewWhitelabel/EditWLApiIntegrations";
import { APIIntegrations } from "../../components/admin/APIs/APIIntegrations";
import { UserWallet } from "components/user/UserWallet/UserWallet";
import { WalletRecharge } from "components/user/UserWallet/WalletRecharge";

// import SippiCustomerTransactions from "components/admin/SippiCustomerTransaction/SippiCustomerTransactions";
import { Addons } from "components/user/Addons/Addons";
import { ComboPlansScreen } from "components/user/plans/ComboPlans/ComboPlansScreen";
import { useAppSelector } from "hooks/reduxHooks";
import { SelectAddonBeforePaymentScreen } from "components/user/plans/travelEsLite/SelectAddonBeforePaymentScreen";
import { BillingSlots } from "components/admin/accounts/Billing/BilingSlots";
import { BillingSummaries } from "components/admin/accounts/Billing/BillingSummaries";
import { SiteWiseBillingUsage } from "components/admin/accounts/Billing/SiteWiseBillingUsage";
import { AirportsSettings } from "components/admin/AirportsSettings/AirportsSettings";
import { AddonItem } from "components/user/Addons/AddonsItems/AddonItem";
import { PaymentScreen } from "components/user/UserWallet/PaymentScreen";
import { ApiSettings } from "components/user/ApiSettings/ApiSettings";

const SippiCustomerTransactions = React.lazy(
  () =>
    import(
      "components/admin/SippiCustomerTransaction/SippiCustomerTransactions"
    )
);
const Dashboard = React.lazy(
  () => import("components/user/dashboard/Dashboard")
);

// const APIIntegrations = React.lazy(
//   () => import("components/admin/APIs/APIIntegrations")
// );
const SiteWiseProductivity = React.lazy(
  () => import("components/admin/accounts/productivity/SiteWiseProductivity")
);

const Routes: React.FC = () => {
  useScroll();
  const sippiCustomer = useAppSelector(
    (state) => state.sippiCustomer.customer?.purchases?.isPaid
  );
  return (
    <React.Fragment>
      <>
        <AlertMessage />
        <ReduxLoader />
        <Switch>
          <UserPublicRoutes exact path="/" component={Home} />
          <UserPublicRoutes path="/login" component={Login} />
          <UserPublicRoutes path="/privacy-policy" component={PrivacyPolicy} />
          <UserPublicRoutes
            path="/platform_and_usage_fee"
            component={PlatformAndUsageFee}
          />
          <UserPublicRoutes
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <LoggedInRoute path="/user/dashboard" component={Dashboard} />
          <LoggedInRoute
            path="/buyTravelWebsite"
            component={TravelEsLitePrePayment}
          />
          <LoggedInRoute
            path="/travelWebsitePrice"
            component={sippiCustomer ? Dashboard : ComboPlansScreen}
          />
          <LoggedInRoute path="/user/manage_staff" component={ManageStaff} />
          <LoggedInRoute
            path="/user/email_settings"
            component={EmailSettings}
          />
          <LoggedInRoute
            path="/user/dmc_module_settings"
            component={DmcModuleSettings}
          />
          <LoggedInRoute path="/user/billing" component={SippiBilling} />
          <LoggedInRoute
            path="/user/look_to_book"
            component={LookToBookRatio}
          />
          <LoggedInRoute path="/user/bank_details" component={BankDetails} />
          <LoggedInRoute
            path="/user/user_wallet"
            component={sippiCustomer ? UserWallet : ComboPlansScreen}
          />
          <LoggedInRoute
            path="/user/wallet_recharge"
            component={WalletRecharge}
          />{" "}
          <LoggedInRoute
            path="/user/advance_settings"
            component={UserAdvanceSettings}
          />
          {/* <LoggedInRoute
            path="/user/dynamic_web_pages"
            component={DynamicWebPages}
          /> */}
          <LoggedInRoute path="/user/page_builder" component={PageBuilder} />
          <LoggedInRoute
            path="/user/api_credentials"
            component={ApiCredentials}
          />
          <LoggedInRoute
            path="/user/SelectAddonBeforePaymentScreen"
            component={SelectAddonBeforePaymentScreen}
          />
          <LoggedInRoute
            path="/user/payment_screen"
            component={PaymentScreen}
          />
          <LoggedInRoute path="/user/addons/:addonEnum" component={AddonItem} />
          <LoggedInRoute path="/user/addons" component={Addons} />
          {/* //TODO uncomment when isActive is working in main app */}
          <LoggedInRoute path="/user/apiSettings" component={ApiSettings} />
          <Route exact path="/admin" component={Admin} />
          <AdminRoutes path="/admin/sippiStaff" component={AdminDashboard} />
          <AdminRoutes path="/admin/packages" component={AdminPackages} />
          <AdminRoutes path="/admin/master" component={AdminMaster} exact />
          <AdminRoutes
            path="/admin/master/mongo_country_cities"
            component={MongoCountryCityMaster}
            exact
          />
          <AdminRoutes
            path="/admin/airports_settings/"
            component={AirportsSettings}
          />
          <AdminRoutes
            path="/admin/master/mysql_country_cities"
            component={MysqlCountryCity}
            exact
          />
          <AdminRoutes path="/admin/whiteLabel" component={WhiteLabel} />
          <AdminRoutes path="/admin/suppliers" component={SupplierManagement} />
          <AdminRoutes
            path="/admin/new-suppliers"
            component={SupplierManagement}
          />
          <AdminRoutes
            path="/admin/flight_module_settings/"
            component={FlightModuleSettings}
          />
          <AdminRoutes path="/admin/api/" component={API} />
          <AdminRoutes
            path="/admin/api/apiintegrations"
            component={APIIntegrations}
          />
          <AdminRoutes path="/admin/settings/" component={Settings} />
          <AdminRoutes
            path="/admin/sippiCustomers/"
            component={SippiCustomers}
          />
          <AdminRoutes path="/admin/visa" component={Visa} />
          <AdminRoutes exact path="/admin/wallet/old" component={Wallet} />
          <AdminRoutes
            exact
            path={`${AdminRouteConstants.ADMIN_WALLET}/:siteId?`}
            component={AllEntries}
          />
          <AdminRoutes
            path="/admin/accounts"
            component={SippiAccountsReport}
            exact
          />
          <AdminRoutes
            path="/admin/accounts/setup_fee"
            component={SetupFeeReport}
            exact
          />
          <AdminRoutes
            path="/admin/accounts/monthly_charges"
            component={MonthlyChargesReport}
            exact
          />
          <AdminRoutes
            path="/admin/accounts/site-wise-productivity"
            component={SiteWiseProductivity}
            exact
          />
          <AdminRoutes
            path="/admin/accounts/billing_slots"
            component={BillingSlots}
            exact
          />
          <AdminRoutes
            path="/admin/accounts/billing_summary"
            component={BillingSummaries}
          />
          <AdminRoutes
            path="/admin/accounts/billingUsage"
            component={SiteWiseBillingUsage}
          />
          <AdminRoutes
            exact
            path="/admin/advance_settings"
            component={AdvanceSettings}
          />
          <AdminRoutes
            exact
            path="/admin/advance_settings/error_logs"
            component={ErrorLogs}
          />
          <AdminRoutes
            exact
            path="/admin/advance_settings/fdking_mapping"
            component={FDKIngMapping}
          />
          <AdminRoutes
            exact
            path="/admin/advance_settings/duplicate_email_phone"
            component={DuplicateEmailPhone}
          />
          <AdminRoutes
            exact
            path="/admin/advance_settings/tbo_logs"
            component={TBOLogs}
          />
          <AdminRoutes
            exact
            path="/admin/advance_settings/supplier_email_logs"
            component={FlightSupplierEmailLogs}
          />
          {/* <AdminRoutes
            exact
            path="/admin/advance_settings/fallback_DWP"
            component={FallbackDWP}
          /> */}
          <AdminRoutes
            exact
            path={`${AdminRouteConstants.ADMIN_PG_TXN}/:siteId?`}
            component={PaymentGatewayTransactions}
          />
          <AdminRoutes
            exact
            path={`${AdminRouteConstants.ADMIN_COMPARE_TBO_EXCEL}`}
            component={CompareTboExcel}
          />
          <AdminRoutes
            exact
            path="/admin/sippi_customer_transactions"
            component={SippiCustomerTransactions}
          />
          {/* <AdminRoutes
            exact
            path="/admin/recharge_plan_settings"
            component={RechargePlanSettings}
          /> */}
          <UserPublicRoutes exact path="*" component={Error404} />
        </Switch>
      </>
    </React.Fragment>
  );
};

export default Routes;
