import { z } from "zod";

export const editWhitelabelSchema = z.object({
  siteName: z.string(),
  adminEmail: z.string().email(),
  adminContactPhoneCode: z.string(),
  adminContact: z.string().min(5).max(15),
  whatsAppNumber: z.string().min(5).max(15),
});

export const ZpurchaseFormSchema = z.object({
  companyName: z.string(),
  billingName: z.string(),
  email: z.string().email(),
  phoneCountryCode: z.string(),
  phoneNumber: z.string(),
  country: z.string(),
  gst: z.string(),
  state: z.string(),
  address1: z.string(),
  address2: z.string(),
  pin: z.string(),
  // amountPayable: z.string(),
  promoCode: z.string(),
  panCardNo: z.string().optional(),
  // panCardName: z.string().optional(),
});

export enum productTypeEnum {
  AMADEUS = "amadeus",
  FARE_BOUTIQUE = "fare_Boutique",
  TRAVEL_WEBSITE_PURCHASE = "Travel_Website_Purchase",
  WALLET_RECHARGE = "Wallet_Recharge",
  WHATSAPP = "whatsApp",
  TFNEXUS = "tfNexus",
  CHEAP_FIX_DEPARTURE = "cheapFixDeparture",
  GO_FLY_SMART = "goFlySmart",
  CALLER_DESK = "callerDesk",
  AIR_IQ = "airIQ",
  ADDON = "addon",
  TBO = "tbo",
  TRIP_JACK = "tripJack",
  CLEAR_TRIP = "clearTrip",
  GALILEO = "galileo",
  PAN_CARD_VERIFICATION = "panCardVerification",
  COMBO_PLAN_1 = "comboPlan1",
  COMBO_PLAN_2 = "comboPlan2",
  COMBO_PLAN_3 = "comboPlan3",
  COMBO_PLAN_4 = "comboPlan4",
}

export type IeditWhitelabelForm = z.infer<typeof editWhitelabelSchema>;
export type IpurchaseForm = z.infer<typeof ZpurchaseFormSchema>;

export interface Iwhitelabel {
  _id: string;
  domain: string;
  siteName: string;
  companyName: string;
  logoUrl?: string;
  adminEmail: string;
  adminContact: number | string;
  whatsAppNumber?: number | string;
  sippiCustomerId?: string;
  __v: number;
  packageSuppliers: string[];
  creationTime: Date;
  brandingSettings?: BrandingSettings;
  legalEntityId: number;
  supplier?: any;
  accountsEmail?: string;
  accountsEntryId?: any;
  companyId: number;
  isSuspended?: boolean;
  sippiCustomer?: any;
  status?: any;
}

// export interface Iwhitelabel {
//   [key: string]: any;
// }

export interface BrandingSettings {
  aboutUs?: string;
  form?: Form;
  logoImage?: LogoImage;
  featuredPackages?: string[];
  banners?: Banner[];
  frontPageImage?: any;
}

export interface Banner {
  title: string;
  shortDescription: string;
  linkUrl?: string;
  s3Obj: LogoImage;
  imageUrl: string;
}

export interface LogoImage {
  ETag: string;
  VersionId: string;
  Location: string;
  key: string;
  Key: string;
  Bucket: Bucket;
}

export enum Bucket {
  WhitelabelSippi = "whitelabel-sippi",
}

export interface Form {
  aboutUs: string;
  logoBuffer?: LogoBuffer | null;
  links: Link[];
  bannerImages?: any[];
  address?: string;
  topCities?: TopCity[];
  b2b?: boolean;
  lockMode?: boolean;
  theme?: Theme;
  deleteFrontPageImage?: any;
  frontPageImage?: any;
}

export interface Link {
  text: string;
  url: string;
}

export interface LogoBuffer {
  name: string;
}

export interface Theme {
  palette: Palette;
}

export interface Palette {
  common: Common;
  background: Background;
  primary: Ary;
  secondary: Ary;
}

export interface Background {
  paper: Paper;
  default: Default;
}

export enum Default {
  Fafafa = "#fafafa",
}

export enum Paper {
  Fff = "#fff",
}

export interface Common {
  black: Black;
  white: Paper;
}

export enum Black {
  The000 = "#000",
}

export interface Ary {
  light: string;
  main: string;
  dark: string;
  contrastText: Paper;
}

export interface TopCity {
  _id: string;
  cityName: string;
  countryId: string;
  countryName: string;
  __v: number;
}
