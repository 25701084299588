import React, { useEffect } from "react";
import { Container, Typography } from "@material-ui/core";
import SmtpForm from "./smtpForm/SmtpForm";
import GridContainer from "commonUi/grid/container/index";
import GridItem from "commonUi/grid/item/index";
import Tips from "./Tips";
import SmtpList from "./smtpList/SmtpList";
import { useDispatch } from "react-redux";
import { GET_SMTP_DETAILS } from "redux/actions/siteSettings.actions";

export default function EmailSettings() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GET_SMTP_DETAILS());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container style={{ paddingTop: "3rem" }}>
        <Typography variant="h5">Email Settings</Typography>
        <GridContainer spacing={2}>
          <GridItem md={3}>
            <SmtpForm />
          </GridItem>
          <GridItem md={4}>
            <Tips />
          </GridItem>
          <GridItem md={5}>
            <SmtpList />
          </GridItem>
        </GridContainer>
      </Container>
    </>
  );
}
