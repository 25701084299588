export const dmcSettingsRConstants = {
  saveDmcMetaData: {
    prefix: "dmcSettings", // here prefix is the part of url which denotes the route file
    endPoint: "/saveMetaData", // this is the final end point which will lead to the controller
  },
  getDmcMetaData: {
    prefix: "dmcSettings", // here prefix is the part of url which denotes the route file
    endPoint: "/getMetaData", // this is the final end point which will lead to the controller
  },
};
