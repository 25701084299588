import React, {useEffect,useState} from 'react'
import {Button,List,ListItem,ListItemText,Typography,makeStyles} from '@material-ui/core'

import axios from 'axios'

const useStyles = makeStyles({
    collectionList: {
        display: 'block',
        width: '100%'
    },
    itemText: {
        '&:nth-child(even)':{
            background: '#e8e3e7'
            }
    },
    collectionName: {
        display: 'inline-block'
    },
    syncBtn: {
        float: 'right'
    }
})

export default function DbSync(){
    const classes = useStyles()
    const [collections,setCollections] = useState([])

    useEffect(() => {
        getCollections()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getCollections = () => {
        axios({
            url: '/api/admin/settings/getProductionCollections',
            headers: {
                'auth-token': localStorage.getItem('auth-token')
            }
        }).then(r=>{
            console.log(r.data)
            setCollections(r.data)
        })
    }

    const syncCollection = (collection) => {
        console.log(collection)
    }

    return(
        <>
            <Typography variant="h6">
                Production Database Collections
            </Typography>
            <List className={classes.collectionList}>
                {collections.map((c,i)=>(
                    <ListItem button key={i} className={classes.itemText}>
                        <ListItemText>
                            <Typography className={classes.collectionName}>
                                {c.name}
                            </Typography>
                            <Button variant="outlined" color="primary"
                            size="small"
                            onClick={()=>{syncCollection(c)}}
                            className={classes.syncBtn} >
                                Sync Collection
                            </Button>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
           
            
        
        </>
    )
}