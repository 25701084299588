export const useRegex = () => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  /**
   * domain must not start with www or https:// or http://www. or https://www.
   */
  const domainRegex =
    /^(?!www\.|https?:\/\/|http:\/\/www\.|https:\/\/www\.)[a-z0-9._'-]+$/;
  return {
    emailRegex,
    domainRegex,
  };
};
