import { AdminApi } from "apiCalls/adminApi";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export const DuplicateEmailPhone: React.FC<{}> = (props) => {
  const [duplicateEmails, setDuplicateEmails] = useState<
    Array<{
      email: string;
      site_id: string;
      count: number;
    }>
  >([]);
  const [duplicatePhones, setDuplicatePhones] = useState<
    Array<{
      phone: string;
      site_id: string;
      count: number;
    }>
  >([]);
  const [duplicateUsers, setDuplicateUsers] = useState<Array<any>>();

  const getInitData = async () => {
    const dupEmails = await AdminApi.getDuplicateEmails();
    if (dupEmails.success) {
      setDuplicateEmails(dupEmails.result);
    }
    if (dupEmails.success) {
      setDuplicateEmails(dupEmails.result);
    }
    const dupPhones = await AdminApi.getDuplicatePhones();
    if (dupPhones.success) {
      setDuplicatePhones(dupPhones.result);
    }
  };

  const getDuplicateUsers = async (searchString: string) => {
    const duplicateUsers = await AdminApi.getDuplicateUsersByPhoneEmail(
      searchString
    );
    if (duplicateUsers.success) {
      setDuplicateUsers(duplicateUsers.result);
    }
  };

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <div>
      <div className="container">
        <Modal
          size="lg"
          show={duplicateUsers ? true : false}
          onHide={() => {
            setDuplicateUsers(undefined);
          }}
        >
          <Modal.Header>
            <Modal.Title>Duplicate Users</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <table className="table">
                  <thead>
                    <tr>
                      <td>
                        <b>ID</b>
                      </td>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>
                        <b>Role</b>
                      </td>
                      <td>
                        <b>Email</b>
                      </td>
                      <td>
                        <b>Phone</b>
                      </td>
                      <td>
                        <b>Site</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {duplicateUsers?.map((dupUser, index) => (
                      <tr key={dupUser.id}>
                        <td>{dupUser.id}</td>
                        <td>{`${dupUser.firstName} ${dupUser.lastName}`}</td>
                        <td>{dupUser.userType}</td>
                        <td>{dupUser.email}</td>
                        <td>{dupUser.phone}</td>
                        <td>{dupUser.site_id}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="row">
          <div className="col-md-6">
            <h3>Duplicate Emails</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Site ID</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {duplicateEmails.map((email, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        getDuplicateUsers(email.email);
                      }}
                    >
                      <td>
                        {email.email} * {email.count}
                      </td>
                      <td>{email.site_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <h3>Duplicate Phones</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Phone</th>
                  <th>Site ID</th>
                </tr>
              </thead>
              <tbody>
                {duplicatePhones.map((phone, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        getDuplicateUsers(phone.phone);
                      }}
                    >
                      <td>
                        {phone.phone} * {phone.count}
                      </td>
                      <td>{phone.site_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
