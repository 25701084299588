/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { IAirline } from "redux/reducers/flightApi.reducer";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DiscountMarkupTextField } from "./components/DiscountMarkupTextField";
import { useState } from "react";
import { IAgentGroup, IAirlineDiscount, IDiscountObject } from "./types";
import { Autocomplete } from "@material-ui/lab";
import { Radio } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { flightAxios } from "./commonFlightAjax";
import { FullScreenLoader } from "./components/FullScreenLoader";
import { getWhitelabelGroups } from "apiCalls/whitelabelDb";
import { ERROR_MESSAGES, isInvalidField } from "./tools/utils";
import { ErrorHelperList } from "./components/ErrorHelperText";
import { AirlineLogo } from "./components/AirlineLogo";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        // width: 200,
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

interface IDiscountFormState {
  airlineId: string;
  YQPercent: number;
  YRPercent: string;
  additionalDiscountPercent: number;
  agentGroupId: string;
  basicFarePercent: number;
  userGroupId: string | undefined;
  isDefault: boolean;
  isInternational: boolean;
}

export const DiscountSettings: React.FC<{
  airlines: Array<IAirline>;
}> = (props) => {
  const classes = useStyles();

  const initialFormState: IDiscountObject = {
    airlineId: { value: "", error: [], validation: ["required"] },
    YQPercent: { value: 0, error: [], validation: ["required"] },
    YRPercent: { value: 0, error: [], validation: ["required"] },
    additionalDiscountPercent: {
      value: 0,
      error: [],
      validation: ["required"],
    },
    agentGroupId: { value: "", error: [], validation: ["required"] },
    basicFarePercent: {
      value: 0,
      error: [],
      validation: ["required"],
    },
    userGroupId: { value: "", error: [], validation: [] },
    isDefault: { value: false, error: [], validation: ["required"] },
    isInternational: { value: false, error: [], validation: ["required"] },
  };

  const [formState, setFormState] = useState<IDiscountObject>(initialFormState);

  const [loadingState, setLoadingState] = useState(false);
  const [airlinesDiscountState, setAirlinesDiscountState] = useState<
    Array<IAirlineDiscount>
  >([]);
  const [selectedAirlineState, setSelectedAirlineState] = useState<
    | IAirline
    | {
        text: any;
      }
    | undefined
  >({ text: "" });
  const [agentGroupsState, setAgentGroupsState] = useState<Array<IAgentGroup>>(
    []
  );
  const [selectedAgentGroup, setSelectedAgentGroup] = useState<
    | IAgentGroup
    | {
        groupName: any;
      }
    | undefined
  >({ groupName: "" });

  useEffect(() => {
    getAllAirlineDiscounts();
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    setSelectedAgentGroup(
      agentGroupsState.find(
        (agent) =>
          agent.id.toString() === formState.agentGroupId.value.toString()
      )
    );
  }, [formState.agentGroupId]);

  useEffect(() => {
    setSelectedAirlineState(
      props.airlines.find((air) => air._id === formState.airlineId.value)
    );
  }, [formState.airlineId]);

  const handleAirlineChange = (e, value) => {
    if (value) {
      setFormState({
        ...formState,
        airlineId: {
          ...formState.airlineId,
          value: value._id,
          error: [],
        },
      });
    }
  };

  const handleAgentGroupChange = (e, value) => {
    if (value) {
      setFormState({
        ...formState,
        agentGroupId: {
          ...formState.agentGroupId,
          value: value.id,
          error: [],
        },
      });
    }
  };

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: {
        ...formState[e.target.name],
        value: e.target.value,
        error: [],
      },
    });
  };

  // const handleDefaultChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //     setFormState({
  //         ...formState,
  //         isDefault: {
  //             ...formState.isDefault,
  //             value: checked,
  //             error: []
  //         }
  //     })
  // }

  const getAllAirlineDiscounts = async () => {
    let discountResponse: AxiosResponse;
    let agentGroupsResponse;
    setLoadingState(true);
    try {
      discountResponse = await flightAxios({
        // url: `getAllAirlineDiscounts`,
        // method: "POST",
        url: `airlineDiscount`,
        method: "GET",
      });

      agentGroupsResponse = await getWhitelabelGroups();
    } catch (error) {
      toast.error("Error connecting to server. ");
      return;
    }
    if (agentGroupsResponse.success) {
      setAgentGroupsState(agentGroupsResponse.result);
    }

    if (discountResponse.data) {
      setAirlinesDiscountState(discountResponse.data.result);
    }
    setLoadingState(false);
  };

  const handleDomesticChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let isInternational: boolean = value === "true" || value === "1";
    setFormState({
      ...formState,
      isInternational: {
        ...formState.isInternational,
        value: isInternational,
        error: [],
      },
    });
  };

  const handleDiscountEditBtn = (discountRowIndx) => {
    let formData = { ...formState };

    Object.keys(formData).forEach((key) => {
      formData[key] = {
        ...formData[key],
        value: airlinesDiscountState[discountRowIndx][key],
      };
    });

    formData.agentGroupId.value =
      airlinesDiscountState[discountRowIndx].agentGroupId.id.toString();

    setFormState(formData);
  };

  const handleDiscountDeleteBtn = async (discountMapId) => {
    setLoadingState(true);

    try {
      let deleteResponse = await flightAxios({
        method: "DELETE",
        url: "airlineDiscount",
        data: {
          discountId: discountMapId,
        },
      });

      if (deleteResponse.data.success) {
        toast.success("Discount deleted successfully");
      } else {
        throw new Error();
      }
    } catch (error: any) {
      toast.success(error?.message);
    }

    await getAllAirlineDiscounts();
    setLoadingState(false);
  };

  const handleDiscountSave = async () => {
    setLoadingState(true);

    let formData: IDiscountFormState = {
      YQPercent: 0,
      YRPercent: "",
      additionalDiscountPercent: 0,
      basicFarePercent: 0,
      airlineId: "",
      isDefault: false,
      isInternational: false,
      agentGroupId: "",
      userGroupId: undefined,
    };

    let newFormDataState = { ...formState };

    let errorExists = false;
    Object.keys(newFormDataState).forEach((fieldKey) => {
      if (newFormDataState[fieldKey].value !== "") {
        formData[fieldKey] = newFormDataState[fieldKey].value;
      } else {
        formData[fieldKey] = undefined;
      }
      if (isInvalidField(newFormDataState[fieldKey])) {
        newFormDataState[fieldKey].error = ["This Field is invalid."];
        errorExists = true;
      }
    });

    setFormState(newFormDataState);

    if (!errorExists) {
      try {
        let saveResponse = await flightAxios({
          method: "POST",
          url: "airlineDiscount",
          data: formData,
        });

        await getAllAirlineDiscounts();

        let backendErrors = saveResponse.data.errors;
        if (backendErrors) {
          let newFormDataState = { ...formState };
          Object.keys(newFormDataState).forEach((fieldKey) => {
            if (backendErrors[fieldKey]) {
              newFormDataState[fieldKey].error = backendErrors[fieldKey];
            }
          });
          setFormState(newFormDataState);
        } else {
          setFormState(initialFormState);
        }
      } catch (error) {
        toast.error(ERROR_MESSAGES.SERVER_TRY_LATER);
      }
    }
    setLoadingState(false);
  };

  return (
    <>
      <FullScreenLoader show={loadingState} />
      <div>
        <form className={classes.root} noValidate autoComplete="off">
          <div className="text-center">
            <div className="my-4">
              <Typography variant="h5">Airline Specific Discount </Typography>
              <Typography variant="caption">Enter percentage only</Typography>
            </div>

            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={formState.isDefault.value} onChange={handleDefaultChange} name="isDefault" />}
                                    label="Is Default ?"
                                />
                            </Grid> */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="isInternational"
                    name="isInternational"
                    value={formState.isInternational.value}
                    onChange={handleDomesticChange}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Domestic"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="International"
                    />
                  </RadioGroup>
                  <ErrorHelperList errors={formState.isInternational.error} />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={agentGroupsState}
                  getOptionLabel={(option) => {
                    if (option) {
                      console.log("getOptionLabel", option);
                      return option.groupName;
                    }
                  }}
                  className="w-100"
                  value={selectedAgentGroup}
                  onChange={handleAgentGroupChange}
                  style={{ width: 270 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Agent Group"
                      variant="outlined"
                      size="small"
                      error={formState.agentGroupId.error.length > 0}
                    />
                  )}
                />
                <ErrorHelperList errors={formState.agentGroupId.error} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  className="w-100"
                  options={props.airlines}
                  getOptionLabel={(option) => option?.text}
                  value={selectedAirlineState}
                  onChange={handleAirlineChange}
                  style={{ width: 270 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="SELECT AIRLINE"
                      variant="outlined"
                      size="small"
                      error={formState.airlineId.error.length > 0}
                    />
                  )}
                />
                <ErrorHelperList errors={formState.airlineId.error} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <DiscountMarkupTextField
                  name="basicFarePercent"
                  label="BASIC FARE Discount"
                  value={formState.basicFarePercent}
                  onChange={handleInputChange}
                  textFieldProps={{
                    type: "number",
                    className: "w-100",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DiscountMarkupTextField
                  name="YQPercent"
                  label="YQ Discount"
                  value={formState.YQPercent}
                  onChange={handleInputChange}
                  textFieldProps={{
                    type: "number",
                    className: "w-100",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DiscountMarkupTextField
                  name="YRPercent"
                  label="YR Discount"
                  value={formState.YRPercent}
                  onChange={handleInputChange}
                  textFieldProps={{
                    type: "number",
                    className: "w-100",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DiscountMarkupTextField
                  name="additionalDiscountPercent"
                  label="Additional Discount"
                  value={formState.additionalDiscountPercent}
                  onChange={handleInputChange}
                  textFieldProps={{
                    type: "number",
                    className: "w-100",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  className="mt-2 mb-5"
                  color="primary"
                  variant="contained"
                  onClick={handleDiscountSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>

            {airlinesDiscountState.map((air, index) => {
              // if (!air.airline_joint || air.airline_joint.length !== 1) {
              //   return null;
              // }

              const airline = props.airlines.find(
                (airline) => airline._id === air.airlineId
              );

              if (!airline) return null;

              return (
                <>
                  <Grid
                    key={air.id}
                    justify="center"
                    alignItems="center"
                    container
                    spacing={2}
                  >
                    <Grid item xs={3} className="text-center">
                      <AirlineLogo logoUrl={airline.logo?.logoUrl} />

                      <p className="bg-dark text-white rounded">
                        {air.agentGroupId.groupName}
                      </p>
                    </Grid>
                    <Grid item xs={3} className="text-center">
                      <p className="mb-0">
                        <small>Basic Fare</small>
                      </p>
                      <p>{air.basicFarePercent}%</p>

                      <p className="mb-0">
                        <small>Additional</small>
                      </p>
                      <p>{air.additionalDiscountPercent}%</p>
                    </Grid>
                    <Grid item xs={2} className="text-center">
                      <p className="mb-0">
                        <small>YQ</small>
                      </p>
                      <p>{air.YQPercent}%</p>

                      <p className="mb-0">
                        <small>YR</small>
                      </p>
                      <p>{air.YRPercent}%</p>
                    </Grid>

                    <Grid item xs={3} className="text-center">
                      <p>
                        {air.isInternational ? "International" : "Domestic"}
                      </p>
                      <p>
                        <Button
                          onClick={() => handleDiscountEditBtn(index)}
                          variant="contained"
                          color="primary"
                          size="small"
                          // className="mt-2"
                        >
                          Edit
                        </Button>
                      </p>
                      <Button
                        onClick={() => handleDiscountDeleteBtn(air.id)}
                        variant="contained"
                        color="primary"
                        size="small"
                        className=" bg-danger"
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                  <hr />
                </>
              );
            })}
          </div>
        </form>
      </div>
    </>
  );
};
