import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { postRequest } from "tools/ajax";
import { currencyType } from "types/wallet.types";

export const ConfirmCurrencyChangeModal: React.FC<{
  temorarerlySelectedCurrency: currencyType | undefined;
  show: boolean;
  onHide: () => any;
  setCurrentCurrency: (selectedCurrency: any) => any;
}> = (props) => {
  const clearCustomerPurchaseDetails = async () => {
    try {
      const res = await postRequest("wallet/clearCustomerPurchaseDetails", {});
      props.setCurrentCurrency(props.temorarerlySelectedCurrency);
      props?.onHide();
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <h3 className="pt-4 ps-4 text-danger">Warning!</h3>

      <p className="px-4 py-2">
        Please note that changing currency at this point will requires you to
        fill in your user details again. Your current details will be cleared.
        Are you sure you want to proceed?
      </p>
      <div className="text-end pe-2 pb-2">
        <button
          onClick={props.onHide}
          className="btn btn-sm btn-outline-secondary mx-2 "
        >
          Close
        </button>
        <button
          onClick={clearCustomerPurchaseDetails}
          className="btn btn-sm btn-success "
        >
          Clear Details
        </button>
      </div>
    </Modal>
  );
};
