import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import moment, { localeData } from "moment";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "tools/ajax";

interface IBillingSlot {
  id: number;
  fromDate: Date;
  toDate: Date;
  remark: string;
  isCompleted: boolean;
}

export const BillingSlots: React.FC<{}> = () => {
  const [slots, setSlots] = useState<IBillingSlot[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const defaultFilterState = {
    fromDate: moment().subtract(1, "days").toDate(),
    toDate: moment().toDate(),
  };
  const [dateFilter, setDateFilter] = useState<{
    fromDate: Date;
    toDate: Date;
  }>(defaultFilterState);

  const [pagination, setPagination] = useState<{
    perPage: number;
    recordsToSkip: number;
    page: number;
    totalRecords: number;
    totalPages: number;
  }>({
    perPage: 10,
    recordsToSkip: 0,
    page: 1,
    totalRecords: 0,
    totalPages: 1,
  });
  useEffect(() => {
    updateBillingSlotStatus();
  }, []);
  useEffect(() => {
    // getBillingSlots();
  }, [dateFilter]);
  const getBillingSlots = async (pageNumber = 1) => {
    // setLoading(true);
    // try {
    //   const res = await getRequest("billing/getBillingSlots", {
    //     dateFilter,
    //     page: pageNumber,
    //   });
    //   if (res?.result?.allBillingSlots) {
    //     setSlots(res?.result?.allBillingSlots);
    //   }
    //   if (res?.result?.pagination) {
    //     setPagination({
    //       ...res.result.pagination,
    //       page: parseInt(res.result.pagination.page),
    //     });
    //   }
    // } catch (error) {
    //   toast.error(error);
    //   console.log(error);
    // }
    // setLoading(false);
  };

  const createBillingSummaryWithDate = async (fromDate: Date, toDate: Date) => {
    // setLoading(true);
    // try {
    //   const res = await postRequest("billing/createBillingSummaryWithDate", {
    //     fromDate,
    //     toDate,
    //   });
    //   if (res?.success) {
    //     updateBillingSlotStatus();
    //   }
    // } catch (error) {
    //   toast.error(error);
    //   console.log(error);
    // }
    // setLoading(false);
  };
  const updateBillingSlotStatus = async () => {
    // setLoading(true);
    // try {
    //   const res = await postRequest(
    //     "billing/manuallyupdateBillingSlotStatus",
    //     {}
    //   );
    //   if (res?.success) {
    //     // getBillingSlots();
    //   }
    //   console.log(res);
    // } catch (error) {
    //   toast.error(error);
    //   console.log(error);
    // }
    // setLoading(false);
  };
  return (
    <div className="container">
      <div className="alert alert-warning" role="alert">
        This page is under-maintenance after the monthly billing is shifted to
        daily billing
      </div>
      <FullScreenLoader show={loading} />
      <div className="my-3 d-flex ">
        <b>Billing Status (All sites)</b>
        <span className="border rounded   me-2 rounded ">
          <ReactDatePicker
            className="date-filter"
            maxDate={dateFilter.toDate}
            onChange={(date) => {
              setDateFilter({
                ...dateFilter,
                fromDate: moment(date).toDate(),
              });
            }}
            customInput={
              <button className="btn ">
                From
                <br /> <i className="fas fa-calendar-alt h5"></i>
                {moment(dateFilter.fromDate).format("ll")}
              </button>
            }
            selected={dateFilter.fromDate}
          />
        </span>
        <span className="border rounded   me-2 rounded ">
          <ReactDatePicker
            className="date-filter "
            onChange={(date) => {
              setDateFilter({
                ...dateFilter,
                toDate: moment(date).toDate(),
              });
            }}
            maxDate={moment().toDate()}
            minDate={dateFilter.fromDate}
            customInput={
              <button className="btn ">
                To
                <br /> <i className="fas fa-calendar-alt h5"></i>{" "}
                {moment(dateFilter.toDate).format("ll")}
              </button>
            }
            selected={dateFilter.toDate}
          />
        </span>
      </div>
      <div className="row bg-light p-2">
        <div className="col-1">
          <b>Index</b>
        </div>
        <div className="col-3">
          <b>Date</b>
        </div>
        <div className="col-3">
          <b>Time Slot</b>
        </div>
        <div className="col-2">
          <b>Remark</b>
        </div>
        <div className="col-3">
          <b>Status</b>
        </div>
      </div>
      {slots &&
        slots?.map((slot, key) => {
          return (
            <div className="row border mb-2 py-3 align-items-center" key={key}>
              <div className="col-1">
                {" "}
                {key + 1 + (pagination.page - 1) * 10}.
              </div>

              <div className="col-3 small">
                {moment(slot?.fromDate).format("DD-MMMM-YYYY")}
              </div>
              <div className="col-3 small text-decoration-underline">
                <Link
                  to={`/admin/accounts/billing_summary?fromDate=${slot?.fromDate}&toDate=${slot?.toDate}`}
                >
                  {moment(slot?.fromDate).format("h:mm A")} -{" "}
                  {moment(slot?.toDate).format("h:mm A")}{" "}
                  <i className="fas fa-external-link-alt"></i>
                </Link>
              </div>
              <div className="col-2 small">{slot?.remark}</div>

              <div className="col-1">
                {slot.isCompleted ? (
                  <b className="text-success small">Completed</b>
                ) : (
                  <b className="text-danger small">Pending</b>
                )}
              </div>
              <div className="col-2 text-end">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    if (slot?.isCompleted) {
                      toast.warning(
                        "billing is alredy completed for this time slot"
                      );
                      return;
                    }
                    createBillingSummaryWithDate(slot?.fromDate, slot?.toDate);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          );
        })}

      <div className="p-1 d-flex justify-content-center my-2 ">
        {pagination.page !== 1 && (
          <div
            className="btn btn-outline-dark"
            onClick={() => {
              if (pagination.page > 1) {
                // getBillingSlots(pagination.page - 1);
              }
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </div>
        )}
        <div className="mx-4 py-2">{`${pagination.page} of ${pagination.totalPages} Pages - ${pagination.totalRecords} Records`}</div>
        {pagination.totalPages > 1 &&
          pagination.page !== pagination.totalPages && (
            <div
              className="btn btn-outline-dark"
              onClick={() => {
                if (pagination.page === pagination.totalPages) {
                  return;
                }
                // getBillingSlots(pagination.page + 1);
              }}
            >
              <i className="fas fa-chevron-right"></i>
            </div>
          )}
      </div>
    </div>
  );
};
