import React, { useEffect, useState } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import gjsPresetWebpage from "grapesjs-preset-webpage";
import Loader from "tools/Loader";
import {
  addHomePageBuilderApiCall,
  getHomePageBuilderCodeApiCall,
} from "apiCalls/siteSettingsDb";
import { toast } from "react-toastify";

export const PageBuilder = () => {
  const [editorState, setEditorState] = useState<any>(null);
  const [isRemoteCodeUpdated, setIsRemoteCodeUpdated] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  //   const saveEditor = async () => {
  //     const data = await axios({
  //         editorState.runCommand("gjs-save-raw-content");
  //   }

  useEffect(() => {
    // getHomePageBuilderCode();
    // const editor = grapesjs.init({
    //   container: "#gjs",
    //   fromElement: true,
    //   height: "100%",
    //   plugins: [gjsPresetWebpage],
    //   stylePrefix: "gjs-",
    //   storageManager: {
    //     id: "gjs-",
    //     type: "local",
    //     autosave: true,
    //     autoload: true,
    //     stepsBeforeSave: 1,
    //     storeComponents: true,
    //     storeStyles: true,
    //     storeHtml: true,
    //     storeCss: true,
    //     storeSvg: true,
    //     storeGjs: true,
    //     loadCallback() {
    //       console.log("Loaded");
    //     }
    //   },
    //   assetManager: {
    //     upload: {
    //       url: "/upload",
    //       params: {
    //         _token: document.querySelector("meta[name=csrf-token]")!.getAttribute("content")
    //       }
    //     },
    //     assets: [
    //       {
    //         src: "/images/grapesjs-logo.png",
    //         height: "50px",
    //         width: "50px",
    //         title: "GrapesJS",
    //         type: "image"
    //       }
    //     ]
    //   },
    //   commands: {
    //     save: {
    //       run(editor: any) {
    //         const data = editor.runCommand("gjs-save-raw-content");
    //         addHomePageBuilderApiCall(data);
    //       }
    //     }
    //   }
    // });
  }, []);

  const saveEditor = async (event) => {
    setLoadingState(true);
    const cssCode = editorState.getCss();
    const htmlCode = editorState.getHtml();
    const pageBuilderCodeUpdate = await addHomePageBuilderApiCall({
      css: cssCode,
      html: htmlCode,
    });
    if (pageBuilderCodeUpdate) {
      setLoadingState(false);
      toast.error("Homepage code successfully saved.");
    }
  };

  const getHomePageBuilderCode = async () => {
    const data = await getHomePageBuilderCodeApiCall();
    if (editorState && !isRemoteCodeUpdated) {
      setIsRemoteCodeUpdated(true);
      editorState.setComponents(data.html);
      editorState.setStyle(data.css);
    }
  };

  useEffect(() => {
    if (editorState && !isRemoteCodeUpdated) {
      getHomePageBuilderCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState, isRemoteCodeUpdated]);

  useEffect(() => {
    const editor = grapesjs.init({
      container: "#editor",
      plugins: [gjsPresetWebpage],
      pluginsOpts: {
        "gjs-preset-webpage": {
          // modalImportUrl: 'https://www.grapesjs.com/examples/assets/modal-import.html',
          //     blocksBasicOpts: {
          //         defaultType: 'text',
          //         allowedBlocks: [
          //             'text',
          //             'image',
          //             'columns',
          //             'button',
          //             'video',
          //             'map',
          //             'heading',
          //             'divider',
          //             'alert',
          //             'icon',
          //             'list',
          //             'table',
          //             'label',
          //             'code',
          //             'quote',
          //             'spacer',
          //             'separator',
          //             'social',
          //             'embed',
          //             'rawHtml',
          //             'html',
          //             'link',
          //             'video',
          //             'map',
          //         ]
          //     }
          // }
        },
      },
    });
    setEditorState(editor);
    getHomePageBuilderCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {loadingState && <Loader />}
      <button
        disabled={loadingState}
        className="btn btn-primary fixed-bottom-center"
        onClick={saveEditor}
      >
        Save Design
      </button>
      <div id="editor"></div>
    </div>
  );
};
