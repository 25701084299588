import { CommonConstants } from "constants/common.constants";
import React from "react";
import { Form } from "react-bootstrap";
import IntlTelInput, { CountryData } from "react-intl-tel-input";

export const PhoneInput: React.FC<{
  label: string;
  defaultValue?: string;
  value?: string;
  phoneNumberIsValid?: boolean;
  handleChange: (
    isValid: boolean,
    value: string,
    selectedCountryData: CountryData,
    fullNumber: string
    // extension: string
  ) => void;
}> = (props) => {
  return (
    <>
      <Form.Group className="text-start mb-2">
        <Form.Label className="mb-0 text-muted ">{props.label} </Form.Label>

        <IntlTelInput
          preferredCountries={CommonConstants.prefferedCountriesIntlInput}
          excludeCountries={CommonConstants.excludedCountriesIntlInput}
          containerClassName="intl-tel-input w-100"
          inputClassName={`form-control m-0 w-100 ${
            props.phoneNumberIsValid ? "" : "border-danger"
          }`}
          onSelectFlag={(currentNumber, countryData, fullNumber, isValid) => {
            const dialCode = countryData.dialCode;
            props.handleChange(
              isValid,
              currentNumber,
              { dialCode },
              fullNumber
            );
          }}
          // defaultValue={props.defaultValue}
          customPlaceholder={() => props.label}
          onPhoneNumberChange={props.handleChange}
          formatOnInit={false}
          value={props.value}
        />
      </Form.Group>
    </>
  );
};
