import React from "react";

import PropTypes from "prop-types";

function SimpleTable(props) {
  const { headerCells, rowsArr } = props;
  return (
    <>
      <div>
        <table className="table shadow text-center">
          <thead className="bg-dark text-light ">
            <tr className="fw-bold">
              {headerCells.map((cell, i) => (
                <td key={i}>{cell}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowsArr.map((row, rId) => (
              <tr key={rId}>
                {row.map((cell, cId) => (
                  <td key={cId}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

SimpleTable.propTypes = {
  headerCells: PropTypes.array.isRequired,
  rowsArr: PropTypes.array.isRequired,
};

export default SimpleTable;
