import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useAppSelector } from "hooks/reduxHooks";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

export default function MainMenu(props) {
  const sippiCustomer = useAppSelector(
    (state) => state.sippiCustomer?.customer
  );
  const classes = useStyles();
  const [wlPurchased, setWlPurchased] = useState(false);

  const siteData = useAppSelector((state) => state?.siteSettings?.siteData);

  useEffect(() => {
    if (sippiCustomer?.purchases?.isPaid) {
      setWlPurchased(true);
    } else {
      setWlPurchased(false);
    }
  }, [siteData, sippiCustomer]);

  const printItem = (name, path) => (
    <Link to={path} className="routerLink" onClick={() => props.close()}>
      <ListItem button>
        <ListItemText>{name}</ListItemText>
      </ListItem>
    </Link>
  );
  return (
    <React.Fragment>
      <Drawer anchor="right" open={props.open} onClose={() => props.close()}>
        {/* <p>{`id = ${siteData._id} userName = ${user.name}`}</p> */}
        <List className={classes.list}>
          {!sippiCustomer?.googlePersonObj.email &&
            printItem("Login", "/login")}
          {printItem("Home", "/")}
          {wlPurchased && printItem("Website Settings", "/user/dashboard")}
          {wlPurchased && printItem("Manage Staff", "/user/manage_staff")}
          {wlPurchased && printItem("Email Settings", "/user/email_settings")}
          {wlPurchased && printItem("Bank Details", "/user/bank_details")}
          {wlPurchased &&
            printItem("DMC Settings", "/user/dmc_module_settings")}
          {wlPurchased && printItem("Look to Book", "/user/look_to_book")}
          {wlPurchased && printItem("Billing", "/user/billing")}
          {wlPurchased &&
            printItem("Advance Settings", "/user/advance_settings")}
          {wlPurchased && printItem("Homepage Builder", "/user/page_builder")}
          {/* TODO */}
          {wlPurchased && printItem("User Wallet", "/user/user_wallet")}
          {wlPurchased && printItem("Add-ons", "/user/addons")}
          {/* //TODO uncomment when isActive is working in main app */}
          {wlPurchased && printItem("API Settings", "/user/apiSettings")}
          {/* {wlPurchased &&
            siteData.domain === "packagecart.in" &&
            printItem("Api Credentials", "/user/api_credentials")} */}
        </List>
      </Drawer>
    </React.Fragment>
  );
}
