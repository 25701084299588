import React from "react";
import TextInp from "./TextInp";
export default function index(props) {
  return (
    <>
      <div className="mb-3">
        <TextInp {...props} />
      </div>
    </>
  );
}
