import { useAppDispatch } from "hooks/reduxHooks";
import React, { useEffect } from "react";

import { fetchWlMeta } from "redux/slices/wlMeta.slice";
import { GoogleAuth } from "./GoogleAuth";
import { GTagManager } from "./GTagManager";
import { FacebookAuthSetting } from "./FacebookAuthSetting";

export const UserAdvanceSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchWlMeta());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container d-flex justify-content-between flex-column">
        <p className="h4 fw-bold m-4">Advance Settings</p>
        {/* <div className="shadow row mb-4">
          <div className="col-sm-6 p-2">
            <div
              className="d-flex align-items-center justify-content-center "
              style={{ height: "100%" }}
            >
              <button
                className="btn btn-primary"
                onClick={() => {
                  history.push("/user/dynamic_web_pages");
                }}
              >
                <i className="fas fa-tools fs-5"></i> Dynamic Web Pages
              </button>
            </div>
          </div>
          <div className="col-sm-6">
            <p>
              This feature empowers you to manage the whole code of your website
              by yourself. You can create a unique look and feel for your
              website as per your choice.
            </p>
            <img
              src="https://via.placeholder.com/350x200?text=youtube_video"
              alt="placeholder for youtube video"
              className="img img-responsive mb-3"
            />
          </div>
        </div> */}
        <GTagManager />
        <GoogleAuth />
        <FacebookAuthSetting />
      </div>
    </>
  );
};
