import { useDispatch } from "react-redux";
import { PhoneInput } from "./PhoneInput";
import { MasterApi } from "apiCalls/masterDb";
import { toast } from "react-toastify";
import { CountryType } from "types/common.types";
import { IForm } from "components/user/dashboard/brandingSettings/brandingSettingsInterfaces";
import { IpurchaseForm } from "commonFnBcode/types/whitelabel.types";
import { currencySigns, currencyType } from "types/wallet.types";
import { Link } from "react-router-dom";
import { ChangeEvent } from "react";

export const TravelWebsitePurchaseForm: React.FC<{
  phoneNumberIsValid?: boolean;
  SET_LOADER: (boolean) => any;
  setCountries: (boolean) => void;
  countries: { label: string; value: any }[] | undefined;
  form: IpurchaseForm;
  currency: currencyType | undefined;
  updateForm: (e: any) => void;
  // handleCountryChange: (e: any, data: any) => void;
  handlePrimaryPhoneChange: (
    isValid: any,
    value: any,
    selectedCountryData: any,
    fullNumber: any
  ) => void;
  verifiedPanNo: boolean;
  panNo: string | undefined;
  setPanNo: (arg: string) => void;
  verifyPanNo: () => Promise<boolean | undefined>;
}> = (props) => {
  const printTextInput = (label, name, isRequired = true) => (
    <div className="text-start mb-2">
      <label className="mb-0 text-muted ">{label}</label>
      <input
        className="form-control"
        name={name}
        required={isRequired}
        value={props.form[name]}
        onChange={(e) => {
          props.updateForm((old: IForm) => {
            return {
              ...old,
              [name]: e.target.value,
            };
          });
        }}
      />
    </div>
  );

  const selectInput = (props: {
    label: string;
    name: string;
    value: string;
    required?: boolean;
    options:
      | Array<{
          label: string;
          value: any;
        }>
      | undefined;
    handleChange: any;
    updateForm: (e: any) => void;
    form?: IpurchaseForm;
  }) => (
    <div className="text-start mb-2">
      <label className="mb-0 text-muted ">
        {props.label}
        {props.required && <span className="text-danger">*</span>}
      </label>
      <select
        className="form-select"
        value={props.value}
        onChange={({ target }) => {
          props.updateForm({
            ...props.form,
            [target.name]: target.value,
          });
        }}
        name={props.name}
        required={props.required}
      >
        {props?.options?.length
          ? props?.options?.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))
          : []}
      </select>
    </div>
  );

  const getCountries = async () => {
    props.SET_LOADER(true);
    const response = await MasterApi.getCountries();
    if (!response?.success || !response?.result)
      return toast.error(response?.message || "Could not get countries");
    const countriesArr = response.result.map((country: CountryType) => ({
      label: `${country.countryName} (${country.countryCode})`,
      value: country.countryCode,
    }));
    props.setCountries(countriesArr);
    props.SET_LOADER(true);
  };

  return (
    <div className="payment-form-box row border rounded mt-1">
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {printTextInput("Company Name", "companyName")}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {printTextInput("Billing Name", "billingName")}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {printTextInput("Email", "email")}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        <PhoneInput
          phoneNumberIsValid={props.phoneNumberIsValid}
          value={props.form.phoneNumber}
          label="Phone Number"
          defaultValue={props.form.phoneNumber}
          handleChange={props.handlePrimaryPhoneChange}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {selectInput({
          label: "Country",
          name: "country",
          value: props.form.country,
          options: props.countries,
          required: true,
          handleChange: (val) => {
            console.log(val);
          },
          updateForm: props.updateForm,
          form: props.form,
        })}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {printTextInput("Pin Code", "pin")}
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        {props.form.country === "IN" && (
          <>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                {printTextInput("GST", "gst", false)}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                {printTextInput("State", "state")}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {printTextInput("Address Line 1", "address1")}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {printTextInput("Address Line 2", "address2", false)}
      </div>

      {props?.currency === currencyType.INR && (
        <>
          {" "}
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-start ">
            <label htmlFor="" className="">
              Pan card No
            </label>

            <input
              type="text"
              className="form-control"
              value={props?.form?.panCardNo}
              onChange={(e) =>
                props.updateForm({ ...props.form, panCardNo: e.target.value })
              }
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-end  ">
            {/* {props?.form?.panCardName && (
              <button
                disabled
                type="button"
                className={`btn w-100 ${
                  props.form.panCardNo
                    ? "btn-outline-success disabled"
                    : "btn-outline-primary"
                } mt-2 `}
                onClick={async () =>
                  (await props.verifyPanNo())
                    ? toast.success("pan card verified")
                    : null
                }
              >
                {props?.form?.panCardName ? (
                  <>
                    {props?.form?.panCardName} <i className="fas fa-check"></i>
                  </>
                ) : (
                  ""
                )}
              </button>
            )} */}
          </div>
          <div className="col-lg-12 col-md-6 col-sm-12 col-12 align-self-center mt-2">
            <div className="text-start alert alert-warning ps-6">
              <div className="">
                <i className="fas fa-exclamation-triangle"></i> PAN card number
                is mandatory for users with "INR Currency".
              </div>
            </div>
          </div>{" "}
        </>
      )}

      <div className="text-start d-flex align-items-end">
        <div>
          <input
            className="form-check-input m-0 me-2"
            type="checkbox"
            required
          />
        </div>
        <div>
          I agree to the &nbsp;
          <Link to="/terms-and-conditions" target="_blank">
            Terms & Conditions
          </Link>
        </div>
      </div>
    </div>
  );
};
