import { AxiosResponse } from "axios";
import { useAppSelector } from "hooks/reduxHooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { sippiCustomerSlice } from "redux/slices/sippiCustomer.slice";
import { postRequest } from "tools/ajax";
import { IJSONResponse } from "types/common.types";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const EaseBuzzPaymentGateway: React.FC<{
  closeGateway: Function;
  setLoading: (loading: boolean) => void;
  handleSuccessPayment: (paymentRes: any) => void;
  handleFailedPayment: () => void;
  orderId: string;
}> = (props) => {
  useEffect(() => {
    loadEasebuzz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();
  const user = useAppSelector(
    (state) => state.sippiCustomer.customer?.purchases?.form
  );

  const loadEasebuzz = async () => {
    // toast.success("easebuzz triggered");
    try {
      await loadScript(
        "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js"
      );
      let {
        accessKey,
        hash,
        transactionId,
        key,
        env,
        totalAmount,
        productinfo,
      } = await createEasebuzzOrder();

      const win: any = window;
      var easebuzzCheckout = new win.EasebuzzCheckout(key, env);
      var options = {
        access_key: accessKey, // access key received via Initiate Payment
        onResponse: (response) => {
          verifyEasebuzzPayment({
            ...response,
            transactionId,
            firstName: user?.billingName,
          });
          props.setLoading(false);
        },
        theme: "#000", // color hex
        amount: totalAmount,
        txnid: transactionId,
        key: key,
        productinfo,
        firstname: user?.billingName,
        phone: user?.phoneNumber,
        email: user?.email,
        // surl: "http://localhost:3000/success",
        // furl: "http://localhost:3000/fail",
        hash,
      };

      easebuzzCheckout.initiatePayment(options);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const verifyEasebuzzPayment = async (response) => {
    props.setLoading(true);
    if (response?.status === "success") {
      // toast.success("easebuzz payment verified");
      const easebuzzRes = await postRequest("payment/easebuzzVerifyPayment", {
        ...response,
        orderId: props.orderId,
      });

      if (!easebuzzRes?.success) {
        // toast.error(easebuzzRes.message);
        toast.error(easebuzzRes?.errorMessage || "something went wrong");
      } else {
        toast.success("Payment successful");
        props.handleSuccessPayment(easebuzzRes);
      }
    } else {
      toast.error("Payment failed");
      props.handleFailedPayment();
    }
    props.setLoading(false);
  };

  const createEasebuzzOrder = async () => {
    props.setLoading(true);
    const easebuzzRes: IJSONResponse<{
      accessKey: string;
      hash: string;
      transactionId: string;
      key: string;
      env: string;
      totalAmount: number;
      productinfo: string;
    }> = await postRequest("payment/easebuzzCreateOrder", {
      // returnUrl: window.location.href.split("?")[0], // Removing query string from url
      orderId: props.orderId,
      // returnUrl: window.location.href,
    });

    if (!easebuzzRes.success) {
      // toast.error(easebuzzRes.message);
      throw new Error(easebuzzRes.message);
    }

    props.setLoading(false);
    return {
      accessKey: easebuzzRes.result.accessKey,
      hash: easebuzzRes.result.hash,
      transactionId: easebuzzRes.result.transactionId,
      key: easebuzzRes.result.key,
      env: easebuzzRes.result.env,
      totalAmount: easebuzzRes.result.totalAmount,
      productinfo: easebuzzRes.result.productinfo,
    };
  };

  return <></>;
};
