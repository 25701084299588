const defaultStyles: any = {
  minWidth: "100px",
};
export const col = (cellValue, style = defaultStyles) => ({
  cellValue,
  style,
});
export const saleColumns = [
  col("Creation Date"),
  col("Domain", {
    ...defaultStyles,
    maxWidth: "300px",
    minWidth: "200px",
  }),
  col("Company Name"),
  col("Plan Name"),
  col("DB Payment Status"),
  col("Amount"),
  col("GST Number"),
  col("State"),
  col("Actual Payment Status"),
  col("Actual Amount Paid"),
];
