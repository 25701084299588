import React, { useEffect, useState } from "react";

import {
  getAccountsReportForWlApiCall,
  // getlook2BookReportApiCall,
  verifyBillingPaymentApiCall,
} from "apiCalls/siteSettingsDb";

// import _ from "lodash";
import moment from "moment";
import Loading from "../common/loading";
// import exportFromJSON from "export-from-json";

import { toast } from "react-toastify";

import { Link, useLocation } from "react-router-dom";
import {
  // IPendingPaymentBreakup,
  PendingPaymentBreakup,
} from "../lookToBookRatio/pendingPaymentBreakup";
import { IInvoice } from "types/accounts.types";
import queryString from "query-string";

export const SippiBilling = () => {
  // const [lookCount, setLookCount] = useState<number>();

  const location = useLocation();

  const parsedParams = queryString.parse(location.search);

  const [selectedMonthPayment, setSelectedMonthPayment] = useState<IInvoice>();

  const [loadingState, setLoadingState] = useState(false);

  const [invoiceData, setInvoiceData] = useState<Array<IInvoice>>();

  const getInvoiceData = async () => {
    const invoiceResponse = await getAccountsReportForWlApiCall();

    if (invoiceResponse.success && invoiceResponse.result) {
      setInvoiceData(invoiceResponse.result.billingData);
    }
  };

  // Verifying Cashfree Payment
  const checkForCashfreePayment = async (order_id) => {
    setLoadingState(true);

    const paymentRes = await verifyBillingPaymentApiCall({ orderId: order_id });

    if (paymentRes.success) {
      toast.success("Payment Verified Successfully");

      getInvoiceData();

      // setDoPaymentNowState(true);
      // getUserBalance();
    } else {
      toast.error("Could not verify payment... Please contact support!");
    }

    setLoadingState(false);
  };

  useEffect(() => {
    getInvoiceData();
  }, []);

  // Checking If Cashfree Payment success or not
  useEffect(() => {
    if (parsedParams.order_id) {
      checkForCashfreePayment(parsedParams.order_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedParams.order_id]);

  const convertToFloat = (amount) => {
    if (typeof amount == "string") {
      return parseFloat(amount);
    } else return amount;
  };
  return (
    <>
      <Loading loading={loadingState} />
      <div className="my-4">
        <div>
          <h2 className="display-6 text-center">All Invoices</h2>
        </div>
        <div>
          <>
            {selectedMonthPayment && (
              <PendingPaymentBreakup
                onHide={() => {
                  setSelectedMonthPayment(undefined);
                }}
                paymentBreakup={selectedMonthPayment}
              />
            )}

            <div className="container">
              <div className="text-end">
                <Link to="/user/user_wallet">
                  <button className="btn btn-success my-2">
                    Pay All Bills
                  </button>
                </Link>
              </div>
              <div className="row mb-4">
                <div className="col-12 ">
                  <ul className="list-group ">
                    {invoiceData?.map((item, index) => {
                      let { subtotal, tax, discount } = item;

                      let totalPayable =
                        convertToFloat(subtotal) +
                        convertToFloat(tax) -
                        convertToFloat(discount);

                      return (
                        <li
                          key={index}
                          onClick={() => {
                            setSelectedMonthPayment(item);
                          }}
                          className="list-group-item d-flex justify-content-between align-items-start py-3"
                        >
                          <div className="ms-2 me-auto">
                            <span className="badge bg-secondary">
                              #{item.id}
                            </span>{" "}
                            {item.currency} {totalPayable} For Month of{" "}
                            {moment(item.monthYear, "YYYY-MM").format(
                              `MMM YYYY`
                            )}{" "}
                          </div>
                          <h3 className="mt-0">
                            {item.isPaid ? (
                              <span className="badge bg-success rounded-pill">
                                Paid
                              </span>
                            ) : (
                              // <button
                              //   onClick={() => {
                              //     setSelectedMonthPayment(item);
                              //   }}
                              //   className="btn btn-primary"
                              // >
                              //   Pay Now
                              // </button>
                              <span className="badge bg-danger rounded-pill">
                                Pending
                              </span>
                            )}
                          </h3>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="alert alert-warning" role="alert">
                Invoices dated before November 2023 can be viewed from this
                page. Bills after November 2023 will be paid using new prepaid
                billing system, click{" "}
                <Link
                  className="text-decoration-underline"
                  to="/user/user_wallet"
                >
                  here
                </Link>{" "}
                to check your current usage.
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};
