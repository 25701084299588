import React from "react";
import WhitelabelMenu from "components/admin/whiteLabel/whitelabelMenu";
import AdminRoutes from "components/routes/AdminRoutes";
import CreateWhitelabel from "components/admin/whiteLabel/editWhitelabel/EditWhitelabel";
import ViewWhitelabel from "components/admin/whiteLabel/viewWhitelabel/index";
import Users from "./users/Users";
import "assets/css/admin/whitelabel/viewWhitelabel.css";
import { WlPgList } from "./wlPgList/WlPgList";
import { EditWLApiIntegrations } from "./viewWhitelabel/EditWLApiIntegrations";
import { AppJSONData } from "../AppJSONData/AppJSONData";
export default class WhiteLabel extends React.Component {
  render() {
    return (
      <React.Fragment>
        <WhitelabelMenu />

        <AdminRoutes
          exact
          path="/admin/whiteLabel"
          component={ViewWhitelabel}
          noMenu={true}
        />
        <AdminRoutes
          exact
          path="/admin/whiteLabel/create"
          component={CreateWhitelabel}
          noMenu={true}
        />
        <AdminRoutes
          path="/admin/whiteLabel/editwlapiintegrations"
          component={EditWLApiIntegrations}
          noMenu={true}
        />
        <AdminRoutes
          exact
          path="/admin/whitelabel/payment-gateway-list"
          component={WlPgList}
          noMenu="true"
        />
        <AdminRoutes
          exact
          path="/admin/whiteLabel/users/:_id"
          component={Users}
          noMenu={true}
        />
        <AdminRoutes
          exact
          path="/admin/whiteLabel/create/:_id"
          component={CreateWhitelabel}
          noMenu={true}
        />
        <AdminRoutes
          exact
          path="/admin/whiteLabel/appjsondata"
          component={AppJSONData}
          noMenu={true}
        />
      </React.Fragment>
    );
  }
}
