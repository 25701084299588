import { takeLatest, call, put } from "redux-saga/effects";
import { accountsTypes } from "redux/actionTypes/accounts.types";
import {
  getWhitelabelsWithSippiCustomerData as getData,
  updateActualPaymentStatusApi,
} from "apiCalls/accountsDb";
import { setWhitelabelsWithSippiCustomerData } from "redux/actions/accounts.actions";
import { SET_ALERT_MESSAGE, SET_LOADER } from "redux/actions/common.actions";

function* getWhitelabelsWithSippiCustomerData() {
  yield put(SET_LOADER(true));
  const res = yield call(getData);
  yield put(setWhitelabelsWithSippiCustomerData(res));
  yield put(SET_LOADER(false));
}

function* updateActualPaymentStatus({ payload }) {
  const result = yield call(updateActualPaymentStatusApi, payload);
  if (result.err)
    yield put(
      SET_ALERT_MESSAGE({
        message: result.err,
      })
    );
  else {
    yield getWhitelabelsWithSippiCustomerData();
  }
}

export function* watchAccountsActions() {
  yield takeLatest(
    accountsTypes.GET_WHITELABELS_WITH_SIPPI_CUSTOMER_DATA,
    getWhitelabelsWithSippiCustomerData
  );
  yield takeLatest(
    accountsTypes.UPDATE_ACTUAL_PAYMENT_STATUS,
    updateActualPaymentStatus
  );
}
