import React from "react";
import { AirlineSettings } from "./AirlineSettings/ShowAirlineSettings";
import { Grid, Container } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllAirlines } from "redux/actions/flightApi.actions";
import { AirlineDiscountMarkup } from "./AirlineSettings/AirlineDiscountMarkup";

export const FlightModuleSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAirlines());

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="px-0 ">
      {/* grid in parent will control space taken by all child components */}
      <Grid container>
        <Grid item xs={12}>
          <AirlineDiscountMarkup />
          {/* </Grid>
        <Grid item xs={12} md={6}> */}
          <AirlineSettings />
        </Grid>
      </Grid>
    </Container>
  );
};
