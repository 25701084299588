import React from 'react'
import LoginForm from './LoginForm'
export default function adminLogin (props){

    return(
    <>
        <div className="loginGrid">    
            <LoginForm history={props.history} />
        </div>
    </>
    )
}