
import {getRequest,postRequest} from 'tools/ajax'

export const getWhitelabelsWithSippiCustomerData = async () => {
    const r = await getRequest('admin/whitelabel/allForAccountsReport',{})
    return r
}

export const updateActualPaymentStatusApi = async (payload) => {
    return (await postRequest('admin/whitelabel/updateActualPaymentStatus',payload))
}

export const getPaymentGatewayTransactions = async (payload) => {
    return (await postRequest('admin/paymentGatewayTransactions',payload))
}