import {getWhitelabel as getWl} from 'apiCalls/whitelabelDb'

const useWhitelabel = () => {

    const getWhitelabel = async () => {
        const wl = await getWl()
        if(wl.length) return wl[0]
        else return {err: 'no site found!'}
    }

    return {
        getWhitelabel
    }
    
}

export default useWhitelabel