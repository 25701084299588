import {visaActionTypes} from 'redux/actionTypes/visaActionTypes'

export const SET_COUNTRY = (data) => {
    return {
        type: visaActionTypes.SET_COUNTRY,
        payload: data
    }
}

export const SET_VISACATEGORIES = (data) => {
    return {
        type: visaActionTypes.SET_VISACATEGORIES,
        payload: data
    }
}

export const SET_VISACHECKLISTS = (data) => {
    return {
        type: visaActionTypes.SET_VISACHECKLISTS,
        payload: data
    }
}

export const SET_NATIONALITY = (data) => {
    return {
        type: visaActionTypes.SET_NATIONALITY,
        payload: data
    }
}

export const SET_SELECTED_VISACATEGORY = (data) => {
    return {
        type: visaActionTypes.SET_SELECTED_VISACATEGORY,
        payload: data
    }
}

export const GET_ALL_COUNTRIES = (data) => {
    return {
        type: visaActionTypes.GET_ALL_COUNTRIES,
        payload: data
    }
}

export const SET_SELECTED_VISA_CHECKLIST = (data) => {
    return {
        type: visaActionTypes.SET_SELECTED_VISA_CHECKLIST,
        payload: data
    }
}