import { useState, useEffect } from "react";
import Dialog from "commonUi/dialog/Index";
import AutoComplete from "commonUi/autoComplete";
import { searchWhitelabelByDomain } from "apiCalls/whitelabelDb";
import { SupplierManagementApiCalls } from "apiCalls/supplierManagementDb";
import { toast } from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "redux/actions/common.actions";
import { Isupplier, SupplierCategoryEnum } from "./supplierManagement.types";
import { convertEnumToArray } from "tools/commonFuncions";

const SupplierList = () => {
  const [showSupplierListModal, setShowSupplierListModal] = useState(false);

  const [whitelabelsForAutocomplete, setWhitelabelsForAutocomplete] = useState(
    []
  );

  const [searchWhitelabelText, setSearchWhitelabelText] = useState("");
  const [selectedWl, setSelectedWl] = useState<any>(undefined);
  const [SupplierLists, setSupplierLists] = useState<Array<Isupplier>>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const timeOutId = setTimeout(
      () => searchWhitelabel(searchWhitelabelText),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [searchWhitelabelText]);

  useEffect(() => {
    getSupplierLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSupplierLists = async () => {
    const response = await SupplierManagementApiCalls.getSuppliers();
    console.log(response);
    if (response?.success) {
      setSupplierLists(response.result);
    }
  };

  const handleAutocompleteChange = (event, selectedWl) => {
    setSelectedWl(selectedWl);
    console.log(selectedWl);
  };

  const searchWhitelabel = async (searchText) => {
    const searchResults = await searchWhitelabelByDomain({
      domain: searchText,
    });
    console.log({ searchResults });
    setWhitelabelsForAutocomplete(searchResults.whitelabels);
  };

  const makeSupplier = async () => {
    try {
      dispatch(SET_LOADER(true));
      if (!selectedWl?.tempSupplierCategoty)
        return toast.error("Please select a website and a supplier category!");
      const response = await SupplierManagementApiCalls.saveSupplier({
        mongoDb_siteId: selectedWl._id,
        category: selectedWl.tempSupplierCategoty,
        isVisible: true,
      });

      // console.log(response);
      if (!response.success) toast.error(response.errorMessage);
      else {
        toast.success("Supplier created successfully!");
        getSupplierLists();
        closeModal();
      }
      dispatch(SET_LOADER(false));
    } catch (error) {
      console.log(error, "above error in saving supplier");
      dispatch(SET_LOADER(false));
    }
  };

  const closeModal = () => {
    setSelectedWl(undefined);
    setShowSupplierListModal(false);
  };

  const changeVisibilityOfSupplierList = async (event, supplier, index) => {
    dispatch(SET_LOADER(true));
    supplier.isVisible = event.target.checked;
    supplier.mongoDb_siteId = supplier?.mongoDb_siteId?.mongoDb_siteId;
    console.log(supplier);

    const response = await SupplierManagementApiCalls.saveSupplier(supplier);
    if (response.success) {
      toast.success("Visibility Status Changed!");
      getSupplierLists();
    } else {
      toast.error("Could not change visibility Status!");
    }
    dispatch(SET_LOADER(false));
    console.log({ response });
  };

  const handleNewSupplierCategoryChange = (val) => {
    setSelectedWl({
      ...selectedWl,
      tempSupplierCategoty: val,
    });
  };
  return (
    <div>
      <Dialog
        title="Create New Supplier"
        isOpen={showSupplierListModal}
        handleClose={closeModal}
      >
        <div className="d-flex justify-content-center">
          <AutoComplete
            getOptionLabel={(option) => option.domain}
            label="Search Whitelabel/Site"
            onChange={handleAutocompleteChange}
            onInputChange={(event, text) => {
              setSearchWhitelabelText(text);
            }}
            options={whitelabelsForAutocomplete}
          />
        </div>
        {selectedWl && (
          <div className="row align-items-center justify-content-center d-flex mt-4">
            <div className="col-sm-6">
              <img
                src={selectedWl?.logoUrl}
                style={{ maxWidth: 200 }}
                alt={selectedWl.domain}
              />
            </div>
            <div className="col-sm-6 align-middle">
              Domain: {selectedWl.domain}
              <br />
              Company: {selectedWl.companyName}
            </div>
            <div className="col-sm-12 text-center pt-4">
              <div className="d-flex justify-content-center m-4">
                <AutoComplete
                  getOptionLabel={(option) => {
                    return option;
                  }}
                  label="Supplier Category"
                  onChange={(e, selectedValue) =>
                    handleNewSupplierCategoryChange(selectedValue)
                  }
                  options={convertEnumToArray(SupplierCategoryEnum)}
                />
              </div>
              <button className="btn btn-primary" onClick={makeSupplier}>
                Make Supplier
              </button>
            </div>
          </div>
        )}
      </Dialog>

      <div className="d-flex flex-row-reverse bg-light border p-2">
        <button
          className="btn btn-dark btn-sm"
          onClick={() => setShowSupplierListModal(true)}
        >
          + Supplier
        </button>
      </div>
      <ul className="list-group mt-2">
        {SupplierLists?.length ? (
          SupplierLists.map((supplier: any, index) => (
            <li
              className="list-group-item d-flex justify-content-between align-items-center"
              key={supplier.id}
            >
              {supplier?.siteData?.domain} ({supplier?.siteData?.companyName})
              <span className="badge bg-secondary">{supplier?.category}</span>
              <span>
                <FormControlLabel
                  className="mx-2"
                  control={
                    <Switch
                      size="small"
                      checked={Boolean(supplier?.isVisible)}
                      onChange={(event) =>
                        changeVisibilityOfSupplierList(event, supplier, index)
                      }
                    />
                  }
                  label={
                    <span className="fs-6 fw-light">
                      <small>Visibility Status</small>
                    </span>
                  }
                />
              </span>
            </li>
          ))
        ) : (
          <>No Suppliers Found!</>
        )}
      </ul>
    </div>
  );
};

export default SupplierList;
