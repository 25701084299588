import React from "react";

import { PartnerSippiPage } from "./PartnerSippiPage";

export default function Home() {
  return (
    <>
      <PartnerSippiPage />
    </>
  );
}
