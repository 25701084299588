//import all reducers here
import { combineReducers } from "redux";
import visaReducer from "redux/reducers/visaReducer";
import { siteSettingsReducer } from "./siteSettingsReducer";
import { commonReducer } from "./commonReducer";
import { packageReducer } from "./packageReducer";
import { accountsReducer } from "./accounts.reducer";
import { flightApiReducer } from "./flightApi.reducer";
import { paymentGatewayReducer } from "../slices/paymentGatewaySlice";
import { wlMetaReducer } from "redux/slices/wlMeta.slice";
import { sippiCustomerReducer } from "redux/slices/sippiCustomer.slice";
import { currencyWisePricesReducer } from "redux/slices/currencyWisePrices.slice";

export const rootReducer = combineReducers({
  visaChecklist: visaReducer,
  siteSettings: siteSettingsReducer,
  packages: packageReducer,
  common: commonReducer,
  accounts: accountsReducer,
  flightApi: flightApiReducer,
  paymentGateway: paymentGatewayReducer,
  wlMeta: wlMetaReducer,
  sippiCustomer: sippiCustomerReducer,
  currencyWisePrices: currencyWisePricesReducer,
});
