import React from "react";

export default function AmountSummary(props) {
  const { totalAmount, actualTotalAmount } = props;

  return (
    <>
      <div className=" row ">
        <div className=" offset-md-3 col-md-6 offset-sm-2 col-sm-8" item lg={4}>
          <div className="shadow p-4">
            <p variant="h5" color="primary">
              Amount Summary
            </p>
            <p>Total Amount: {totalAmount}</p>
            <p>Actual Total Amount: {actualTotalAmount}</p>
            <p>Difference: {totalAmount - actualTotalAmount}</p>
          </div>
        </div>
      </div>
    </>
  );
}
