import React, { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { postRequest } from "tools/ajax";
import { dmcSettingsRConstants } from "commonFnBcode/routeConstants/DmcSettings.constant";
import { getRouteString } from "commonFnBcode/commonFunctions";

const TextEditor: React.FC<{
  label: string;
  value: string;
  name: string;
  handleChange: Function;
}> = (props) => {
  return (
    <Form.Group className="mb-4">
      <Form.Label className="text-muted mb-0">{props.label}</Form.Label>
      <CKEditor
        editor={ClassicEditor}
        data={props.value}
        onChange={(event, editor) => {
          const data = editor.getData();
          props.handleChange(props.name, data);
        }}
      />
    </Form.Group>
  );
};

export const DmcModuleSettings = () => {
  const [formData, setFormData] = useState<{
    itineraryTnC?: string;
    voucherTnC?: string;
    checklistTnC?: string;
    quotationTnC?: string;
    licenseName?: string;
    travelAgentLabel?: string;
    licenseNumber?: string;
  }>({
    itineraryTnC: "",
    voucherTnC: "",
    checklistTnC: "",
    quotationTnC: "",
    licenseName: "",
    travelAgentLabel: "",
    licenseNumber: "",
  });
  // const siteId = useAppSelector(
  //   (state) => state?.siteSettings?.siteData?.siteId
  // );

  useEffect(() => {
    console.log({ formData });
  }, [formData]);

  const getDmcSettings = async () => {
    try {
      const getDmcMetaRes = await postRequest(
        getRouteString(dmcSettingsRConstants.getDmcMetaData),
        formData
      );

      if (getDmcMetaRes.success) {
        // toast.success("Data saved successfully");

        setFormData({
          ...getDmcMetaRes.result,
        });
      } else {
        throw new Error(getDmcMetaRes.errorMessage);
      }
    } catch (error: any) {
      let errorMessage = "Could not get data.";
      if (error.message) {
        errorMessage = error.message;
      }
      toast.error(errorMessage);
    }
  };

  const handleChange = (name, data) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: data,
    }));
  };

  const printInp = (props: {
    label: string;
    name: string;
    value: string;
    handleChagne: any;
    type: string;
  }) => {
    return (
      <Form.Group className="mb-4">
        <Form.Label className="mb-0 text-muted">{props.label}</Form.Label>
        <Form.Control
          name={props.name}
          value={props.value}
          onChange={props.handleChagne}
          type={props.type}
          placeholder={props.label}
        />
      </Form.Group>
    );
  };

  useEffect(() => {
    getDmcSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveDmcMetaData = async () => {
    try {
      const saveDmcMetaRes = await postRequest(
        getRouteString(dmcSettingsRConstants.saveDmcMetaData),
        formData
      );
      // setDmcMeta(saveDmcMetaRes.result);
      if (saveDmcMetaRes.success) {
        toast.success("Data saved successfully");
      } else {
        throw new Error(saveDmcMetaRes.errorMessage);
      }
    } catch (error: any) {
      let errorMessage = "Could not save data.";
      if (error.message) {
        errorMessage = error.message;
      }
      toast.error(errorMessage);
    }
  };

  return (
    <div className="container">
      <p className="h4">DMC Module Settigns</p>
      <div className="row">
        <div className="col-md-12">
          {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
          {printInp({
            label: "License Name",
            name: "licenseName",
            value: formData.licenseName || "",
            handleChagne: (e) => handleChange(e.target.name, e.target.value),
            type: "text",
          })}
          {printInp({
            label: "Travel Agent Label",
            name: "travelAgentLabel",
            value: formData.travelAgentLabel || "",
            handleChagne: (e) => handleChange(e.target.name, e.target.value),
            type: "text",
          })}
          {printInp({
            label: "License Number",
            name: "licenseNumber",
            value: formData?.licenseNumber || "",
            handleChagne: (e) => {
              handleChange(e.target.name, e.target.value);
            },
            type: "text",
          })}
          <TextEditor
            label="Itinerary Terms & Conditions"
            value={formData?.itineraryTnC || ""}
            name="itineraryTnC"
            handleChange={handleChange}
          />
          <TextEditor
            label="Checklist Terms & Conditions"
            value={formData?.checklistTnC || ""}
            name="checklistTnC"
            handleChange={handleChange}
          />
          <TextEditor
            label="Voucher Terms & Conditions"
            value={formData?.voucherTnC || ""}
            name="voucherTnC"
            handleChange={handleChange}
          />
          <TextEditor
            label="Quotation Terms & Conditions"
            value={formData?.quotationTnC || ""}
            name="quotationTnC"
            handleChange={handleChange}
          />
          <div className="text-center">
            <button
              className="btn btn-primary"
              type="button"
              onClick={saveDmcMetaData}
            >
              Save DMC Settings
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
