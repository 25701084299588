/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FullScreenLoader } from "../flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import { AdvanceSettings } from "./AdvanceSettings";
import { AdminApi } from "apiCalls/adminApi";

export const FlightSupplierEmailLogs = () => {
  const [loadingState, setLoadingState] = React.useState(false);
  const [emailLogsState, setEmailLogsState] = React.useState<any>();
  const [pageState, setPageState] = React.useState(1);
  const [showExceptionsEmails, setShowExceptionsEmails] = useState(true);
  const getFlightSupplierEmails = async () => {
    setLoadingState(true);
    setEmailLogsState(undefined);
    const supplierEmailLogs = await AdminApi.getFLightSupplierEmailLogs(
      pageState,
      showExceptionsEmails
    );

    if (supplierEmailLogs.success) {
      setEmailLogsState(supplierEmailLogs.result);
    }

    setLoadingState(false);
  };

  useEffect(() => {
    getFlightSupplierEmails();
    return () => {};
  }, [pageState, showExceptionsEmails]);

  return (
    <>
      <FullScreenLoader show={loadingState} />

      <AdvanceSettings />
      <Container>
        <Typography variant="h5">Flight Supplier Email Logs</Typography>

        {/* On / off radio */}
        <div className="mt-3">
          <label className="me-3">
            <input
              type="radio"
              name="email_type"
              value="on"
              onChange={() => setShowExceptionsEmails(false)}
            />
            Sent Emails
          </label>
          <label>
            <input
              type="radio"
              name="email_type"
              value="off"
              onChange={() => setShowExceptionsEmails(true)}
            />
            Exceptions
          </label>
        </div>

        <ul
          style={{
            marginTop: 50,
          }}
          className="list-group"
        >
          {emailLogsState?.data?.map((log, index) => {
            return (
              <li
                key={log.id.toString()}
                className={`list-group-item p-4 ${
                  index % 2 === 0 ? "bg-light" : ""
                }`}
              >
                <strong>Email To: {log.to}</strong>
                <br />
                <strong>Email CC: {log.cc}</strong>
                <br />
                <strong>Subject: {log.subject}</strong>
                <br />
                <strong>Message ID: {log.message_id}</strong>
                <br />
                <strong>History ID: {log.history_id}</strong>
                <br />
                <strong>PNR: {log.pnr}</strong>
                <br />
                {/* <div>Email: {log.message_text}</div> */}
                {/* <br /> */}
                <div>
                  {/* print html */}
                  <div dangerouslySetInnerHTML={{ __html: log.email_html }} />
                </div>
              </li>
            );
          })}
        </ul>
        {emailLogsState?.hasPreviousPage && (
          <button
            className="btn btn-primary"
            onClick={() => {
              setPageState(pageState - 1);
            }}
          >
            Prev
          </button>
        )}
        {emailLogsState?.hasNextPage && (
          <button
            className="btn btn-primary"
            onClick={() => {
              setPageState(pageState + 1);
            }}
          >
            Next
          </button>
        )}
      </Container>
    </>
  );
};
