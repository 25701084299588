import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "components/user/common/loading";

import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import "react-intl-tel-input/dist/main.css";
import { MasterApi } from "apiCalls/masterDb";
import { CountryType, prePaymentFormSchema } from "types/common.types";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "redux/actions/common.actions";
import {
  IpurchaseForm,
  ZpurchaseFormSchema,
  productTypeEnum,
} from "commonFnBcode/types/whitelabel.types";
import { getRequest, postRequest } from "tools/ajax";
import {
  IComboPlans,
  ICurrencyWisePrices,
  currencySigns,
  currencyType,
} from "types/wallet.types";
import { sippiCustomerSlice } from "redux/slices/sippiCustomer.slice";
import { useAppSelector } from "hooks/reduxHooks";
import { TravelWebsitePurchaseForm } from "./TravelWebsitePurchaseForm";
import { setCurrencyWisePrices } from "redux/slices/currencyWisePrices.slice";
import _ from "lodash";
import { ConfirmCurrencyChangeModal } from "./ConfirmCurrencyChangeModal";
import queryString from "query-string";

export default function TravelEsLitePrePayment() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getGateway = () => "cashfree";

  /**
   *
   * this state variable will represet if we have checked whether the user has purchased earlier or not
   *  we are doing so because we want to populate the form with the default values
   *  and the default values will be returned from checkIfPurchased methon of this component
   */
  const [ifPurchasedCheckDone, setIfPurchaseCheckDone] =
    useState<Boolean>(false);

  const parsedParams = queryString.parse(location.search);
  const urlParams = new URLSearchParams(window.location.search);
  const [showPlanDetails, setShowPlanDetails] = useState(false);
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true);
  const [temorarerlySelectedCurrency, setTemorarerlySelectedCurrency] =
    useState<currencyType | undefined>(undefined);
  const [form, updateForm] = useState<IpurchaseForm>({
    companyName: "",
    billingName: "",
    email: "",
    phoneCountryCode: "+91",
    phoneNumber: "",
    country: "IN",
    gst: "",
    state: "",
    address1: "",
    address2: "",
    pin: "",
    promoCode: "",
    panCardNo: "",
    // panCardName: "",
  });

  const [countries, setCountries] = useState<
    Array<{ label: string; value: any }> | undefined
  >([]);

  const currencyWisePrices: ICurrencyWisePrices | undefined = useAppSelector(
    (state) => state.currencyWisePrices.currencyWisePrices
  );
  const currency = useAppSelector(
    (state) => state.currencyWisePrices.currencyWisePrices?.currency
  );
  // const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(
  //   currency
  // );
  const [verifiedPanNo, setVerifiedPanNo] = useState(false);
  const [panNo, setPanNo] = useState<string | undefined>(undefined);
  const [comboPlan, setComboPlan] = useState<IComboPlans | undefined>(
    undefined
  );
  const [productPrices, setProductPrices] = useState<
    | {
        product: string;
        price: number;
        currency: currencyType;
        monthlyMinimum: number;
        offeredBalance: number;
      }[]
    | undefined
  >(undefined);
  const [showConfirmCurrencyChangeModal, setShowConfirmCurrencyChangeModal] =
    useState(false);
  const plan = urlParams.get("plan");
  useEffect(() => {
    // if (currency) getPrices();
    if (currency) getProductPriceWithCurrency();
  }, [currency]);

  useEffect(() => {
    checkIfPurchased();
    console.log(process.env.NODE_ENV, "this is node env");
    getCountries();
    getComboPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (parsedParams.order_id) {
      const paymentObj = {
        order_id: parsedParams.order_id,
      };
      setLoading(true);
      axios({
        url: "/api/payment/plan1Success",
        method: "POST",
        data: paymentObj,
        headers: {
          "customer-token": localStorage.getItem("customer-token"),
        },
      })
        .then((r) => {
          history.push("/user/dashboard");
          dispatch(
            sippiCustomerSlice.actions.setSippiCustomer(
              r?.data?.result?.updateCustomer
            )
          );
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedParams.order_id, parsedParams.order_token]);

  // const travelWebsitePrice = useAppSelector(
  //   (state) =>
  //     state.currencyWisePrices.currencyWisePrices?.Travel_Website_Purchase
  // );

  const checkIfCurrencySelected = async (selectedCurrency) => {
    try {
      const res = await getRequest("wallet/getSippiCustomerCurrency", {
        checkingBeforeWebsitePurchase: true,
      });

      if (!res?.result?.currency) {
        dispatch(
          setCurrencyWisePrices({
            ...currencyWisePrices,
            currency: selectedCurrency,
          })
        );
        return;
      }
      if (res?.result?.currency !== selectedCurrency) {
        setShowConfirmCurrencyChangeModal(true);
        setTemorarerlySelectedCurrency(selectedCurrency);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  // const comboPrice = useAppSelector((state) => {
  //   if (state.currencyWisePrices.currencyWisePrices) {
  //     let selectedPlan = comboPlan;
  //     if (
  //       comboPlan === productTypeEnum.COMBO_PLAN_3_WITH_TBO ||
  //       productTypeEnum.COMBO_PLAN_3_WITH_TRIPJACK
  //     ) {
  //       selectedPlan = "comboPlan3";
  //     }
  //     return state?.currencyWisePrices?.currencyWisePrices[selectedPlan];
  //   }
  // });

  const getCountries = async () => {
    dispatch(SET_LOADER(true));
    const response = await MasterApi.getCountries();
    if (!response?.success || !response?.result)
      return toast.error(response?.message || "Could not get countries");
    const countriesArr = response.result.map((country: CountryType) => ({
      label: `${country.countryName} (${country.countryCode})`,
      value: country.countryCode,
    }));
    setCountries(countriesArr);
    dispatch(SET_LOADER(false));
  };

  const checkIfPurchased = () => {
    setLoading(true);
    axios({
      url: "/api/payment/checkIfUserPurchased",
      headers: {
        "customer-token": localStorage.getItem("customer-token"),
      },
    }).then((r) => {
      setIfPurchaseCheckDone(true);
      setLoading(false);
      if (r?.data?.purchases) {
        if (r.data.purchases?.form) {
          updateForm((old) => ({
            ...old,
            ...r.data.purchases.form,
          }));
        }
        if (r.data.purchases.isPaid) {
          toast("You have already paid for this product!");
          history.push("/user/dashboard");
          return;
        }
      }
    });
  };
  // const printTextInput = (label, name, isRequired = true) => (
  //   <Form.Group className="text-start mb-2">
  //     <Form.Label className="mb-0 text-muted ">{label}</Form.Label>
  //     <Form.Control
  //       name={name}
  //       required={isRequired}
  //       value={form[name]}
  //       onChange={(e) => {
  //         updateForm({
  //           ...form,
  //           [name]: e.target.value,
  //         });
  //       }}
  //     />
  //   </Form.Group>
  // );

  // const selectInput = (props: {
  //   label: string;
  //   name: string;
  //   value: string;
  //   required?: boolean;
  //   options:
  //     | Array<{
  //         label: string;
  //         value: any;
  //       }>
  //     | undefined;
  //   handleChange: any;
  // }) => (
  //   <Form.Group className="text-start mb-2">
  //     <Form.Label className="mb-0 text-muted ">
  //       {props.label}
  //       {props.required && <span className="text-danger">*</span>}
  //     </Form.Label>
  //     <Form.Select
  //       value={props.value}
  //       onChange={({ target }) => {
  //         updateForm({
  //           ...form,
  //           [target.name]: target.value,
  //         });
  //       }}
  //       name={props.name}
  //       required={props.required}
  //     >
  //       {props?.options?.length
  //         ? props?.options?.map((option, index) => (
  //             <option value={option.value} key={index}>
  //               {option.label}
  //             </option>
  //           ))
  //         : []}
  //     </Form.Select>
  //   </Form.Group>
  // );

  const submit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    if (currency === "INR") {
      if (!(await verifyPanNo())) {
        return;
      }
    }
    const verifyForm = prePaymentFormSchema.safeParse(form);
    if (!verifyForm.success && !phoneNumberIsValid) {
      toast.error("Please enter valid values in the form!");
      setLoading(false);
      return;
    }

    try {
      const isValid = ZpurchaseFormSchema.safeParse(form);
      if (!isValid.success) {
        toast.error("something went wrong");
        return;
      }
      //add purchase form to sippi customer object
      // const addPurchaseObjectResponse = await postRequest(
      //   "wallet/addPurchaseObjectToSippiCustForm",
      //   form
      // );
      // if (!addPurchaseObjectResponse.success) {
      //   toast.error("something went wrong");
      //   return;
      // }
      // entry in order table
      // const createOrderRes = await postRequest("wallet/createSippiOrder", {
      //   amount: travelWebsitePrice,
      //   remark: paymentTypeEnum.TRAVEL_WEBSITE_PURCHASE,
      //   productType: productTypeEnum.TRAVEL_WEBSITE_PURCHASE,
      //   currency,
      // });
      // if (createOrderRes?.result) {
      //   history.push(
      //     `/user/payment_screen?orderId=${createOrderRes.result}&redirectRoute=/user/dashboard`
      //   );
      // }
      const purchaseComboResponse = await postRequest("wallet/purchaseCombo", {
        form,
        comboPlan: productTypeEnum.COMBO_PLAN_1,
        currency,
        panNo,
        // panName: form?.panCardName,
      });
      if (purchaseComboResponse?.result) {
        // history.push(
        //   `/user/SelectAddonBeforePaymentScreen?orderId=${purchaseComboResponse.result}`
        // );
        history.push(
          `/user/payment_screen?orderId=${purchaseComboResponse.result}&redirectRoute=/user/dashboard`
        );
      }
      setLoading(false);
      // return res?.result.purchaseResponse;
    } catch (error: any) {
      toast.error(error.message);
      return;
    }
  };
  //commented when switched to pre paid modal (01-01-2024)
  // const submitPurchaseForm = async () => {
  //   setLoading(true);

  // const response = await axios({
  //   url: "/api/payment/createPurchase",
  //   method: "POST",
  //   headers: {
  //     "customer-token": localStorage.getItem("customer-token"),
  //   },
  //   data: {
  //     form,
  //   },
  // });
  // if (!response?.data?.success || !response?.data?.result) {
  //   return toast.error(
  //     response?.data?.errorMessage ||
  //       "Failed to create a purchase... Please contact Support..."
  //   );
  // }
  //   const sippiOrderdetails = {
  //     amount: 5100,
  //     remark: "Travel Website Purchase",
  //   };
  //   const res = await postRequest("wallet/createSippiOrder", {
  //     form,
  //     sippiOrderdetails,
  //   });
  //   setLoading(false);
  //   return res?.result.purchaseResponse;
  // };

  const handlePrimaryPhoneChange = (
    isValid,
    value,
    selectedCountryData,
    fullNumber
  ) => {
    // if (isValid) {
    setPhoneNumberIsValid(isValid);
    updateForm({
      ...form,
      //.replace(/ /g, "") removed all spaces/whitespaces
      phoneNumber: value,
      phoneCountryCode: selectedCountryData.dialCode,
    });
    // }
  };

  // const handleCountryChange = (e, data) => {
  //   console.log({ data, val: e.target.value, name: e.target.name });
  // };

  const verifyPanNo = async () => {
    try {
      if (!form.panCardNo?.trim) {
        toast.error("Invalid Pan Number!");
        return false;
      }

      const res = await postRequest("wallet/verifyPanNoDurinigSitePurchase", {
        panNo: form?.panCardNo,
      });
      if (res?.success) {
        setVerifiedPanNo(true);
        toast.success("Pan card verified.");
        updateForm(form);

        return true;
      } else {
        setLoading(false);
      }
    } catch (error) {
      setVerifiedPanNo(false);
      console.log(error);
      return false;
    }
    return false;
  };

  const getProductPriceWithCurrency = async () => {
    try {
      const pricesResponce = await getRequest(
        "wallet/getProductPriceWithCurrency",
        {
          currency,
          productEnum: productTypeEnum.COMBO_PLAN_1,
        }
      );
      setProductPrices(pricesResponce.result);
    } catch (error) {
      console.log(error);
    }
  };
  const getComboPlans = async () => {
    try {
      const comboPlansRes = await getRequest("wallet/getComboPlans", {
        comboPlan: productTypeEnum.COMBO_PLAN_1,
      });
      if (comboPlansRes?.success) {
        setComboPlan(comboPlansRes.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const planPrice: any = _.find(productPrices, {
    product: productTypeEnum.COMBO_PLAN_1,
  });
  const offeredBalance: any = _.find(productPrices, {
    product: `${productTypeEnum.COMBO_PLAN_1}_offered_balance`,
  });

  return (
    <>
      {loading && <Loading loading={loading} />}
      <ConfirmCurrencyChangeModal
        temorarerlySelectedCurrency={temorarerlySelectedCurrency}
        show={showConfirmCurrencyChangeModal}
        onHide={() => setShowConfirmCurrencyChangeModal(false)}
        setCurrentCurrency={(selectedCurrency) => {
          dispatch(
            setCurrencyWisePrices({
              ...currencyWisePrices,
              currency: selectedCurrency,
            })
          );
          updateForm({
            companyName: "",
            billingName: "",
            email: "",
            phoneCountryCode: "+91",
            phoneNumber: "",
            country: "IN",
            gst: "",
            state: "",
            address1: "",
            address2: "",
            pin: "",
            promoCode: "",
            panCardNo: "",
            // panCardName: "",
          });
          console.log("HERE");

          setShowConfirmCurrencyChangeModal(false);
        }}
      />
      {/* <DebugComponent jsonData={form} show={true} /> */}
      <div className="pre-payment-form  mt-4 container  ">
        {" "}
        <div className="ms-2 mb-4 border-start border-primary border-5 ps-3">
          <p className=" fw-bold text-primary fs-4 mb-1">
            {" "}
            {comboPlan?.planName} Plan
          </p>
          <p className="fs-6">Pre-payment form</p>{" "}
        </div>
        <div className="row flex-column-reverse flex-md-row  ">
          <div className="col-12 col-md-8 px-4">
            <div className="row main-box">
              <div className="col-12 col-xs-12">
                <div className="">
                  {ifPurchasedCheckDone && (
                    <form
                      onSubmit={async (e) => {
                        e.preventDefault();

                        await submit(e);
                      }}
                    >
                      <TravelWebsitePurchaseForm
                        phoneNumberIsValid={phoneNumberIsValid}
                        verifyPanNo={verifyPanNo}
                        verifiedPanNo={verifiedPanNo}
                        panNo={panNo}
                        setPanNo={setPanNo}
                        SET_LOADER={(val) => dispatch(SET_LOADER(val))}
                        setCountries={setCountries}
                        countries={countries}
                        currency={currency}
                        form={form}
                        updateForm={updateForm}
                        // handleCountryChange={handleCountryChange}
                        handlePrimaryPhoneChange={handlePrimaryPhoneChange}
                      />

                      <div style={{ textAlign: "center", padding: "1rem" }}>
                        <button type="submit" className="btn btn-success">
                          Proceed
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="plan-details col-12 col-md-4  ">
            <div className="bg-light border rounded w-100 px-3 py-2 ">
              <div className="d-flex align-items-center justify-content-between align-items-end my-3 alert alert-info">
                <div className="">
                  {comboPlan ? comboPlan.planName + " Plan" : ""}
                </div>
                <div>
                  <b>
                    {currency && currencySigns[currency]}
                    {planPrice?.price.toFixed(2)}
                  </b>
                </div>
              </div>
              {/* <div
                className={`${
                  showPlanDetails ? " d-flex " : " hide-details d-flex"
                }  align-items-center justify-content-between  mb-3 `}
              >
                <div>Selected Currency</div>
                <select
                  value={currencyWisePrices?.currency}
                  className="form-select w-25"
                  aria-label="Default select example"
                  name="selectedCurrency"
                  onChange={(e) => {
                    checkIfCurrencySelected(e.target.value);
                    // setSelectedCurrency(e.target.value);
                  }}
                >
                  <option value={currencyType.INR}>{currencyType.INR}</option>
                  <option value={currencyType.USD}>{currencyType.USD}</option>
                </select>
              </div> */}

              {/* <div
                className={`alert alert-info small text-start ${
                  showPlanDetails ? "" : "hide-details"
                }`}
                role="alert"
              >
                You will not be able to change the Currency beyond this point.
              </div> */}

              {/* <button className="btn btn-primary w-100">Next</button> */}
              <h4
                className={`text-start mb-2 text-muted mt-0 ${
                  showPlanDetails ? "" : "hide-details"
                }`}
              >
                Plan Inclusions
              </h4>
              <div
                className={`bg-white border p-3 rounded text-start mb-2 ${
                  showPlanDetails ? "" : "hide-details"
                }`}
              >
                <div className="price-plan-details mb-2">
                  <div className="plan-detail-item text-muted">
                    <i className="fas fa-check text-muted pe-3"></i> Travel
                    Website
                  </div>
                </div>
                <div className="plan-detail-item text-muted mb-2">
                  <i className="fas fa-check text-muted pe-3"></i>{" "}
                  {currency && currencySigns[currency]}
                  {planPrice?.offeredBalance} Wallet Balance
                </div>
                {comboPlan?.description.map((item, key) => {
                  return (
                    <div
                      className="plan-detail-item text-success  text-muted mb-2"
                      key={key}
                    >
                      <i className="fas fa-check text-success  text-muted pe-3"></i>{" "}
                      {item.heading}
                    </div>
                  );
                })}
              </div>
              <div className="more-details text-start ">
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => setShowPlanDetails(!showPlanDetails)}
                >
                  {showPlanDetails ? "Hide" : "Show"} Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
