import { getRouteString } from "commonFnBcode/commonFunctions";
import { userAdvanceRConstants } from "commonFnBcode/routeConstants/UserAdvance.constant";
import { getRequest, postRequest } from "tools/ajax";

export class UserAdvanceApi {
  static saveWlMetaData = async (clientId: string) => {
    const result = await postRequest("userAdvance/saveWlMetaData", {
      wlMeta: {
        googleAuthClientId: clientId,
      },
    });
    return result;
  };
  static getWlMetaData = async () => {
    const result = await getRequest(
      getRouteString(userAdvanceRConstants.getWlMetaData),
      {}
    );
    return result;
  };
}
