/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  makeStyles,
  Button,
} from "@material-ui/core";
import moment from "moment";
import { date } from "tools/constants";
import FlightApi from "apiCalls/FlightApi.ts";
import { tboSetAllBookings } from "redux/actions/flightApi.actions";
import { SET_LOADER } from "redux/actions/common.actions";
import { useDispatch, useSelector } from "react-redux";
import { getWlUserWithWhitelabelDetails } from "apiCalls/whitelabelDb";
import Dialog from "commonUi/dialog/Index";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  segmentFlex: {
    display: "flex",
    alignItems: "center",
    // justifyContent: 'space-between'
  },
  segmentFlexItem: {
    margin: "4px",
  },
}));

const Tbo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tboBookings = useSelector((state) => state.flightApi.tbo.allBookings);
  const [selectedUser, setSelectedUser] = useState(false);
  useEffect(() => {
    callTboGetAllBookings();
    // tempLocalStorageBookings()
  }, []);

  // const tempLocalStorageBookings = () => {
  //     dispatch(tboSetAllBookings(JSON.parse(localStorage.getItem('bookings'))))
  // }

  const callTboGetAllBookings = async () => {
    dispatch(SET_LOADER(true));
    const bookings = await FlightApi.tboGetAllBookings();
    console.log(bookings);
    if (bookings.length) dispatch(tboSetAllBookings(bookings.reverse()));
    else toast.error("Bookings not found");
    dispatch(SET_LOADER(false));
    // localStorage.setItem('bookings',JSON.stringify(bookings))
  };

  const printBookingStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return <>First Case</>;
      case 2:
        return <>Hold/Booked</>;
      case 3:
        return <>Ticketed</>;
      case 4:
        return <>Cancelled</>;
      case 5:
        return <>Payment</>;
      default:
        return <>{statusId}</>;
    }
  };

  const getUserDetails = async (booking, index) => {
    dispatch(SET_LOADER(true));
    const userId = booking.userId;
    const res = await getWlUserWithWhitelabelDetails({ userId });
    if (res.err) return toast.error(res.err);
    if (res.user) {
      setSelectedUser(res.user);
      console.log(res.user);
    } else toast.error("user not found!");
    dispatch(SET_LOADER(false));
  };

  return (
    <Container>
      <Typography variant="h5">TBO Flights - Total Sale Report</Typography>
      {tboBookings?.map((booking, index) => (
        <Paper key={booking._id} className={classes.paper} elevation={3}>
          <Grid container>
            <Grid item md={4}>
              Booking Status: {printBookingStatus(booking.statusId)}
              {/* {(booking.statusId === 3)} */}
              <br />({booking.airlineCode}) {booking.origin} -{" "}
              {booking.destination}
              <br />
              {booking?.segments?.map((seg, index) => (
                <div className={classes.segmentFlex} key={index}>
                  <div className={classes.segmentFlexItem}>
                    {seg.origin.airport.airportCode}{" "}
                    {seg.origin.airport.cityName}{" "}
                    {seg.origin.airport.terminal &&
                      `T-${seg.origin.airport.terminal}`}
                    <br />
                    {moment(seg.origin.depTime).format(date.dateTimeFormat)}
                  </div>
                  <div className={classes.segmentFlexItem}>➤</div>
                  <div className={classes.segmentFlexItem}>
                    {seg.destination.airport.airportCode}{" "}
                    {seg.destination.airport.cityName}{" "}
                    {seg.destination.airport.terminal &&
                      `T-${seg.destination.airport.terminal}`}
                    <br />
                    {moment(seg.destination.arrTime).format(
                      date.dateTimeFormat
                    )}
                  </div>
                </div>
              ))}
              <br />
              PNR: {booking.pnr}
              <br />
              Created At:{" "}
              {moment(booking.createdAt).format(date.dateTimeFormat)}
              <br />
              Publish: {booking?.fare?.publishedFare} Offer:{" "}
              {booking?.fare?.offeredFare}
            </Grid>
            <Grid item md={4}>
              Airline Remark: {booking?.airlineRemark}
              <br />
              Agent Remark: {booking?.agentRemark}
              <br />
              Invoice Amount: {booking?.invoiceAmount}
              <br />
              Invoice Number: {booking?.invoiceNo}
              <br />
              Invoice Created on:{" "}
              {moment(booking?.invoiceCreatedOn).format(date.dateTimeFormat)}
            </Grid>
            <Grid item md={4}>
              {booking?.passenger?.map((passenger, index) => (
                <>
                  Pax {index + 1}
                  <br />
                  {passenger.title} {passenger.firstName} (F.N.){" "}
                  {passenger.lastName} (L.N.)
                  <br />
                </>
              ))}
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => getUserDetails(booking, index)}
              >
                Show User Details
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ))}

      <Dialog
        title="User Details"
        isOpen={Boolean(selectedUser)}
        handleClose={() => setSelectedUser(false)}
      >
        {selectedUser && selectedUser.userType ? (
          <>
            User Type: {selectedUser.userType}
            <br />
            User Email: {selectedUser?.loginDetails?.email}
            {selectedUser?.websiteDetails?.length ? (
              <>
                <br />
                Website: {selectedUser?.websiteDetails[0]?.domain}
                <br />
                Website Company: {selectedUser?.websiteDetails[0]?.companyName}
              </>
            ) : (
              <>Website Not Found!</>
            )}
          </>
        ) : (
          <>User not found!</>
        )}
      </Dialog>
    </Container>
  );
};

export default Tbo;
