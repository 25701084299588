import { UserAdvanceApi } from "apiCalls/UserAdvance.api";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { fetchWlMeta, selectWlMeta } from "redux/slices/wlMeta.slice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { SET_LOADER } from "redux/actions/common.actions";

export const GoogleAuth: React.FC = () => {
  const [clientId, setClientId] = useState("");
  const dispatch = useAppDispatch();
  const wlMeta = useAppSelector(selectWlMeta);

  useEffect(() => {
    if (wlMeta?.googleAuthClientId) {
      setClientId(wlMeta.googleAuthClientId);
    }
  }, [wlMeta?.googleAuthClientId]);

  const printInp = (name, label, value) => (
    <Form.Group>
      <Form.Label className="mb-0 ">{label}</Form.Label>
      <Form.Control
        name={name}
        type="text"
        value={value}
        onChange={handleInputChange}
        placeholder={label}
        required
      />
    </Form.Group>
  );

  const handleInputChange = ({ target }) => {
    setClientId(target.value);
  };

  const saveGoogleClientId = async () => {
    dispatch(SET_LOADER(true));
    try {
      const response = await UserAdvanceApi.saveWlMetaData(clientId);
      if (response.success) {
        toast.success("Google Client Id saved successfully");
        dispatch(fetchWlMeta());
      } else {
        toast.error(response.errorMessage);
      }
    } catch (error: any) {
      toast.error(error.message || "Could not save Google Client Id...");
      console.log({ saveGoogleClientIdError: error });
    }
    dispatch(SET_LOADER(false));
  };

  return (
    <div className="row shadow p-2 mt-4">
      <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
        {printInp("clientId", "Google Client Id", clientId)}
        <button className="btn btn-primary mt-2" onClick={saveGoogleClientId}>
          Save
        </button>
      </div>
      <div className="col-md-6">
        <p>
          If you wish to allow users of your website to login using their google
          account instead of filling the signup form of your website then you
          can use this feature. To enable google login, you have to enter the
          client id of your google O Auth 2.0 account.
        </p>
        <iframe
          width="100%"
          height="250"
          src="https://www.youtube.com/embed/xH6hAW3EqLk"
          title="Google O Auth Login Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
