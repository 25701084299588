import exportFromJSON from "export-from-json";
import { useState } from "react";
import { toast } from "react-toastify";

type TDownloadExcelProps = {
  fileName: string;
  getData: () => Promise<Object>;
  fileType?: keyof typeof exportFromJSON.types;
  children?: React.ReactNode;
  className?: string;
};

export const DownloadExcelButton: React.FC<TDownloadExcelProps> = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const onDownload = async () => {
    setIsDownloading(true);
    try {
      const dataForExcel = await props.getData();

      exportFromJSON({
        data: dataForExcel,
        fileName: props.fileName,
        exportType: props.fileType || exportFromJSON.types.xls,
      });
    } catch (error: any) {
      toast.error(error.message);
    }
    setIsDownloading(false);
  };

  return (
    <button
      className={`btn btn-sm btn-success ${props.className}`}
      disabled={isDownloading}
      onClick={() => {
        if (!isDownloading) {
          onDownload();
        }
      }}
    >
      <i className="fa-solid fa-circle-down me-1"></i>
      {props.children || "Download"}
    </button>
  );
};
