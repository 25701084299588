import { Dropdown, DropdownButton } from "react-bootstrap";
import { getRequest, postRequest } from "tools/ajax";
import { useState, useEffect } from "react";

import { IOption, SelectWrapper } from "./SelectWrapper";
import { IWhitelabel } from "types/accounts.types";
import { toast } from "react-toastify";
import { IAllWlIntegration } from "commonFnBcode/Interfaces/userInterfaces";

interface IAllApiIntegrations {
  apiName: string;
  base_url: string;
  book_url: string;
  booking_details_url: string;
  confirm_ticket_url: string;
  created_at: string;
  fare_quote_url: string;
  fare_rule_url: string;
  id: number;
  search_url: string;
  updated_at: string;
}

interface IApiIntegrationData {
  isActive: boolean;
  isTesting: boolean;
  flightApiIntegrationId?: number;
  apiCredentials: any;
  siteId: string;
  domain?: string;
  wlFlightApiCredentialName: string;
}
interface IAllWlData {
  accountsEmail: string;
  adminContact: string;
  adminEmail: string;
  companyId: number;
  companyName: string;
  creationTime: string;
  domain: string;
  first_sync_time: string;
  id: number;
  isSuspended: boolean;
  latest_sync_time: string;
  legalEntityId: number;
  logoUrl: string;
  mongoDb_siteId: string;
  siteName: string;
}
export const APIIntegrations = () => {
  const [apiIntegrations, setApiIntegrations] = useState<IAllApiIntegrations[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [apiSchema, setApiSchema] = useState<string[]>([]);
  const [apiIntegrationsData, setApiIntegrationsData] = useState<
    IApiIntegrationData | undefined
  >(undefined);
  const [apiCredentials, setApiCredentials] = useState<any[]>([]);
  const [wlData, setWlData] = useState<IOption[]>([]);
  const [selectWrapperValue, setSelectWrapperValue] = useState<string>("");

  const [allWlData, setAllWLData] = useState<IAllWlData[]>([]);
  const [allWlApi, setAllWLApi] = useState<IAllWlIntegration[]>([]);
  const [apiIntegrationsList, setApiIntegrationsList] = useState<
    IAllWlIntegration[]
  >([]);
  const handeleApiIntegrationData = (e) => {
    let { name, value } = e.target;
    if (name === "flightApiIntegrationId") {
      value = parseInt(value);
    }
    if (name === "isActive") {
      value = e.target.checked;
    }
    if (name === "isTesting") {
      value = e.target.value.toLocaleLowerCase() === "true";
    }

    setApiIntegrationsData((old: any) => ({ ...old, [name]: value }));
  };

  const handleApiCredentials = (e) => {
    let { name, value } = e.target;
    setApiCredentials((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    getAPIs();
    getAllWLAPI();
    getAllWLData();
    // getAllWhiteLabelApiIntegrations();
  }, []);

  useEffect(() => {
    getAPIIntegrationList();
  }, [allWlApi, allWlData]);

  const getAPIs = async () => {
    const allApiIntegrations = await postRequest(
      "admin/nfs/getAllApiIntegrations",
      {}
    );
    if (allApiIntegrations?.success)
      setApiIntegrations(allApiIntegrations.result);
  };
  // let options: IOption[] = [];
  const getSites = async (keyWord) => {
    const response = await postRequest("admin/nfs/getSites", { keyWord });
    if (response.success && response.result?.length >= 0) {
      const wlData: IWhitelabel[] = response?.result;
      setWlData(
        wlData.map(
          (site): IOption => ({
            label: site.domain,
            value: site.mongoDb_siteId,
          })
        )
      );
    }
  };

  const getApiSchema = async (id) => {
    const apiSchemaData = await postRequest("admin/nfs/getApiSchema", {
      apiIntegrationId: id,
    });
    if (apiSchemaData?.success) setApiSchema(apiSchemaData.result);
  };

  const saveSupplierApiIntegration = async () => {
    if (loading) return;
    setLoading(true);
    if (
      typeof apiIntegrationsData?.isTesting !== "boolean" ||
      typeof apiIntegrationsData.isActive !== "boolean" ||
      !apiIntegrationsData.siteId ||
      !apiIntegrationsData?.flightApiIntegrationId
    ) {
      toast.error("Incomplete data");
      return;
    }
    const response = await postRequest(
      "admin/nfs/createSupplierApiIntegration",
      { ...apiIntegrationsData, apiCredentials }
    );
    if (response?.success) {
      setApiIntegrationsData(undefined);
      setApiCredentials([]);
      toast.success("Api Integration Saved");
    } else {
      toast.error("Could not save api integration");
    }
    setLoading(false);
  };

  // console.log(apiIntegrationsData);

  const getAllWLAPI = async () => {
    const res = await postRequest("admin/nfs/getAllWlApiIntegrations", {});
    if (res?.success) {
      setAllWLApi(res?.result);
    } else {
      toast.error("Could not get API integration data");
    }
  };

  const getAllWLData = async () => {
    const res = await postRequest("admin/nfs/getAllWLData", {});
    if (res?.success) {
      setAllWLData(res?.result);
    } else {
      toast.error("could not get WL data");
    }
  };

  //merging wldata and api integrations data wrt mongodbsiteid
  const getAPIIntegrationList = () => {
    let APIIntegratinList: IAllWlIntegration[] = [];
    allWlData?.map((wl: IAllWlData) => {
      allWlApi?.forEach((wlApi: IAllWlIntegration) => {
        if (wlApi?.mongoDb_siteId === wl?.mongoDb_siteId) {
          APIIntegratinList.push({
            ...wlApi,
            domain: wl?.domain,
          });
        }
      });
    });
    setApiIntegrationsList(APIIntegratinList);
  };

  const getCredentials = (credintials) => {
    return Object.keys(credintials).map((cred: string) => (
      <p key={cred}>
        <b>{cred}:</b> {credintials[cred]}
      </p>
    ));
  };

  return (
    <div className="container">
      <div className="">
        <h3>Add API Integration</h3>
        <div className=" shadow p-4 mb-5">
          <div className="row   bg-body rounded justify-content-between ">
            <div className="col-2">
              <label htmlFor="select-api" className="form-label">
                <b>Select API</b>
              </label>
              <select
                name="flightApiIntegrationId"
                className="form-select"
                id="select-api"
                onChange={(e) => {
                  handeleApiIntegrationData(e);
                  getApiSchema(parseInt(e.target.value));
                }}
              >
                <option defaultChecked>API</option>
                {apiIntegrations?.map((api, i) => {
                  return (
                    <option key={i} value={api?.id}>
                      {api.apiName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-3">
              <label htmlFor="select-site" className="form-label">
                <b>Select Site</b>
              </label>
              <SelectWrapper
                onChange={(option) => {
                  setSelectWrapperValue(option?.label);

                  handeleApiIntegrationData({
                    target: { name: "siteId", value: option?.value },
                  });
                }}
                placeholder="select"
                value={selectWrapperValue}
                onInputChange={getSites}
                options={wlData}
              />
            </div>
            <div className="col-1">
              <label className="form-label">
                <b>Testing</b>
              </label>
              <div className="form-check">
                <input
                  onChange={handeleApiIntegrationData}
                  className="form-check-input"
                  type="radio"
                  name="isTesting"
                  id="testing-yes"
                  value={"true"}
                />
                <label className="form-check-label" htmlFor="testing-yes">
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  onChange={handeleApiIntegrationData}
                  value="false"
                  className="form-check-input"
                  type="radio"
                  name="isTesting"
                  id="testing-no"
                />
                <label className="form-check-label" htmlFor="testing-no">
                  No
                </label>
              </div>
            </div>
            <div className="col-1">
              <div className="form-check form-switch">
                <input
                  onChange={handeleApiIntegrationData}
                  name="isActive"
                  className="form-check-input"
                  type="checkbox"
                  id="isActive"
                />
                <label className="form-check-label" htmlFor="isActive">
                  <b>isActive</b>
                </label>
              </div>
            </div>
            <div className="col-1">
              <label htmlFor="">
                <b>Credential Name</b>
                <input
                  type="text"
                  name="wlFlightApiCredentialName"
                  className="form-control"
                  onChange={handeleApiIntegrationData}
                  value={apiIntegrationsData?.wlFlightApiCredentialName || ""}
                />
              </label>
            </div>
            <div className="col-3">
              <label className="form-check-label">
                <b>Credentials</b>
              </label>
              {!apiSchema.length && <p className="text-danger">Not Avalible</p>}
              {apiSchema.map((apiField, index) => {
                return (
                  <div className="input-group mb-3 w-100" key={index}>
                    <div>
                      <label htmlFor={apiField} className="small">
                        {apiField}
                      </label>
                      <input
                        id={apiField}
                        onChange={handleApiCredentials}
                        name={apiField}
                        type="text"
                        className="form-control"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="border-top text-end mt-3">
            <button
              className="btn btn-success mt-3  "
              onClick={saveSupplierApiIntegration}
            >
              Save
            </button>
          </div>
        </div>
      </div>{" "}
      <h3 className="mb-4">All API Integrations</h3>
      <div className="table-responsive">
        <table className="table ">
          <thead className="thead-light">
            <tr className="bg-dark">
              <th className="bg-light" scope="col">
                #
              </th>
              <th className="bg-light" scope="col">
                Domain
              </th>
              <th className="bg-light" scope="col">
                API
              </th>
              <th className="bg-light" scope="col">
                isTesting
              </th>
              <th className="bg-light" scope="col">
                isActive
              </th>
              <th className="bg-light" scope="col">
                Credentials
              </th>
              <th className="bg-light" scope="col">
                wlFlightApiCredentialName
              </th>
              <th className="bg-light text-end" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {apiIntegrationsList?.length ? (
              apiIntegrationsList?.map((data, index) => {
                return (
                  <tr key={index} className="py-4">
                    <th className="py-4" scope="row">
                      {index + 1}
                    </th>
                    <td className="py-4">{data.domain}</td>
                    <td className="py-4">
                      {data.flightApiIntegration.apiName}
                    </td>
                    <td
                      className={`text-${
                        data?.isTesting ? "success" : "danger"
                      } bold py-4`}
                    >
                      {data?.isTesting.toString().toLocaleUpperCase()}
                    </td>
                    <td
                      className={`text-${
                        data?.isActive ? "success" : "danger"
                      } bold py-4`}
                    >
                      {data?.isActive.toString().toLocaleUpperCase()}
                    </td>
                    <td className="py-4">
                      <div style={{ wordBreak: "break-all" }}>
                        {getCredentials(data.apiCredentials)}
                      </div>
                    </td>
                    <td className="py-4">{data.wlFlightApiCredentialName}</td>
                  </tr>
                );
              })
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
