import { useState, useEffect } from "react";
// import { OfferCard } from "./OfferCard";

import { getRequest, postRequest } from "tools/ajax";
import { useHistory } from "react-router-dom";
import { WalletReachargePlanItemSkelton } from "./WalletReachargePlanItemSkelton";
import { useAppSelector } from "hooks/reduxHooks";
import { WalletReachargePlanItem } from "./WalletReachargePlanItem";
import { useDispatch } from "react-redux";
// import { setCurrency } from "redux/slices/currencySlice";
import { currencySigns, currencyType } from "types/wallet.types";
import { setCurrencyWisePrices } from "redux/slices/currencyWisePrices.slice";
import { toast } from "react-toastify";
import { currencyConfig } from "types/sippiCurrencyTypes";
interface IRechargePlan {
  price: number;
  offered_amount: number;
  description: string;
  plan_name: string;
  id: number;
}

export const WalletRecharge: React.FC<{}> = () => {
  const [currentBalance, setCurrentBalance] = useState<
    | {
        balance: number;
        currency: currencyType;
      }
    | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [isPurchaseClicked, setIsPurchaseClicked] = useState(false);
  const [RechargePlans, setRechargePlans] = useState<IRechargePlan[]>([]);
  // const [price, setPrice] = useState(undefined);
  // const [currency, setCurrency] = useState<currencyType | undefined>(undefined);

  // const currency = useAppSelector(
  //   (state) => state.currencyWisePrices.currencyWisePrices?.currency
  // );
  const dispatch = useDispatch();
  // useEffect(() => {
  //   getCurrency();
  // }, []);

  useEffect(() => {
    // if (currency) getAllRechargePlans();
    getCurrentUserBalance();

    // getPrices();
  }, []);

  const history = useHistory();
  const getAllRechargePlans = async (currency: currencyType) => {
    setLoading(true);
    try {
      getRequest("wallet/getAllRechargePlans", { currency }).then((res) => {
        if (res?.success) setRechargePlans(res.result);
      });
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
    setLoading(false);
  };

  const getCurrentUserBalance = async () => {
    setLoading(true);
    try {
      const res = await getRequest("wallet/getCurrentUserBalance", {});
      if (res?.success) {
        setCurrentBalance(res?.result);
        getAllRechargePlans(res?.result?.currency);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
    setLoading(false);
  };
  function formatNumber(num) {
    let n1, n2;
    num = num + "" || "";
    n1 = num.split(".");
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    return num;
  }

  let currencySign = currentBalance
    ? currencySigns[currentBalance.currency]
    : currencyConfig.baseCurrencySymbol;

  return (
    <div className="wallet-reacharge pt-5">
      <div className="container">
        <div className="row topup-items offer-item-container rounded">
          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
            <div className="current-balance rounded bg-white p-3 rounded mb-3">
              <div className=" fs-5">Current Balance</div>

              <div className="fs-3 mt-2">
                {loading ? (
                  <p className="placeholder-glow">
                    <span className="placeholder col-12"></span>
                  </p>
                ) : (
                  <span className="fw-bold text-success">
                    {`${currencySign}${
                      currentBalance
                        ? formatNumber(currentBalance?.balance)
                        : "0"
                    } `}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-12 col-sm-12 col-12">
            <h5 className="bg-light p-3 m-0 fw-normal ">Recharge Plans</h5>
            {/* Offer Item */}
            {loading || RechargePlans.length === 0 ? (
              <>
                <WalletReachargePlanItemSkelton />
                <WalletReachargePlanItemSkelton />
                <WalletReachargePlanItemSkelton />
              </>
            ) : (
              RechargePlans?.map((plan, index) => {
                return (
                  <WalletReachargePlanItem
                    {...plan}
                    history={history}
                    index={index + 1}
                    key={plan.id}
                    loading={isPurchaseClicked}
                    setLoading={setIsPurchaseClicked}
                    currencySign={currencySign}
                    currency={currentBalance?.currency}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
