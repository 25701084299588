import React, { useEffect, useState } from "react";
import GridContainer from "commonUi/grid/container";
import GridItem from "commonUi/grid/item";
import Container from "commonUi/Container";
import SimpleTable from "commonUi/table/simpleTable";
import Typography from "commonUi/Typography";
import { GET_ALL_PACKAGES } from "redux/actions/package.actions";
import { useDispatch, useSelector } from "react-redux";
import { ListItem, List } from "@material-ui/core";

const headers = ["Package Name", "Starting Price"];

export default function Index() {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.packages.allPackages);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplierPackages, setSelectedSupplierPackages] = useState([]);
  const [supplierPackagesDataForTable, setSupplierPackagesDataForTable] =
    useState([]);
  const [supplierName, setSupplierName] = useState("");

  useEffect(() => {
    dispatch(GET_ALL_PACKAGES());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getSupplierName(packages);
  }, [packages]);

  useEffect(() => {
    let supplierTableData = selectedSupplierPackages.map((data, i) => {
      return [data.packageName, data.startingPrice];
    });
    return setSupplierPackagesDataForTable(supplierTableData);
  }, [selectedSupplierPackages]);

  const getSupplierName = async (packages) => {
    let supplierArr = await packages.map((data) => data.supplier);

    supplierArr = supplierArr.filter((data) => {
      return data !== undefined;
    });

    //let supplierName = await supplierArr.map(data=>data.supplierName)

    // let counts = {}
    // let value = supplierName.forEach((i)=>{
    //     counts[i] = (counts[i] || 0) +1
    // })
    // console.log("COUNTS",counts)

    const result = [
      ...supplierArr
        .reduce((arr, data) => {
          if (!arr.has(data.supplierName))
            arr.set(data.supplierName, { ...data, count: 0 });
          arr.get(data.supplierName).count++;
          return arr;
        }, new Map())
        .values(),
    ];
    //console.log(result);

    //let unique = [...new Set(supplierName)]

    setSuppliers(result);
  };

  const handleSupplierChange = async (supplier, i) => {
    setSupplierName(supplier.supplierName);

    let supplierPackages = [];

    await packages.filter((data, i) => {
      if (
        data &&
        data.supplier &&
        data.supplier.supplierId === supplier.supplierId
      ) {
        return supplierPackages.push(data);
      }
      return null
    });

    setSelectedSupplierPackages(supplierPackages);
  };

  return (
    <Container>
      <GridContainer justify="flex-start" spacing={2}>
        <GridItem xs={12} sm={6}>
          <Typography variant="h5">Package Supplier Report</Typography>
          <List>
            <ListItem divider style={{ justifyContent: "space-between" }}>
              <Typography>
                <b>Suppliers</b>
              </Typography>
              <Typography>
                <b>count: {suppliers.length}</b>
              </Typography>
            </ListItem>
            {suppliers.map((supplier, i) => {
              return (
                <ListItem
                  key={i}
                  divider
                  onClick={() => {
                    handleSupplierChange(supplier, i);
                  }}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography>{supplier.supplierName}</Typography>
                  <Typography variant="caption">{supplier.count}</Typography>
                </ListItem>
              );
            })}
          </List>
        </GridItem>

        {packages &&
        selectedSupplierPackages.length &&
        supplierPackagesDataForTable.length ? (
          <GridItem>
            <Typography color="initial" variant="h5">
              {supplierName}
            </Typography>
            <Typography variant="caption" color="initial" align="center">
              Package count: {supplierPackagesDataForTable.length}
            </Typography>
            <SimpleTable
              headerCells={headers}
              rowsArr={supplierPackagesDataForTable}
            />
          </GridItem>
        ) : (
          " "
        )}
      </GridContainer>
    </Container>
  );
}
