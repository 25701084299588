import { z } from "zod";

export const phoneNumberStringSchema = z
  .string()
  .refine((val) => val.length > 0, {
    message: "Phone Number should not be empty.",
  })
  .refine((val) => !isNaN(Number(val)), {
    message: "Phone Number should contain only digits.",
  });
