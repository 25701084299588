import React, { useEffect, useState } from "react";
import { List, Button, Confirm } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { getCountries } from "apiCalls/masterDb";
import { Autocomplete } from "@material-ui/lab";
import { Typography, Divider, Button as MuiButton } from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ListCity(props) {
  const [open, toggleOpen] = useState(false);
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [dropdownSearchValue, setDropdownSearchValue] = useState("");
  const [countriesList, setCountriesList] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({
    _id: props.city.countryId,
    countryName: props.city.countryName,
  });
  const [text, setText] = useState(props.city.cityName);

  useEffect(() => {
    getCountriesList();
  }, []);

  const getCountriesList = async () => {
    const r = await getCountries();
    setCountriesList(r);
  };

  const dropdownSearch = ({ target }) => {
    setDropdownSearchValue(target.value);
  };

  const cancel = () => {
    toggleOpen(!open);
  };
  const confirm = () => {
    toggleOpen(!open);
    props.deleteCity(props.city._id);
    props.getCities();
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const setCountry = (e, v) => {
    setSelectedCountry(v);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const saveChanges = () => {
    if (text === null || selectedCountry === null)
      return toast.error("Fields cannot be empty");
    const data = {
      _id: props.city._id,
      newCityName: text,
      newCountry: selectedCountry,
    };
    props.editCityAndCountry(data);
    return setTimeout(() => {
      handleClose();
    }, 1200);
  };

  return (
    <>
      <List.Item>
        <List.Content>
          {props.city.cityName} ({props.city.countryName})
          <List.Content floated="right">
            <Button size="mini" color="blue" onClick={handleOpen}>
              Edit
            </Button>
            <Button
              disabled
              size="mini"
              color="red"
              onClick={() => toggleOpen(true)}
            >
              Delete
            </Button>
            <Confirm open={open} onCancel={cancel} onConfirm={confirm} />
          </List.Content>
        </List.Content>
      </List.Item>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            {/* <pre>
                {JSON.stringify(props.city)}
                </pre> */}
            <Typography noWrap variant="h4">
              Edit Country and City Name
            </Typography>

            <Divider />

            <Typography noWrap variant="h5">
              Selected City: {props.city.cityName} ({props.city.countryName})
            </Typography>

            <Autocomplete
              noOptionsText="No Country Found"
              options={countriesList}
              defaultValue={{
                _id: props.city.countryId,
                countryName: props.city.countryName,
              }}
              onChange={setCountry}
              getOptionLabel={(option) => option.countryName}
              style={{ width: 350, margin: "5px", alignItems: "left" }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={dropdownSearchValue}
                  onChange={dropdownSearch}
                  label="Select Country"
                  variant="outlined"
                />
              )}
            />

            <TextField
              type="text"
              style={{ width: 350, margin: "5px", alignItems: "left" }}
              defaultValue={props.city.cityName}
              onChange={handleTextChange}
              size="small"
              label="City Name"
              variant="outlined"
            />
            <br />
            <MuiButton
              color="primary"
              variant="contained"
              size="large"
              style={{ width: 350, margin: "5px", alignItems: "left" }}
              onClick={() => saveChanges()}
            >
              Save
            </MuiButton>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
