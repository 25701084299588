/* eslint-disable react-hooks/exhaustive-deps */
import { Container, List, ListItem, Typography } from "@material-ui/core";
import { getALlTBOLogs, getUniqueTBOTraceIds } from "apiCalls/errorLogsDb";
import React, { useCallback, useEffect } from "react";
import { FullScreenLoader } from "../flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import { AdvanceSettings } from "./AdvanceSettings";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export const TBOLogs = () => {
  const [loadingState, setLoadingState] = React.useState(false);
  const [traceIdState, setTraceIdState] = React.useState<string>();
  const [pnrInputState, setPnrInputState] = React.useState<string>();
  const [tboLogsState, setTboLogsState] = React.useState<Array<any>>();
  const [uniqueTboSearchTracesState, setUniqueTboSearchTracesState] =
    React.useState<Array<any>>();

  const callGetAllTBOLogsEntries = useCallback(
    async (traceIdState) => {
      setLoadingState(true);

      const res = await getALlTBOLogs({
        traceId: traceIdState,
        pnr: pnrInputState,
      });
      if (res.success) {
        setTboLogsState(res.result);
      }
      setLoadingState(false);
    },
    [pnrInputState]
  );

  const callGetUniqueTboTraceIds = useCallback(async () => {
    setLoadingState(true);

    const res = await getUniqueTBOTraceIds();
    if (res.success) {
      setUniqueTboSearchTracesState(res.result);
    }
    setLoadingState(false);
  }, []);

  useEffect(() => {
    callGetAllTBOLogsEntries(traceIdState);
    callGetUniqueTboTraceIds();
  }, [traceIdState]);

  return (
    <>
      <FullScreenLoader show={loadingState} />

      <AdvanceSettings />
      <Container>
        <Typography variant="h5">TBO Logs</Typography>

        <TextField
          label="Trace id"
          placeholder="Trace ID"
          onChange={(e) => setTraceIdState(e.target.value)}
        />
        <br />
        <TextField
          label="PNR"
          className="mt-2"
          placeholder="PNR"
          onChange={(e) => setPnrInputState(e.target.value)}
        />
        <br />
        <Button
          onClick={() => {
            callGetAllTBOLogsEntries(traceIdState);
          }}
          color="primary"
          variant="contained"
          className="mt-2"
        >
          Refresh
        </Button>
        <List
          style={{
            marginTop: 50,
          }}
        >
          {tboLogsState?.map((log) => {
            return (
              <ListItem key={log._id}>
                <h3>{log.type}</h3>
                <Button
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(
                      {
                        url: log.url,
                        request: log.request,
                        reqponse: log.response,
                      },
                      null,
                      2
                    )
                  )}`}
                  download={`${log.type}.txt`}
                  size="small"
                  style={{
                    marginTop: -8,
                    marginLeft: 20,
                  }}
                  color="primary"
                  variant="contained"
                >
                  Download
                </Button>
                {/* <pre>
                                        {JSON.stringify(log, null, 2)}
                                    </pre> */}
              </ListItem>
            );
          })}
        </List>

        <Typography variant="h4">Recent Search Traces</Typography>
        {uniqueTboSearchTracesState?.map((trace) => {
          return (
            <div>
              <pre>{JSON.stringify(trace, null, 2)}</pre>
            </div>
          );
        })}
      </Container>
    </>
  );
};
