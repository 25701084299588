import TextInp from "commonUi/input/text/TextInp";
import React, { useEffect, useState } from "react";
// import Button from "@material-ui/core/Button";
import Button from "react-bootstrap/Button";

import {
  addPGChargesApiCall,
  getPGChargesApiCall,
} from "apiCalls/siteSettingsDb";
import { useAppDispatch } from "hooks/reduxHooks";
import { updatePGCharge } from "redux/slices/paymentGatewaySlice";

const initialState = {
  paymentGateway: "",
  getGSTFromCustomer: false,
  tdr: {
    DebitCard: {
      percentage: 2,
      fixed: 2,
    },
    CreditCard: {
      percentage: 2,
      fixed: 2,
    },
    NetBanking: {
      percentage: 2,
      fixed: 2,
    },
    Upi: {
      percentage: 2,
      fixed: 2,
    },
    Wallet: {
      percentage: 2,
      fixed: 2,
    },
  },
};

const PaymentGatewayTDR: React.FC<{
  paymentGateway:
    | "Razorpay"
    | "Paytm"
    | "ICICI"
    | "HDFC_CCAVENUE"
    | "Cashfree"
    | "Phonepe"
    | "Easebuzz";
  setLoading: (boolean) => void;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [tdrForm, setTdrForm] = useState(initialState);

  useEffect(() => {
    if (props.paymentGateway) {
      initialState.paymentGateway = props.paymentGateway;
      getGatewayCharges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paymentGateway]);

  const getGatewayCharges = async () => {
    props.setLoading(true);
    let newTdrForm = { ...tdrForm };

    newTdrForm.paymentGateway = props.paymentGateway;

    const gatewayCharges = await getPGChargesApiCall({
      paymentGateway: props.paymentGateway,
    });

    if (gatewayCharges.success && gatewayCharges.result.length) {
      gatewayCharges.result.forEach((pgCharge) => {
        newTdrForm.tdr[pgCharge.paymentMode] = {
          fixed: pgCharge.fixed,
          percentage: pgCharge.percentage,
        };

        newTdrForm.getGSTFromCustomer = pgCharge.charge_gst;
      });
      setTdrForm(newTdrForm);
    } else {
      setTdrForm(Object.assign({}, { ...initialState }));
    }
    props.setLoading(false);
  };

  const submitGatewayCharges = async () => {
    // looping through tdrForm.tdr and check if user has not entered both percentage and fixed
    let tdrFormCopy = { ...tdrForm };
    let tdrFormKeys = Object.keys(tdrFormCopy.tdr);
    let isValidationFailed = false;
    for (let i = 0; i < tdrFormKeys.length; i++) {
      let tdrFormKey = tdrFormKeys[i];
      let tdrFormKeyObj = tdrFormCopy.tdr[tdrFormKey];

      if (
        parseFloat(tdrFormKeyObj.percentage) &&
        parseFloat(tdrFormKeyObj.fixed)
      ) {
        alert(`Please enter either percentage or fixed for ${tdrFormKey}!`);
        isValidationFailed = true;
      }
    }
    if (isValidationFailed) return;

    props.setLoading(true);
    await addPGChargesApiCall(tdrForm);
    dispatch(updatePGCharge());
    props.setLoading(false);
  };

  const handleTDRChange = (e, paymentMode) => {
    const { name, value } = e.target;
    if (tdrForm) {
      setTdrForm({
        ...tdrForm,
        tdr: {
          ...tdrForm.tdr,
          [paymentMode]: {
            ...tdrForm.tdr[paymentMode],
            [name]: value,
          },
        },
      });
    }
  };

  // const handleChargeGST = (e) => {
  //   const { checked } = e.target;
  //   if (tdrForm) {
  //     setTdrForm({
  //       ...tdrForm,
  //       getGSTFromCustomer: checked,
  //     });
  //   }
  // };

  const printIn = (name, label, value, paymentMode) => (
    <TextInp
      name={name}
      label={label}
      type="text"
      value={value}
      onChange={(e) => handleTDRChange(e, paymentMode)}
      required
    />
  );

  return (
    <div className="payment-charges-box">
      <div className="row">
        <div className="col-12 my-3">
          <h4>{tdrForm.paymentGateway} Charges</h4>
        </div>
      </div>
      {Object.keys(tdrForm.tdr).map((tdrKey, index) => {
        return (
          <div className="row my-3" key={index}>
            {Object.keys(tdrForm.tdr[tdrKey]).map((pcOrFixed, idx) => (
              <div className="col-6" key={idx}>
                {printIn(
                  pcOrFixed,
                  `${tdrKey} ${pcOrFixed}`,
                  tdrForm.tdr[tdrKey][pcOrFixed],
                  tdrKey
                )}
              </div>
            ))}
          </div>
        );
      })}

      {/* <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={tdrForm.getGSTFromCustomer}
              onChange={handleChargeGST}
              name="checkedA"
            />
          }
          label={`Charge GST on "Payment Gateway Charges" From Customer`}
        />
      </FormGroup> */}

      <div className="row">
        <div className="col-12 text-center">
          <Button variant="primary" onClick={submitGatewayCharges}>
            Save {tdrForm.paymentGateway} Charges
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentGatewayTDR;
