import {siteSettingsTypes} from 'redux/actionTypes/siteSettings.types'

export const GET_SMTP_DETAILS = () => {
    return {
        type: siteSettingsTypes.GET_SMTP_DETAILS
    }
}

export const SET_SMTP_DETAILS = (data) => {
    return {
        type: siteSettingsTypes.SET_SMTP_DETAILS,
        payload: data
    }
}

export const DELETE_SMTP_DETAIL = (id) => {
    return {
        type: siteSettingsTypes.DELETE_SMTP_DETAIL,
        payload: id
    }
}

export const GET_SITE_DATA = () => ({
    type: siteSettingsTypes.GET_SITE_DATA
})

export const SET_SITE_DATA = (data) => {
    return {
        type: siteSettingsTypes.SET_SITE_DATA,
        payload: data
    }
}

export const SEND_TEST_EMAIL = (payload) => ({
    type: siteSettingsTypes.SEND_TEST_EMAIL,
    payload: payload
})

export const SEND_TEST_EMAIL_RESPONSE = (apiRes) => ({
    type: siteSettingsTypes.SEND_TEST_EMAIL_RESPONSE,
    payload: apiRes
})

export const GET_BANK_DETAILS = (payload) => ({
    type: siteSettingsTypes.GET_BANK_DETAILS,
    payload
})

export const BANK_DETAILS = (payload) => ({
    type: siteSettingsTypes.BANK_DETAILS,
    payload
})

export const SET_BANK_DETAILS = (apiRes) => ({
    type: siteSettingsTypes.SET_BANK_DETAILS,
    payload : apiRes
})