import {packageTypes} from "../actionTypes/package.types"

const initialState = {
    allPackages : []
}

export function packageReducer (state= initialState,action) {
    switch(action.type){
        case packageTypes.SET_ALL_PACKAGES:
            return {...state, allPackages: action.payload}
        default:
        return state;
    }
    
}