import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'

function index(props) {
    return (
        <>
        <ListItem {...props}>
            {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
            {props.children}
        </ListItem>
        </>
    )
}

index.propTypes = {
    icon: PropTypes.node,
    children: PropTypes.node.isRequired
}

export default index