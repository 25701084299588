import { postRequest } from "tools/ajax";

export class SippiAccountsApi {
  static getBillingDataForAllSitesByMonth = (month: string, year: string) => {
    return postRequest("admin/accounts/getBillingDataForAllSitesByMonth", {
      month,
      year,
    });
  };
  static sendEmailsToAllWhitelabels = (
    condition: "onlyUnpaid" | null = null,
    monthYear: null | string = null
  ) => {
    return postRequest("admin/accounts/sendEmailsToAllWhitelabels", {
      condition,
      monthYear,
    });
  };
  static sendEmailToSingleWhitelabel = (invoiceId) => {
    return postRequest("admin/accounts/sendEmailToSingleWhitelabel", {
      invoiceId,
    });
  };
  static updateAccountsEmail = (props) => {
    return postRequest("admin/accounts/updateAccountsEmail", props);
  };
  static updateInvoiceData = (props) => {
    return postRequest("admin/accounts/updateInvoiceData", props);
  };
}
