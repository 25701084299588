import React, { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import Loading from "components/admin/common/loading";
import { toast } from "react-toastify";
import IntlTelInput from "react-intl-tel-input";
import { CommonConstants } from "constants/common.constants";
import { FormControl, FormGroup } from "react-bootstrap";
import { Console } from "console";
import { postRequest } from "tools/ajax";
import _ from "lodash";

interface Istaff {
  id?: number;
  firstName: string;
  lastName: string;
  designation?: string;
  email: string;
  // phoneCode: string;
  // phone: string;
  // password?: string;
  site_id?: string;
  status?: string;
  createdAt?: string;
  staff?: {
    designation?: string;
    branchId?: number;
  };
  isSuperAdmin?: boolean;
  // apiDeskPhoneNumber?: string;
}
interface IBranch {
  branchName: string;
  createdAt: string;
  id: number;
  siteId: string;
  updatedAt: string;
}
export default function ManageStaff() {
  //const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  // const [siteId, setSiteId] = useState("");
  const [staffs, setStaffs] = useState<Istaff[]>([]);
  const [loading, setLoading] = useState(true);
  const [siteBranches, setSiteBranches] = useState<IBranch[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<number | undefined>(
    undefined
  );
  const [isStaffPhoneValid, setIsStaffPhoneValid] = useState<
    boolean | undefined
  >(undefined);
  const [form, setForm] = useState<Istaff>({
    firstName: "",
    lastName: "",
    designation: "",
    email: "",
    // phoneCode: "+91",
    // phone: "",
    // password: "",
    isSuperAdmin: undefined,

    // apiDeskPhoneNumber: "",
  });

  useEffect(() => {
    // getWhitelabel();
    getAllBranchesBySiteId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getWhitelabel = () => {
  //   setLoading(true);
  //   axios({
  //     url: "/api/user/getWhitelabelBySippiCustomerId",
  //     headers: {
  //       "customer-token": localStorage.getItem("customer-token"),
  //     },
  //   }).then((r) => {
  //     // console.log(r.data);
  //     if (r.data.err) {
  //       return toast.error(r.data.err);
  //     }
  //     setLoading(false);
  //     // setSiteId(r.data._id);
  //   });
  // };

  useEffect(() => {
    // if (!siteId) return;
    getStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStaff = async () => {
    setLoading(true);
    const apiRes = await postRequest("user/getWhitelabelStaffBySiteId", {});
    if (apiRes.success) {
      setStaffs(apiRes.result);
    } else {
      toast.error(apiRes.errorMessage || "Something went wrong");
    }
    setLoading(false);
  };

  const MyTextField = (inpName, inpLabel, inpValue, inputType?) => {
    return (
      <FormGroup className="mb-2">
        <b className="text-muted">{inpLabel}</b>
        <FormControl
          name={inpName}
          placeholder={inpLabel}
          onChange={handleChange}
          value={inpValue}
          type={inputType}
        />
      </FormGroup>
    );
  };

  const handleChange = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    // if (!form.staff?.branchId) {
    //   toast.error("Please select branch");
    //   return;
    // }
    setForm({
      ...form,
      [name]: value,
      // site_id: siteId,
    });
  };
  // console.log(form);

  const checkFormValidity = () => {
    let isFormValid = false;
    if (
      form.firstName &&
      form.lastName &&
      form.designation &&
      form.email &&
      selectedBranch
    ) {
      isFormValid = true;
    }

    if (isStaffPhoneValid === false) {
      toast.error("Please enter a valid phone number");
      isFormValid = false;
    }
    return isFormValid;
  };

  const addUser = () => {
    const isFormValid = checkFormValidity();
    if (!isFormValid) {
      return toast.error("Please fill all fields");
    }
    setLoading(true);
    axios({
      url: "/api/user/addStaffForWhitelabel",
      method: "POST",
      headers: {
        "customer-token": localStorage.getItem("customer-token"),
      },
      data: { ...form, staff: { branchId: selectedBranch } },
    })
      .then(
        (
          r: AxiosResponse<{
            success: boolean;
            result: any;
            errorMessage?: string;
          }>
        ) => {
          if (!r.data.success)
            return toast.error(
              r.data.errorMessage || "Could not create new User!"
            );
          if (form.id) {
            toast.success("Staff Saved!");
          } else {
            toast.success(
              "Staff Created. Please check your email for password!"
            );
          }

          getStaff();
          setForm({
            firstName: "",
            lastName: "",
            designation: "",
            email: "",
            // phoneCode: "",
            // phone: "",
            // password: "",
            // apiDeskPhoneNumber: "",
          });
        }
      )
      .catch((err) => {
        if (err.response.data) {
          const errorMessage = err?.response?.data?.errorMessage;
          if (errorMessage) toast.error(errorMessage);
        }
      });
    setLoading(false);
  };
  // console.log(selectedBranch);

  const handleEditStaff = (staff: Istaff) => {
    setForm({
      id: staff.id,
      firstName: staff.firstName,
      lastName: staff.lastName,
      designation: staff?.staff?.designation,
      email: staff.email,
      // phoneCode: staff.phoneCode,
      // phone: staff.phone,
      // site_id: siteId,
      isSuperAdmin: staff.isSuperAdmin || false,
      staff: {
        branchId: staff?.staff?.branchId,
      },
      // apiDeskPhoneNumber: staff.apiDeskPhoneNumber || "",
    });
    setSelectedBranch(staff.staff?.branchId);
  };

  const intelInput = (props: {
    label: string;
    phoneCode: string;
    phone: string;
  }) => {
    return (
      <IntlTelInput
        preferredCountries={CommonConstants.prefferedCountriesIntlInput}
        excludeCountries={CommonConstants.excludedCountriesIntlInput}
        containerClassName="intl-tel-input w-100 mb-2"
        inputClassName="form-control m-0 w-100"
        defaultValue={props.phone}
        customPlaceholder={() => props.label}
        onPhoneNumberChange={(
          isValid,
          value,
          selectedCountryData,
          fullNumber
        ) => {
          const phone = value;
          const phoneCode = "+" + selectedCountryData.dialCode;
          console.log({ phoneCode, phone });
          setForm((old) => ({
            ...old,
            phoneCode,
            phone,
          }));

          setIsStaffPhoneValid(isValid);
        }}
        defaultCountry={props.phoneCode || "+91"}
        value={props.phone}
      />
    );
  };

  const getAllBranchesBySiteId = async () => {
    try {
      const res = await postRequest("user/getAllBranchesBySiteId", {});
      if (res?.success) {
        setSiteBranches(res?.result);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  // console.log({ staffs });
  console.log(staffs);

  return (
    <div className="container">
      <Loading loading={loading} />
      <div className="row justify-content-center py-4">
        <div className="col-md-6 shadow p-4 my-4">
          <p className="h5">Add Staff</p>

          {MyTextField("firstName", "First Name", form.firstName)}
          {MyTextField("lastName", "Last Name", form.lastName)}
          {MyTextField("designation", "Designation", form.designation)}
          {MyTextField("email", "Email", form.email)}
          {/* {MyTextField(
            "apiDeskPhoneNumber",
            "Desk Phone Number",
            form.apiDeskPhoneNumber
          )} */}
          <div className="mt-2 mb-3">
            <label className="form-check-label" htmlFor="">
              <b className="text-muted">User Branch</b>
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(parseFloat(e.target.value))}
            >
              <option value={""} defaultChecked>
                Select Branch
              </option>
              {siteBranches &&
                siteBranches.map((branch, index) => {
                  return (
                    <option value={branch?.id} key={index}>
                      {branch.branchName}
                    </option>
                  );
                })}
            </select>
          </div>
          <label className="form-check-label" htmlFor="isAdmin">
            <b className="text-muted"> isAdmin</b>
          </label>
          <div className="my-2 d-flex align-items-center">
            <div className="form-check form-switch ">
              <input
                checked={form.isSuperAdmin || false}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: "isSuperAdmin",
                      value: e.target.checked,
                    },
                  });
                }}
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="isAdmin"
              />
            </div>{" "}
            <div className="alert small alert-warning py-2 m-0" role="alert">
              After changing "isAdmin" settings, ask the respective staff member
              to login again!
            </div>
          </div>

          {/* {intelInput({
            label: "Mobile Number",
            phone: form.phone,
            phoneCode: form.phoneCode,
          })} */}
          <div style={{ textAlign: "center" }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                addUser();
              }}
            >
              Add
            </button>
          </div>
        </div>
        <div className=" table-responsive p-0">
          <table className="table table-scrollable shadow border table-striped">
            <thead>
              <tr className="bg-dark text-light ">
                <th>Action</th>
                <th>Staff Name</th>
                <th>Designation</th>
                <th>Email Id</th>
                {/* <th>Mobile</th> */}
                <th>Status</th>
                <th>Is Super Admin</th>
                <th>Created At</th>
                <th>Branch Name</th>
              </tr>
            </thead>
            <tbody>
              {staffs.length > 0 &&
                staffs?.map((staff, i) => (
                  <tr key={i}>
                    <td>
                      <button
                        onClick={() => {
                          handleEditStaff(staff);

                          window.scrollTo(0, 0);
                        }}
                        className="btn btn-primary"
                      >
                        Edit
                      </button>
                    </td>
                    <td>{staff.firstName + " " + staff.lastName}</td>
                    <td>{staff?.staff?.designation}</td>
                    <td>{staff.email}</td>
                    {/* <td>
                    {staff.phoneCode} {staff.phone}
                  </td> */}
                    <td>{staff.status}</td>
                    <td>{`${staff.isSuperAdmin}`}</td>
                    <td>
                      {moment(staff.createdAt).format(
                        "ddd, DD MMMM  YYYY, H:mm"
                      )}
                    </td>
                    <td>
                      {" "}
                      {siteBranches
                        ? _.find(siteBranches, { id: staff?.staff?.branchId })
                            ?.branchName
                        : ""}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
