import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserAdvanceApi } from "apiCalls/UserAdvance.api";
import { toast } from "react-toastify";
import { RootState } from "redux/store";

export const fetchWlMeta = createAsyncThunk("wlMeta/fetchWlMeta", async () => {
  const response = await UserAdvanceApi.getWlMetaData();
  if (response.success) {
    return response.result;
  } else {
    toast.error(response.errorMessage);
    throw response.error;
  }
});

export interface IwlMetaState {
  id?: number;
  mongoDb_siteId?: string;
  googleAuthClientId?: string;
  facebookAppSecret?: string;
  facebookAppId?: string;
  created_at?: Date;
  updated_at?: Date;
}

const wlMetaState: IwlMetaState = {};

export const wlMetaSlice = createSlice({
  name: "wlMeta",
  initialState: wlMetaState,
  reducers: {
    updateWlMeta: (state, action: PayloadAction<IwlMetaState>) => {
      return (state = action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWlMeta.fulfilled, (state, action) => {
      return (state = action.payload);
    });
  },
});

export const { updateWlMeta } = wlMetaSlice.actions;

export const selectWlMeta = (state: RootState) => state.wlMeta;

export const wlMetaReducer = wlMetaSlice.reducer;
